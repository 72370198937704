import supporttitle from '../assets/images/supporttitle.png'
import supportway from '../assets/images/supportway.png'
import supportwayH5 from '../assets/images/supportwayH5.png'

function Supported() {
  return (
    <section className='sm:pt-48 pt-[50px]' id='support'>
      <h2 className='flex justify-center'>
        <img className='sm:w-auto w-[253px]' src={supporttitle} alt='support' />
      </h2>
      <section className='sm:mt-16 w-full sm:h-[435px] h-[0]' style={{ background: `url(${supportway})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain' }}></section>
      <img src={supportwayH5} alt='supportway' className='sm:w-[0] sm:mt-[0px] w-[100%] mt-[33px]' />
    </section>
  )
}

export default Supported