import { createSlice } from '@reduxjs/toolkit'

let initialState = {
    user: null,
    token: null,
    userTableType: 0,
    sellInfo: null,
    backRouter: ''
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserInfoAction: (state, action) => {
            state.user = action.payload
        },
        setTokenAction:  (state, action) => {
            state.token = action.payload
        },
        clearUserInfoAction: (state) => {
            state.user = null
            state.token = null
        },
        setUserTableTypeAction:  (state, action) => {
            state.userTableType = action.payload
        },
        setUserSellInfoAction:  (state, action) => {
            state.sellInfo = action.payload
        },
        setUserBackRouterAction:  (state, action) => {
            state.backRouter = action.payload
        },    
    },
})

export const { setUserInfoAction, setTokenAction, clearUserInfoAction, setUserTableTypeAction, setUserSellInfoAction, setUserBackRouterAction } = userSlice.actions

export default userSlice.reducer
