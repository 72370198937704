import { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Index from './pages/index'
import NewLogin from './pages/login/login'
import Login from './pages/login/index'
import Register from './pages/register'
import Bulksender from './pages/bulksender'
import User from './pages/user'
import History from './pages/user/history'
import Bulksenders from './pages/newBulksender/index'
import Business from './pages/business/business'
import BusinessProcess from './pages/business/businessProcess'
import BusinessResult from './pages/business/businessResult'
import BankCard from './pages/bankCard/index'
import AddBankCard from './pages/bankCard/addBankCard'
import AddBankCardNext from './pages/bankCard/addBankCardNext'
import Exchange from './pages/exchange/exchange'

import { getUserInfo } from '../src/api/path/login'
import { useSelector, useDispatch } from 'react-redux'
import { setUserInfoAction } from '../src/store/userInfo'
import { setWidthAction } from '../src/store/global'

function App() {

	const dispatch = useDispatch()

	const { token } = useSelector((state) => state.user)
	useEffect(() => {
		if (localStorage.getItem('token')) {
			getUserInfo().then(res => {
				dispatch(setUserInfoAction(res.data))
			})
		}

	}, [localStorage.getItem('token'),token])

	useEffect(() => {
		handleResize()
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	function handleResize() {
		let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
		dispatch(setWidthAction(width))
	}

	return (
		<div className='select-none'>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Index />} />
					<Route path="/login" element={<NewLogin />} />
					<Route path="/logins" element={<Login />} />
					<Route path="/register" element={<Register />} />
					<Route path="/bulksender" element={<Bulksender />} />
					<Route path="/bulksenders" element={<Bulksenders />} />
					<Route path="/user" element={<User />} />
					<Route path="/details" element={<History />} />
					<Route path="/business" element={<Business />} />
					<Route path="/businessProcess" element={<BusinessProcess />} />
					<Route path="/businessResult" element={<BusinessResult />} />
					<Route path="/bankCard" element={<BankCard />} />
					<Route path="/addBankCard" element={<AddBankCard />} />
					<Route path="/addBankCardNext" element={<AddBankCardNext />} />
					<Route path="/exchange" element={<Exchange />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App
