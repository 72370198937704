import { useEffect, useState, useRef } from 'react'
import { Input, message, Modal } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Header from "../../component/header";
import Icon from '../../icon';
import PassowrdModal from '../../components/passowrdModal/index'

import { bankList, removeBank, isPayPassword } from '../../api/path/login'

import businessBg from '../../assets/images/businessProcessBg.png'
import businessBgH5 from '../../assets/images/businessBgH5.png'
import listNone from '../../assets/images/listNone.png'
// import add from '../../assets/images/add.png'
import add from '@/assets/images/add.png'



function BankCard() {

    const [messageApi, contextHolder] = message.useMessage()
    const navigate = useNavigate()

    const { width } = useSelector((state) => state.global)
    const { user, sellInfo, backRouter } = useSelector((state) => state.user)

    const [bankChoose, setBankChoose] = useState(-1)

    const [list, setList] = useState([])

    useEffect(() => {
        getList()
    }, [])
    const getList = () => {
        bankList().then(res => {
            setList(res.data)
        })
        isPayPassword().then(res => {
            setModalType(res.data.state)
        })
    }

    const deleteMethods = () => {
        if (bankChoose == -1) {
            messageApi.error('Please select the deleted card')
            return false
        }
        setIsModalOpen(true);
    }


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(0);
    const [modalValue, setModalValue] = useState('');

    const handleClose = () => {
        setIsModalOpen(false)
        setModalValue('')
    }
    const handleChange = (e) => {
        setModalValue(e.target.value)
    }
    const handleSubmit = () => {
        if (!modalValue) {
            messageApi.error('Please input a password')
            return false
        }
        let data = {
            id: list[bankChoose].id,
            payment_password: modalValue
        }
        removeBank(data).then(res => {
            getList()
            setModalValue('')
            setIsModalOpen(false)
            setBankChoose(-1)
        }).catch(err => {
            messageApi.error(err.message)
        })
    }

    const addMethods = () => {
        if (user.user_kyc_info.KycStatus == '0') {
            setModalType('kyc')
            setIsModalOpen(true)
            return false
        }
        navigate('/addBankCard', { state: { index: -1 } })
    }


    return <>
        <div className="App mx-auto min-h-[100vh]">
            {contextHolder}
            <main className='relative min-h-[100vh]'>
                <div className='w-[100%] sm:min-h-[100vh] h-[100%] min-h-[100%] absolute bg-[#000000]'>
                    <img src={width > 768 ? businessBg : businessBgH5} className='w-[100%] h-[100%]' />
                </div>
                <div className="border-b border-[#161654] border-solid sticky top-0 bg-[#010220] relative z-[100]">
                    <Header />
                </div>
                <article className='mx-auto py-[20px] sm:px-[0px] px-[18px] relative'>
                    <div className='sm:w-[916px]' style={{ 'margin': '0 auto' }}>
                        <div className='text-[16px] text-[#ffffff] flex items-center '>
                            <Icon type='icon-backColors' className='text-[16px] mr-[8px] cursor-pointer' onClick={() => { navigate(backRouter, sellInfo) }} />
                            <span className='cursor-pointer' onClick={() => { navigate(backRouter, sellInfo) }}>Back</span>
                        </div>
                        <div className='sm:text-[30px] text-[20px] text-[#FFFFFF] font-bold sm:pt-[50px] pt-[20px]'>Bank Card</div>
                        <div className='sm:text-[16px] text-[12px] pt-[10px]'>Please enter your English bank card information. <span className='text-[#0DC7CB]'>ChiChaPay</span> values and protects user privacy and security, and your personal information will be encrypted throughout the process</div>
                        <div className='sm:h-[21px]'></div>
                        <div className='info'></div>
                        {
                            list.length > 0 ? <>
                                <div className='sm:pt-[25px] pt-[25px]'>
                                    <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[8px]'>Bank List：{list.length} / 10</div>
                                    <div className='w-[100%] sm:flex sm:justify-between sm:flex-wrap'>
                                        {
                                            list.map((item, index) => {
                                                return <div key={index} className={`border-[2px] ${bankChoose == index ? 'border-[#5A47EE]' : 'border-[#E7E7E7]'} hover:border-[#5A47EE] border-solid sm:w-[428px] bankListBox sm:h-[84px] h-[62px] w-[100%] bg-[#F7F7F7] rounded-[6px] flex px-[15px] cursor-pointer sm:mb-[16px] mb-[12px] sm:py-[17px] py-[12px] pl-[20px] sm:pr-[35px] pr-[20px] text-[#000000]`} onClick={() => {
                                                    setBankChoose(index)
                                                }}>
                                                    <div className='flex items-center'><Icon type='icon-a-Group108' className='sm:text-[35px] text-[28px]' /></div>
                                                    <div className='flex flex-col ml-[15px] flex-auto justify-between'>
                                                        <span className='sm:text-[16px] text-[12px] font-bold'>{item.number.replace(/\D/g, "").length > 12 ? item.number.replace(/\D/g, "").slice(0, 4) + ' **** **** ' + item.number.replace(/\D/g, "").substr(-4) : '**** ' + item.number.replace(/\D/g, "").substr(-4)}</span>
                                                        <span className='sm:text-[12px] text-[10px]'>{item.bank_name}</span>
                                                    </div>
                                                    <div className='flex flex-col items-center justify-between'>
                                                        <span className='sm:text-[16px] text-[12px]'>{item.currency}</span>
                                                        <div className={`bankListItem sm:w-[16px] w-[12px] sm:h-[16px] h-[12px] rounded-[50%] ${bankChoose == index ? 'bg-[#5A47EE]' : 'bg-[#ffffff]'} mb-[2px] border ${bankChoose == index ? 'border-[#5A47EE]' : 'border-[#E7E7E7]'} border-solid`}></div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='flex pt-[30px]'>
                                    <div className='w-[118px] sm:h-[48px] h-[40px] rounded-[10px] bg-[rgba(90,71,238,0.3)] border border-solid border-[#5A47EE] sm:text-[20px] text-[16px] text-[#FFFFFF] font-bold flex items-center justify-center cursor-pointer mr-[30px]' onClick={() => {
                                        addMethods()
                                    }}>Add</div>
                                    <div className='w-[118px] sm:h-[48px] h-[40px] rounded-[10px] bg-[rgba(243,64,59,0.3)] border border-solid border-[#F3403B] sm:text-[20px] text-[16px] text-[#FFFFFF] font-bold flex items-center justify-center cursor-pointer' onClick={() => {
                                        deleteMethods()
                                    }}>Delete</div>
                                </div>
                            </> : <>
                                <div className='listnone sm:w-[428px] mt-[30px] sm:px-[54px] px-[20px] rounded-[5px] border-[2px] border-solid border-[#5E5B5B] drop-shadow flex flex-col items-center'>
                                    <img src={listNone} alt='listNone' className='sm:w-[74px] w-[50px] sm:mt-[35px] mt-[20px]' />
                                    <div className='sm:text-[32px] text-[26px] text-[#FFFFFF] mt-[10px]'>No cards available</div>
                                    <div className='sm:h-[64px] h-[48px] w-[100%] rounded-[10px] bg-[#5A47EE] mt-[30px] mb-[20px] flex items-center justify-center cursor-pointer' onClick={() => {
                                        addMethods()
                                    }}>
                                        <img src={add} alt='add' className='sm:w-[24px] w-[20px]' />
                                        <div className='sm:text-[20px] font-bold ml-[10px]'>Add cards</div>
                                    </div>
                                </div>
                            </>
                        }

                    </div>
                </article>
            </main>
            <PassowrdModal type={modalType} visible={isModalOpen} handleClose={handleClose} value={modalValue} handleChange={handleChange} handleSubmit={handleSubmit} />
        </div>
    </>
}

export default BankCard;