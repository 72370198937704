import React, { useEffect, useRef, useState } from 'react';
import { Space, Table, Button, message, Tag } from 'antd';
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Header from "../../component/header";
import Icon from '../../icon';
import { copyFn } from '../../utils'

import { setUserTableTypeAction, setUserBackRouterAction } from '@/store/userInfo'

import { getGrantList, getUserInfo, getBalances, getCryptoList } from '../../api/path/login'
import './index.css'
import payHeads from '../../assets/images/payHeads.png'

import { headerList } from './header'


function User() {

    const dispatch = useDispatch()
    const { width } = useSelector((state) => state.global)
    const { userTableType } = useSelector((state) => state.user)
    
    const [messageApi, contextHolder] = message.useMessage()
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [newData, setNewData] = useState([])
    const [newDatas, setNewDatas] = useState([])
    const [tableTotal, setTableTotal] = useState(0)
    const [newTableTotal, setNewTableTotal] = useState(0)
    const [tableTotals, setTableTotals] = useState(0)
    const [userInfo, setUserInfo] = useState(null);
    const [totalBalance, setTotalBalance] = useState(0)
    const [tableType, setTableType] = useState(0)
    const [tableTypeStatus, setTableTypeStatus] = useState(false)


    useEffect(() => {
        getList(1)
        getNewList(1)
        getNewLists(1, userTableType == 0 ? 1 : 2)
        getUserInfo().then(res => {
            setUserInfo(res.data)
        })
        getBalances('USDT').then(res => {
            setTotalBalance(res.data.user_balances[0].total_balance)
        })
        setTableType(userTableType)
    }, [])

    const getList = (num) => {
        getGrantList(num, 0).then(res => {
            setData(res.data.items)
            setTableTotal(res.data.total)
        })
    }
    const getNewList = (num) => {
        getGrantList(num, 1).then(res => {
            setNewData(res.data.items)
            setNewTableTotal(res.data.total)
        })
    }

    const getNewLists = (num, type) => {
        getCryptoList(num, type).then(res => {
            setNewDatas(res.data.items)
            setTableTotals(res.data.total)
        })
    }



    const onChange = (e) => {
        getList(e.current)
    }
    const onChanges = (e) => {
        getNewList(e.current)
    }
    const onChangesNew = (e) => {
        getNewLists(e.current, cryptoType)
    }

    const showModal = (i, type) => {

        navigate('/details',{
            state: {
                details: i.data,
                remark: i.remark,
                sub_symbol: i.sub_symbol,
                tableType: type
            }
        })
    };

    const columns = [
        {
            title: 'Time',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => <a>{getMyDate(text)}</a>,
        },
        {
            title: 'Coins Sent',
            dataIndex: 'sub_symbol',
            key: 'sub_symbol',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Total Number Sent',
            dataIndex: 'amount',
            key: 'amount',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Total number of addresses',
            dataIndex: 'cnt',
            key: 'cnt',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Handling Fee',
            dataIndex: 'fee',
            key: 'fee',
        },
        {
            title: 'Details',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <div className='border-solid border-[#666C6D] border-[1px] py-[3px] px-[26px] rounded-[15px] cursor-pointer hover:bg-[#5A47EE] hover:border-[#5A47EE]' onClick={() => {
                        showModal(record, 0)
                    }}>See</div>
                </Space>
            ),
        },
        {
            title: 'Notes',
            dataIndex: 'remark',
            key: 'remark',
        }
    ];

    const newColumns = [
        {
            title: 'Time',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => <a>{getMyDate(text)}</a>,
        },
        {
            title: 'Type',
            dataIndex: 'payment_type',
            key: 'payment_type',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Coins Sent',
            dataIndex: 'sub_symbol',
            key: 'sub_symbol',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Total Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Total Number',
            dataIndex: 'cnt',
            key: 'cnt',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Handling Fee',
            dataIndex: 'fee',
            key: 'fee',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Details',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <div className='border-solid border-[#666C6D] border-[1px] py-[3px] px-[26px] rounded-[15px] cursor-pointer hover:bg-[#5A47EE] hover:border-[#5A47EE]' onClick={() => {
                        showModal(record, 1)
                    }}>See</div>
                </Space>
            ),
        },
        {
            title: 'Notes',
            dataIndex: 'remark',
            key: 'remark',
        }
    ];

    const buyCryptoData = [
        {
            title: 'Order Number',
            dataIndex: 'trade_no',
            key: 'trade_no',
        },
        {
            title: 'Time',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => <a>{getMyDate(text)}</a>,
        },
        {
            title: 'Type',
            dataIndex: 'trade_type',
            key: 'trade_type',
            render: (text) => <a>{text == 1 ? 'Buy' : 'Sell'}</a>,
        },
        {
            title: 'Get',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => <>{cryptoType == 1 ? <a>{text} {record.sub_symbol}</a> : <a>{Number(record.discount)} {record.currency}</a>}</>,
        },
        {
            title: 'Handling Fee',
            dataIndex: 'fee',
            key: 'fee',
            render: (text, record) => <a>{text} {cryptoType == 1 ? record.currency : record.sub_symbol}</a>,
        },
        {
            title: 'Total Amount',
            dataIndex: 'discount',
            key: 'discount',
            render: (text, record) => <>{cryptoType == 1 ? <a>{Number(record.discount) + Number(record.fee)} {record.currency}</a> : <a>{record.amount} {record.sub_symbol}</a>}</>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => <a>{
                text == 0 ? <Icon type='icon-a-bianzu5' className='text-[20px]' /> : 
                text == 1 ? <Icon type='icon-a-Group9' className='text-[20px]' /> : 
                <Icon type='icon-a-Group7' className='text-[20px]' />}</a>,
        },
        {
            title: 'Details',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <div className='border-solid border-[#666C6D] border-[1px] py-[3px] px-[26px] rounded-[15px] cursor-pointer hover:bg-[#5A47EE] hover:border-[#5A47EE]' onClick={() => {
                        navigate(`/businessResult?transactionId=${record.trade_no}`, { state: { type: Number(tableType) + 1 } })
                    }}>See</div>
                </Space>
            ),
        }
    ];

    const getMyDate = (str) => {
        var oDate = new Date(str),
            oYear = oDate.getFullYear(),
            oMonth = oDate.getMonth() + 1,
            oDay = oDate.getDate(),
            oHour = oDate.getHours(),
            oMin = oDate.getMinutes(),
            oSen = oDate.getSeconds(),
            oTime = oYear + '-' + getzf(oMonth) + '-' + getzf(oDay) + ' ' + getzf(oHour) + ':' + getzf(oMin) + ':' + getzf(oSen);
        return oTime;
    };
    const getzf = (num) => {
        if (parseInt(num) < 10) {
            num = '0' + num;
        }
        return num;
    }

    const [cryptoType, setCryptoType] = useState(1)
    let navList = [
        { name: 'Buy Crypto', icon: 'icon-buyCrypto', value: 'Trade USDT with Visa/Mastrcard', route: '/business' },
        { name: 'Sell Crypto', icon: 'icon-buyCrypto', value: 'Trade USDT with Visa/Mastrcard', route: '/business' },
        { name: 'Token BulkSender', icon: 'icon-bulksender', value: 'Use Token BulkSender to send tokens in bulk', route: '/bulksender' },
        { name: 'Fiat Currency BulkSender', icon: 'icon-bulksenders', value: 'Batch withdrawals with Fiat Currency BulkSender', route: '/bulksenders' }
    ]

    useEffect(() => {
        document.addEventListener('click', () => {
            setTableTypeStatus(false)
        })
    }, [])
    const stopBubble = (event) => {
        event.nativeEvent.stopImmediatePropagation()
    }

    return <>
        <div className="App mx-auto bg-[#000000] min-h-[100vh] select-none">
            {contextHolder}
            <main className='pb-[50px]'>
                <div className="fixed top-0 bg-[rgba(0,0,0,0.43)] w-[100%] z-[51]">
                    <Header />
                </div>
                <div className='h-[228px]' style={{ background: `url(${payHeads})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cotain', backgroundPosition: 'center' }}>
                    <article className='mx-auto sm:w-[1200px] w-[100%] pt-[110px] z-[23]'>
                        <div className='h-[72px] w-[100%] flex'>
                            <img className='w-[72px] h-[72px] rounded-full mr-[16px]' src={userInfo?.avatar_url} />
                            <div className=''>
                                <div className='text-[20px]'>{userInfo?.user_email}</div>
                                {/* userInfo?.user_type == '2' ? 'Merchants' : userInfo?.user_type == '3' ? 'OTC merchants' : userInfo?.user_type == '4' ? 'Acquirer' :  */}
                                <div className='text-[#D4D4D4] text-[14px] flex items-center'>UID：{userInfo?.id}<Icon onClick={(e) => { copyFn(userInfo?.id, true); messageApi.success('Successful Replication'); }} type='icon-copy' className='cursor-pointer ml-[5px] text-[16px] text-[#ffffff]' /></div>
                                <span className='bg-[#5A47EE] text-[10px] text-[#FFFFFF] px-[10px] py-[3px] rounded-[50px]'>{userInfo?.user_type == '1' ? 'Users' : 'Merchants'}</span>
                            </div>
                        </div>
                    </article>
                </div>
                <article className='mx-auto sm:w-[1200px] w-[100%]'>
                    <div className='h-[64px] text-[18px] text-[#ffffff] leading-[75px]'>Wallet Overview</div>
                    <div className='tableBox py-[20px] flex justify-between items-end'>
                        <div>
                            <div className='sm:text-[18px] text-[14px] flex items-center pl-[20px]'><Icon type='icon-wallet' className='sm:text-[30px] text-[24px] mr-[10px]' />Total assets</div>
                            <div className='sm:text-[28px] text-[16px] text-[#ffffff] pl-[20px] sm:mt-[0px] mt-[10px]'>{totalBalance}  USDT</div>
                        </div>
                        <div className='addBankStyle flex items-center sm:px-[12px] px-[6px] justify-between cursor-pointer sm:h-[40px] sm:w-[160px] w-[115px] h-[28px]' onClick={() => {
                            dispatch(setUserBackRouterAction('/user'));navigate(`/bankcard`)
                        }}>
                            <Icon type='icon-a-Group1081' className='sm:text-[30px] text-[16px]' />
                            <div className='sm:text-[14px] text-[12px]'>Add Bank Card</div>
                        </div>
                    </div>
                    <div className='tableTitle h-[60px] bg-[#1B1B1B] text-[17px] text-[#ffffff] leading-[60px] pl-[10px] flex items-center pt-[5px]'>
                        <div className='relative'>
                            <div className='h-[40px] border-solid border-[#666C6D] border-[1px] rounded-[6px] bg-[#1B1B1B] flex items-center justify-between px-[16px] min-w-[270px] cursor-pointer' onClick={(e) => {
                                setTableTypeStatus(!tableTypeStatus); stopBubble(e)
                            }}>
                                <div className='h-[38px] leading-[38px]'>{navList[tableType].name}</div>
                                <Icon type='icon-iconDown' className='text-[#ffffff]' />
                            </div>
                            {tableTypeStatus && <div className='py-[8px] absolute left-0 top-[42px] w-[100%] z-50 border-solid border-[#666C6D] border-[1px] rounded-[6px] bg-[#1B1B1B]'>
                                {
                                    navList.map((item, index) => {
                                        return <div key={index} className={tableType == index ?
                                            'flex items-center justify-between px-[16px] h-[44px] flex items-center bg-[#121314] hover:bg-[#121314]' :
                                            'flex items-center justify-between px-[16px] h-[44px] flex items-center hover:bg-[#121314]'
                                        } onClick={() => {
                                            setTableType(index);
                                            dispatch(setUserTableTypeAction(index))
                                            if (index == '0') {
                                                setCryptoType(1)
                                                getNewLists(1, 1)
                                            } else if (index == '1') {
                                                setCryptoType(2)
                                                getNewLists(1, 2)
                                            } else if (index == '2') {
                                                getList(1)
                                            } else if (index == '3') {
                                                getNewList(1)
                                            } else {

                                            }

                                        }}>
                                            {item.name}{tableType == index && <Icon type='icon-choosed' className='text-[#ffffff] text-[18px]' />}
                                        </div>
                                    })
                                }
                            </div>}
                        </div>
                    </div>
                    {tableType == '0' && <Table columns={width > 768 ? headerList(navigate).buy : headerList(navigate).buyH5} dataSource={newDatas} onChange={onChangesNew} pagination={{
                        total: tableTotals,
                        pageSize: 10,
                        showSizeChanger: false,
                        position: [width > 768 ? 'bottomRight' : 'bottomCenter']
                    }}
                        onRow={(record) => {
                            return {
                                onClick: (e) => {
                                    if (width < 768) {
                                        navigate(`/businessResult?transactionId=${record.trade_no}`, { state: { type: 1 } })
                                    }
                                }
                            };
                        }}
                        rowKey={record => record.id}
                    />}
                    {tableType == '1' && <Table columns={width > 768 ? headerList(navigate).sell : headerList(navigate).sellH5} dataSource={newDatas} onChange={onChangesNew} pagination={{
                        total: tableTotals,
                        pageSize: 10,
                        showSizeChanger: false,
                        position: [width > 768 ? 'bottomRight' : 'bottomCenter']
                    }}
                        onRow={(record) => {
                            return {
                                onClick: (e) => {
                                    if (width < 768) {
                                        navigate(`/businessResult?transactionId=${record.trade_no}`, { state: { type: 2 } })
                                    }
                                }
                            };
                        }}
                        rowKey={record => record.id}
                    />}
                    {tableType == '2' && <Table columns={columns} dataSource={data} onChange={onChange} pagination={{
                        total: tableTotal,
                        pageSize: 10,
                        showSizeChanger: false,
                        position: [width > 768 ? 'bottomRight' : 'bottomCenter']
                    }}
                        rowKey={record => record.id}
                    />}
                    {tableType == '3' && <Table columns={newColumns} dataSource={newData} onChange={onChanges} pagination={{
                        total: newTableTotal,
                        pageSize: 10,
                        showSizeChanger: false,
                        position: [width > 768 ? 'bottomRight' : 'bottomCenter']
                    }}
                        rowKey={record => record.id}
                    />}
                </article>
            </main>
        </div>
    </>
}
export default User