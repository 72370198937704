import { useEffect, useState, useRef } from 'react'
import { Input, message, InputNumber } from 'antd';
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Header from "../../component/header";
import Loading from "../../component/loading"
import PassowrdModal from '../../components/passowrdModal/index'
import Icon from '../../icon';
import { numberRules, rateRules, CutOut } from '../../utils'

import { getConfig, getSubsymbolRate, getCryptoConfig, payget, getSubsymbolConf, getBalances } from '../../api/path/login'

import businessBg from '../../assets/images/businessProcessBg.png'
import businessBgH5 from '../../assets/images/businessBgH5.png'
import alipay from '../../assets/images/alipay.png'

import { setUserSellInfoAction } from '@/store/userInfo'



function Business() {
    const [messageApi, contextHolder] = message.useMessage()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { width } = useSelector((state) => state.global)
    const { user } = useSelector((state) => state.user)

    const [config, setConfig] = useState(null);
    const [input1Type, setInput1Type] = useState(0);
    const [input2Type, setInput2Type] = useState(0);
    const [input1ListStatus, setInput1ListStatus] = useState(false);
    const [input2ListStatus, setInput2ListStatus] = useState(false);

    const [apiKey, setApiKey] = useState('');

    const [loadingStatus, setLoadingStatus] = useState(false)

    const [totalBalance, setTotalBalance] = useState(0)
    const getConfigInfo = (value) => {
        if (value == 1) {
            setType(value);
            getConfig('crypto_buy').then(res => {
                setConfig(JSON.parse(res.data))
                getRate(JSON.parse(res.data).currency[input1Type].symbol, JSON.parse(res.data).sub_symbol[input2Type].symbol)
                getCryptoConfig().then(arr => {
                    setApiKey(arr.data.moon_pay_api_key)
                    if (arr.data.moon_pay_api_key) {
                        getNorm(arr.data.moon_pay_api_key, JSON.parse(res.data).currency[input1Type].symbol, JSON.parse(res.data).sub_symbol[input2Type].symbol)
                    }
                })
            })
        } else {
            setType(value);
            getConfig('crypto_sell').then(res => {
                setConfig(JSON.parse(res.data))
                getRate(JSON.parse(res.data).sub_symbol[0].symbol, JSON.parse(res.data).currency[0].symbol)
                getSubsymbolConf().then(ress => {
                    ress.data.map((item, index) => {
                        if (item.sub_symbol == JSON.parse(res.data).sub_symbol[0].symbol) {
                            setFee(item.crypto_sell_fee_rate)
                        }
                    })
                })
            })
            getBalances('USDT').then(res => {
                setTotalBalance(res.data.user_balances ? res.data.user_balances[0].total_balance : 0)
            })
        }


    }

    const [fee, setFee] = useState(0)

    const [rate, setRate] = useState(1);
    const getRate = (args1, args2) => {
        let data = {
            args1: args1,
            args2: args2
        }
        getSubsymbolRate(data).then(res => {
            setRate(res.data)
        })
    }

    const [norm, setNorm] = useState({});
    const getNorm = (apiKeyValue, args1, args2) => {
        setLoadingStatus(true)
        let data = {
            uri: `https://api.moonpay.com/v3/currencies/${args2.toLowerCase()}/limits?apiKey=${apiKeyValue}&baseCurrencyCode=${args1.toLowerCase()}`
        }
        payget(data).then(res => {
            setNorm(res)
            setLoadingStatus(false)
        })
    }



    const buyMethods = () => {
        if (user.user_kyc_info.KycStatus == '0') {
            setIsModalOpen(true)
            return false
        }
        if (!input2Value) {
            messageApi.error('Please enter the purchase quantity')
            return false
        }
        if (!input1Status && !input2Status) {
            dispatch(setUserSellInfoAction({
                state: {
                    currency: config.sub_symbol[input2Type].symbol.toLowerCase(),
                    baseCurrencyCode: config.currency[input1Type].symbol.toLowerCase(),
                    quoteCurrencyAmount: input2Value,
                    amount: input1Value,
                    apiKey: apiKey,
                    config: config,
                    input1Type: input1Type,
                    input2Type: input2Type,
                    type: type,
                    rate: rate
                }
            }))
            navigate('/businessProcess', {
                state: {
                    currency: config.sub_symbol[input2Type].symbol.toLowerCase(),
                    baseCurrencyCode: config.currency[input1Type].symbol.toLowerCase(),
                    quoteCurrencyAmount: input2Value,
                    amount: input1Value,
                    apiKey: apiKey,
                    config: config,
                    input1Type: input1Type,
                    input2Type: input2Type,
                    type: type,
                    rate: rate
                }
            })
        }
    }

    const [type, setType] = useState(1);
    //rules
    const [input1Status, setInput1Status] = useState(false);
    const [input2Status, setInput2Status] = useState(false);
    //value
    const [input1Value, setInput1Value] = useState('');
    const [input2Value, setInput2Value] = useState('');

    useEffect(() => {
        getConfigInfo(type)
    }, [])
    useEffect(() => {
        document.addEventListener('click', () => {
            setInput1ListStatus(false)
            setInput2ListStatus(false)
        })
    }, [])
    const stopBubble = (event) => {
        event.nativeEvent.stopImmediatePropagation()
    }

    const changeType = (e) => {
        getConfigInfo(e);
        setInput1Type(0);
        setInput2Type(0);
        setInput1Status(false);
        setInput2Status(false);
        setInput1Value('')
        setInput2Value('')
    }

    const input1Methods = (e,index) => {
        setInput1Value(e)
        if (e == '' || e == null) {
            setInput2Value('')
            setInput1Status(false)
            setInput2Status(false)
            return false
        }
        if (type == 1) {
            setInput2Value((Number(e) * Number(rate)).toFixed(rateRules(config?.sub_symbol[input2Type].symbol)))
            if (Number(e) < norm?.baseCurrency?.minBuyAmount || Number(e) > norm?.baseCurrency?.maxBuyAmount) {
                setInput1Status(true)
            } else {
                setInput1Status(false)
            }
            if (Number(e) * Number(rate) < norm?.quoteCurrency?.minBuyAmount || Number(e) * Number(rate) > norm?.quoteCurrency?.maxBuyAmount) {
                setInput2Status(true)
            } else {
                setInput2Status(false)
            }
        } else {
            let num = CutOut(Number((Number(e) / (Number(rate) - Number(config?.sub_symbol[input2Type].factor)) / (1 - Number(fee)))))
            setInput2Value(num)
            if (Number(e) < config?.currency[index].min || Number(e) > config?.currency[index].max) {
                setInput1Status(true)
            } else {
                setInput1Status(false)
            }
            if (Number(num) < config?.sub_symbol[input2Type].min || Number(num) > config?.sub_symbol[input2Type].max) {
                setInput2Status(true)
            } else {
                setInput2Status(false)
            }
        }
    }

    const input2Methods = (inputValue) => {
        setInput2Value(inputValue)
        if (inputValue == '' || inputValue == null) {
            setInput1Value('')
            setInput1Status(false)
            setInput2Status(false)
            return false
        }
        if (type == 1) {
            setInput1Value((Number(inputValue) / Number(rate)).toFixed(2))
            if (Number(inputValue) / Number(rate) < norm?.baseCurrency?.minBuyAmount || Number(inputValue) / Number(rate) > norm?.baseCurrency?.maxBuyAmount) {
                setInput1Status(true)
            } else {
                setInput1Status(false)
            }
            if (Number(inputValue) < norm?.quoteCurrency?.minBuyAmount || Number(inputValue) > norm?.quoteCurrency?.maxBuyAmount) {
                setInput2Status(true)
            } else {
                setInput2Status(false)
            }
        } else {
            let num = (Number(inputValue) * (1 - Number(fee)) * (Number(rate) - Number(config?.sub_symbol[input2Type].factor))).toFixed(2)
            setInput1Value(num)
            if (Number(inputValue) < config?.sub_symbol[input2Type].min || Number(inputValue) > config?.sub_symbol[input2Type].max) {
                setInput2Status(true)
            } else {
                setInput2Status(false)
            }
            if (Number(num) < config?.currency[input1Type].min || Number(num) > config?.currency[input1Type].max) {
                setInput1Status(true)
            } else {
                setInput1Status(false)
            }
        }
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClose = () => {
        setIsModalOpen(false)
    }

    return <>
        <div className="App mx-auto min-h-[100vh]">
            {contextHolder}
            {loadingStatus && <Loading />}
            {/* style={{background: `url(${businessBgH5})`, backgroundColor: '#000000', backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', backgroundPosition: 'center'}} */}
            <main className='relative'>
                <div className='w-[100%] min-h-[100vh] h-[100%] absolute bg-[#000000]'>
                    <img src={width > 768 ? businessBg : businessBgH5} className='w-[100%] h-[100%]' />
                </div>
                <div className="border-b border-[#161654] border-solid sticky top-0 bg-[#010220] relative z-[100]">
                    <Header />
                </div>
                <article className='mx-auto sm:pt-[90px] pt-[20px] sm:px-[0px] px-[18px] relative'>
                    <div className='sm:w-[502px] sm:rounded-[18px] rounded-[13px] bg-[#FFFFFF] sm:px-[37px] px-[10px] pb-[40px]' style={{ 'margin': '0 auto' }}>
                        <div className='sm:mb-[25px] mb-[11px] sm:h-[84px] h-[62px] border-b-[1px] border-[#8F8F8F] sm:text-[30px] text-[22px] font-bold border-solid sm:leading-[84px] leading-[62px] flex'>
                            <div onClick={() => { changeType(1) }} className={type == 1 ? 'sm:h-[84px] h-[62px] text-[#000000] cursor-pointer border-b-[3px] border-[#5A47EE] border-solid sm:mr-[40px] mr-[30px]' : 'sm:h-[81px] h-[59px] text-[#8F8F8F] cursor-pointer border-b-[3px] border-[#FFFFFF] border-solid sm:mr-[40px] mr-[30px]'}>Buy</div>
                            <div onClick={() => { changeType(2) }} className={type == 2 ? 'sm:h-[84px] h-[62px] text-[#000000] cursor-pointer border-b-[3px] border-[#5A47EE] border-solid sm:mr-[40px] mr-[30px]' : 'sm:h-[81px] h-[59px] text-[#8F8F8F] cursor-pointer border-b-[3px] border-[#FFFFFF] border-solid sm:mr-[40px] mr-[30px]'}>Sell</div>
                        </div>
                        <div className={type == 1 ? 'flex flex-col' : 'flex flex-col-reverse'}>
                            {/* sm:pt-[35px] pt-[16px] */}
                            <div className='sm:pt-[10px] pt-[5px]'>
                                <div className='text-[#444444] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>
                                    {type == 1 ? "Payment Amount" : "Amount Received"}
                                </div>
                                <div className='business relative'>
                                    <InputNumber className='h-[48px] w-[100%]'
                                        controls={false}
                                        // type="number" pattern="[0-9]*" inputMode="decimal"
                                        placeholder={'Limit ' + (type == 1 ? norm?.baseCurrency?.minBuyAmount : config?.currency[input1Type].min) + ' - ' + (type == 1 ? norm?.baseCurrency?.maxBuyAmount : config?.currency[input1Type].max)}
                                        onKeyUp={(e) => { e.target.value = numberRules(e.target.value, 2) }}
                                        // onBlur={(e) => { setInput1Value(numberRules(e.target.value, 2)) }}
                                        value={input1Value} onChange={(e) => {
                                            input1Methods(e,input1Type)
                                        }}
                                        status={input1Status && 'error'}
                                        addonAfter={
                                            <div className='flex items-center cursor-pointer pr-[20px]' onClick={(e) => { setInput1ListStatus(!input1ListStatus); stopBubble(e) }}>
                                                {/* <div className='h-[16px] w-[1px] bg-[#B9B9B9] mr-[12px]'></div> */}
                                                <div className='w-[10px] text-[#B9B9B9] text-[20px] mr-[12px] flex items-center'>|</div>
                                                <img src={config?.currency[input1Type].icon} className="mr-[12px] sm:w-[33px] w-[24px]" />
                                                <div className='mr-[12px] w-[40px]'>{config?.currency[input1Type].symbol}</div>
                                                <Icon type='icon-iconDown' className='text-[#444444] text-[18px]' />
                                            </div>
                                        }
                                    />
                                    {input1ListStatus && <div className='sm:w-[328px] w-[236px] z-[9999999] rounded-[5px] absolute right-[0px] top-[48px] shadow-md bg-[#FFFFFF] sm:pt-[9px] pt-[6px]'>
                                        {
                                            config?.currency.map((item, index) => {
                                                return <div key={index} className={input1Type == index ? 'sm:h-[36px] h-[26px] pl-[20px] flex items-center pr-[28px] mb-[6px] sm:mb-[6px] cursor-pointer bg-[#F4F5F6] hover:bg-[#F4F5F6]' : 'sm:h-[36px] h-[26px] pl-[20px] flex items-center pr-[28px] mb-[6px] sm:mb-[6px] cursor-pointer hover:bg-[#F4F5F6]'}
                                                    onClick={() => { setInput1Type(index); input1Methods(input1Value,index); setInput1ListStatus(false); getNorm(apiKey, config.currency[index].symbol, config.sub_symbol[input2Type].symbol); getRate(config.currency[index].symbol, config.sub_symbol[input2Type].symbol) }}
                                                >
                                                    <img src={item.icon} className="mr-[10px] sm:w-[25px] w-[18px]" />
                                                    <div className='text-[#444444] sm:text-[14px] text-[12px] flex-auto mr-[20px]'>{item.symbol}</div>
                                                    {input1Type == index && <Icon type='icon-choosed' className='text-[#444444] text-[18px]' />}
                                                </div>
                                            })
                                        }
                                    </div>}
                                    <div className='h-[18px] text-[12px] mt-[3px] px-[2px] flex justify-between'>
                                        <span className='text-[#FF0000]'>{input1Status ? 'limit ' + (type == 1 ? norm?.baseCurrency?.minBuyAmount : config?.currency[input1Type].min) + ' - ' + (type == 1 ? norm?.baseCurrency?.maxBuyAmount : config?.currency[input1Type].max) : ''}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='sm:pt-[10px] pt-[5px]'>
                                <div className='text-[#444444] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>
                                    {type == 1 ? "Purchase Quantity" : "Sale Quantity"}
                                </div>
                                <div className='business relative'>
                                    <InputNumber className='h-[48px] w-[100%]'
                                        controls={false}
                                        // type="number" pattern="[0-9]*" inputMode="decimal"
                                        placeholder={'Limit ' + (type == 1 ? norm?.quoteCurrency?.minBuyAmount : config?.sub_symbol[input2Type].min) + ' - ' + (type == 1 ? norm?.quoteCurrency?.maxBuyAmount : config?.sub_symbol[input2Type].max)}
                                        onKeyUp={(e) => { e.target.value = numberRules(e.target.value, rateRules(config?.sub_symbol[input2Type].symbol)) }}
                                        value={input2Value} onChange={(e) => {
                                            input2Methods(e)
                                        }}
                                        status={input2Status && 'error'}
                                        addonAfter={
                                            <div className='flex items-center'>
                                                {type == 2 && <div className='sm:w-[48px] w-[36px] sm:h-[20px] h-[18px] bg-[#5A47EE] mr-[10px] rounded-[2px] text-center sm:leading-[20px] leading-[18px] sm:text-[14px] text-[12px] text-[#FFFFFF] cursor-pointer' onClick={() => {
                                                    input2Methods(config?.sub_symbol[input2Type].max)
                                                }}>MAX</div>}
                                                <div className='flex items-center cursor-pointer pr-[20px]' onClick={(e) => { setInput2ListStatus(!input2ListStatus); stopBubble(e) }}>
                                                    {/* <div className='h-[16px] w-[1px] bg-[#B9B9B9] mr-[12px]'></div> */}
                                                    <div className='w-[10px] text-[#B9B9B9] text-[20px] mr-[12px] flex items-center'>|</div>
                                                    <img src={config?.sub_symbol[input2Type].icon} className="mr-[12px] sm:w-[33px] w-[24px]" />
                                                    <div className='mr-[12px] w-[40px]'>{config?.sub_symbol[input2Type].symbol}</div>
                                                    <Icon type='icon-iconDown' className='text-[#444444] text-[18px]' />
                                                </div>
                                            </div>
                                        }
                                    />
                                    {input2ListStatus && <div className='sm:w-[328px] w-[236px] z-[9999999] rounded-[5px] absolute right-[0px] top-[48px] shadow-md bg-[#FFFFFF] sm:pt-[9px] pt-[6px]'>
                                        {
                                            config?.sub_symbol.map((item, index) => {
                                                return <div key={index} className={input2Type == index ? 'sm:h-[36px] h-[26px] pl-[20px] flex items-center pr-[28px] mb-[6px] sm:mb-[6px] cursor-pointer bg-[#F4F5F6] hover:bg-[#F4F5F6]' : 'sm:h-[36px] h-[26px] pl-[20px] flex items-center pr-[28px] mb-[6px] sm:mb-[6px] cursor-pointer hover:bg-[#F4F5F6]'}
                                                    onClick={() => { setInput2Type(index); setInput2ListStatus(false); getNorm(apiKey, config.currency[input1Type].symbol, config.sub_symbol[index].symbol); getRate(config.currency[input1Type].symbol, config.sub_symbol[index].symbol) }}
                                                >
                                                    <img src={item.icon} className="mr-[10px] sm:w-[25px] w-[18px]" />
                                                    <div className='text-[#444444] sm:text-[14px] text-[12px] flex-auto mr-[20px]'>{item.symbol}</div>
                                                    {input2Type == index && <Icon type='icon-choosed' className='text-[#444444] text-[18px]' />}
                                                </div>
                                            })
                                        }
                                    </div>}
                                    <div className='h-[18px] text-[12px] mt-[3px] px-[2px] flex justify-between'>
                                        <span className='text-[#FF0000]'>{input2Status ? 'limit ' + (type == 1 ? norm?.quoteCurrency?.minBuyAmount : config?.sub_symbol[input2Type].min) + ' - ' + (type == 1 ? norm?.quoteCurrency?.maxBuyAmount : config?.sub_symbol[input2Type].max) : ''}</span>
                                        {type == 2 && <span className='text-[#000000]'>Available Balance：{Number(totalBalance)?.toFixed(2)} USDT</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='sm:pt-[10px] pt-[5px]'>
                            <div className='text-[#444444] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>Pay With</div>
                            <div className='h-[48px] w-[100%] bg-[#F7F7F7] rounded-[6px] flex items-center px-[15px]'>
                                {
                                    type == 1 ? <>
                                        <img src={config?.icons && config?.icons[0]} className="sm:w-[24px] sm:h-[18px] w-[19px] h-[14px] sm:mr-[10px] mr-[7px]" />
                                        <img src={config?.icons && config?.icons[1]} className="sm:w-[24px] sm:h-[18px] w-[19px] h-[14px] sm:mr-[10px] mr-[7px]" />
                                    </> : <>
                                        {
                                            config.currency[input1Type].symbol == 'CNY' ? <img src={alipay} className='sm:w-[24px] w-[19px] sm:mr-[10px] mr-[7px]' alt='alipay' /> :
                                                <>
                                                    <img src={config?.icons && config?.icons[0]} className="sm:w-[24px] sm:h-[18px] w-[19px] h-[14px] sm:mr-[10px] mr-[7px]" />
                                                    <img src={config?.icons && config?.icons[1]} className="sm:w-[24px] sm:h-[18px] w-[19px] h-[14px] sm:mr-[10px] mr-[7px]" />
                                                </>
                                        }
                                    </>
                                }
                                <div className='text-[#000000] sm:text-[16px] text-[12px]'>{type == 1 && 'Debit or Credit'}</div>
                                {type == 2 && <div className='text-[#000000] sm:text-[16px] text-[12px]'>{config.currency[input1Type].symbol == 'CNY' ? 'AilPay' : 'Debit or Credit'}</div>}
                            </div>
                        </div>
                        <div className='sm:h-[46px] h-[34px] cursor-pointer w-[100%] rounded-[8px] bg-[#5A47EE] mt-[35px] flex items-center justify-center' onClick={() => { buyMethods() }}>
                            <div className='text-[#FFFFFF] sm:text-[20px] text-[16px] font-bold mr-[10px]'>{type == 1 ? 'Buy Now' : 'Instant Sell'}</div>
                            <Icon type='icon-Vector' className='text-[#ffffff] sm:text-[20px] text-[14px]' />
                        </div>
                        <div className='sm:text-[16px] text-[12px] text-[#444444] pt-[14px]'>Reference Price：1 {type == 1 ? config?.currency[input1Type].symbol : config?.sub_symbol[input2Type].symbol} ≈ {(1 * Number(rate)).toFixed(
                            rateRules(config?.sub_symbol[input2Type].symbol)
                        )} {type == 1 ? config?.sub_symbol[input2Type].symbol : config?.currency[input1Type].symbol}</div>
                    </div>
                </article>
            </main>
            <PassowrdModal type='kyc' visible={isModalOpen} handleClose={handleClose} />
        </div>
    </>
}

export default Business;