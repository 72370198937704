import { useEffect, useState, useRef, createRef, useCallback } from 'react'
import { Input, message, InputNumber } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'


import Header from "../../component/header";
import Icon from '../../icon';
import { stopBubble, rules, newRules } from '../../utils'

import businessBg from '../../assets/images/businessProcessBg.png'
import businessBgH5 from '../../assets/images/businessBgH5.png'
import alipay from '../../assets/images/alipay.png'

import { addBank, isPayPassword, getConfig } from '@/api/path/login'

import PassowrdModal from '../../components/passowrdModal/index'
import ToastNotification from '../../components/toast/index'

function AddBankCardNext() {

    const navigate = useNavigate()
    const state = useLocation().state
    const { width } = useSelector((state) => state.global)
    const [messageApi, contextHolder] = message.useMessage()


    const [bankChoose, setBankChoose] = useState(-1)
    const [listStatus, setListStatus] = useState(false)

    const [iphone, setIphone] = useState('')

    const [usType, setUsType] = useState([])
    const [usCityList, setUsCityList] = useState([])
    const [cityListStatus, setCityListStatus] = useState(false)
    const [cityChoose, setCityChoose] = useState(-1)

    const [bankName, setBankName] = useState('')
    const [bankCode, setBankCode] = useState('')
    const [bankAddress, setBankAddress] = useState('')
    const [postalCode, setPostalCode] = useState('')

    useEffect(() => {
        getConfigInfo()
        document.addEventListener('click', () => {
            setListStatus(false)
        })
        isPayPassword().then(res => {
            setModalType(res.data.state)
        })
    }, [])

    const getConfigInfo = () => {
        getConfig('us_bankcard').then(res => {
            setUsType(JSON.parse(res.data).account_type)
            setUsCityList(JSON.parse(res.data).city)
        })
    }

    let c = createRef(null);
    const handleChick = useCallback(() => {
        c.current.func();
    }, [c]);

    const submitMethods = () => {
        if (state.currency == 'CNY') {
            if (bankChoose == -1) {
                messageApi.error('Please select a bank');
                return false
            }
            if (!iphone) {
                messageApi.error('Please enter your phone number');
                return false
            }
        } else if (state.currency == 'USD') {
            if (bankChoose == -1) {
                messageApi.error('Select account type');
                return false
            }
            if (!bankName) {
                messageApi.error('Bank name required');
                return false
            }
            if (!iphone) {
                messageApi.error('Receipt account number cannot be empty');
                return false
            }
            if (iphone.length < 14) {
                messageApi.error('Fill in the correct account number');
                return false
            }
            if (!bankCode) {
                messageApi.error('Fill in the correct bank code');
                return false
            }
            if (bankCode.length < 9) {
                messageApi.error('Fill in the correct bank code');
                return false
            }
            if (!bankAddress) {
                messageApi.error('Fill in the correct address');
                return false
            }
            if (cityChoose == -1) {
                messageApi.error('Select city');
                return false
            }
            if (!postalCode) {
                messageApi.error('The postal code cannot be empty');
                return false
            }
            if (postalCode.length < 5) {
                messageApi.error('Fill in the correct postal code');
                return false
            }
        } else {
            if (!bankName) {
                messageApi.error('Bank name required');
                return false
            }
            if (!iphone) {
                messageApi.error('Receipt account number cannot be empty');
                return false
            }
        }

        setIsModalOpen(true)
    }

    const bankList = [
        { icon: alipay, name: 'AliPay' }
    ]

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(0);
    const [modalValue, setModalValue] = useState('');

    const handleClose = () => {
        setIsModalOpen(false)
        setModalValue('')
    }
    const handleChange = (e) => {
        setModalValue(e.target.value)
    }
    const handleSubmit = () => {
        if (!modalValue) {
            messageApi.error('Please input a password')
            return false
        }
        let data = {
            country: state.country,
            currency: state.currency,
            city: usCityList[cityChoose],
            street1: bankAddress,
            bank_id: bankCode,
            postal_code: postalCode,
            card_type: usType[bankChoose],
            number: iphone,
            bank_name: state.currency == 'CNY' ? bankList[bankChoose]?.name : bankName,
            payment_password: modalValue
        }
        addBank(data).then(res => {
            if (res.code == 1000) {
                setIsModalOpen(false)
                handleChick()
                setModalValue('')
                setTimeout(function () {
                    navigate('/bankcard')
                }, "1500");
            } else {
                messageApi.error(res.message)
            }
        }).catch(err => {
            messageApi.error(err.message)
        })
    }


    return <>
        <div className="App mx-auto min-h-[100vh]">
            <ToastNotification onRef={c} />
            {contextHolder}
            <main className='relative min-h-[100vh]'>
                <div className='w-[100%] sm:min-h-[100vh] h-[100%] min-h-[100%] absolute bg-[#000000]'>
                    <img src={width > 768 ? businessBg : businessBgH5} className='w-[100%] h-[100%]' />
                </div>
                <div className="border-b border-[#161654] border-solid sticky top-0 bg-[#010220] relative z-[100]">
                    <Header />
                </div>
                <article className='mx-auto sm:px-[0px] px-[18px] relative'>
                    <div className='sm:w-[916px] pb-[50px]' style={{ 'margin': '0 auto' }}>
                        <div className='text-[16px] text-[#ffffff] flex items-center sm:pt-[20px] pt-[10px] sm:pb-[50px] pb-[16px]'>
                            <Icon type='icon-backColors' className='text-[16px] mr-[8px] cursor-pointer' onClick={() => { navigate('/addBankCard', { state: { index: state.index } }) }} />
                            <span className='cursor-pointer' onClick={() => { navigate('/addBankCard', { state: { index: state.index } }) }}>Back</span>
                        </div>
                        <div className='sm:text-[30px] text-[20px] text-[#FFFFFF] font-bold'>Card Information</div>
                        <div className='sm:text-[16px] text-[12px] sm:mb-[20px] mb-[8px] pt-[10px]'>Please enter your English bank card information. <span className='text-[#0DC7CB]'>ChiChaPay</span> values and protects user privacy and security, and your personal information will be encrypted throughout the process</div>


                        {
                            state.currency == 'CNY' ? <>
                                <div className='sm:pt-[25px] pt-[20px] sm:w-[428px]'>
                                    <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>Bank Name</div>
                                    <div className='relative'>
                                        <div className='h-[48px] w-[100%] bg-[#F7F7F7] rounded-[6px] flex items-center px-[15px] cursor-pointer' onClick={(e) => { setListStatus(!listStatus); stopBubble(e) }}>
                                            {bankChoose != -1 && <img src={bankList[bankChoose].icon} className='w-[27px] mr-[12px]' alt='alipay' />}
                                            <div className='text-[#000000] sm:text-[16px] text-[16px] flex-auto'>{bankChoose == -1 ? 'Please select your issuing bank' : bankList[bankChoose].name}</div>
                                            <Icon type='icon-iconDown' className='text-[#000000]' />
                                        </div>
                                        {
                                            listStatus && <div className='absolute w-[100%] max-h-[300px] bg-[#ffffff] left-0 top-[58px] z-50 rounded-[6px] px-[10px] pt-[20px]' onClick={(e) => { stopBubble(e) }}>
                                                {
                                                    bankList.map((item, index) => {
                                                        return <div key={index} className={`w-[100%] h-[48px] bg-[#E7E7E7] border ${bankChoose == 0 ? 'border-[#5A47EE]' : 'border-[#E7E7E7]'} hover:border-[#5A47EE] border-solid mb-[20px] rounded-[6px] flex items-center justify-center cursor-pointer`} onClick={(e) => {
                                                            setBankChoose(index); setListStatus(false);
                                                        }}>
                                                            <img src={item.icon} className='w-[27px] mr-[12px]' alt='alipay' />
                                                            <div className='text-[18px] text-[#000000]'>{item.name}</div>
                                                        </div>
                                                    })
                                                }

                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='sm:pt-[70px] pt-[20px] sm:w-[428px]'>
                                    <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>AliPay Account</div>
                                    <div className='business businesss'>
                                        <Input className='h-[48px] w-[100%]'
                                            value={iphone}
                                            type="text" pattern="\d*"
                                            onChange={(e) => { setIphone(newRules(e.target.value)) }}
                                            onKeyUp={(e) => {
                                                e.target.value = newRules(e.target.value)
                                            }}
                                            maxLength='13'
                                            placeholder='AliPay Account' />
                                    </div>
                                </div>
                            </> : state.currency == 'USD' ? <>
                                <div className='sm:flex sm:justify-between sm:flex-wrap pb-[30px]'>
                                    <div className='sm:pt-[25px] pt-[20px] sm:w-[428px]'>
                                        <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>Account Type</div>
                                        <div className='relative'>
                                            <div className='h-[48px] w-[100%] bg-[#F7F7F7] rounded-[6px] flex items-center px-[15px] cursor-pointer' onClick={(e) => { setListStatus(!listStatus); stopBubble(e) }}>
                                                <div className='text-[#000000] sm:text-[16px] text-[16px] flex-auto'>{bankChoose == -1 ? 'Select account type' : usType[bankChoose]}</div>
                                                <Icon type='icon-iconDown' className='text-[#000000]' />
                                            </div>
                                            {
                                                listStatus && <div className='absolute w-[100%] max-h-[300px] bg-[#ffffff] left-0 top-[58px] z-50 rounded-[6px] px-[10px] pt-[20px]' onClick={(e) => { stopBubble(e) }}>
                                                    {
                                                        usType.map((item, index) => {
                                                            return <div key={index} className={`w-[100%] h-[48px] bg-[#ffffff] border ${bankChoose == index ? 'bg-[#E7E7E7]' : 'border-[#ffffff]'} hover:bg-[#E7E7E7] border-solid mb-[20px] rounded-[6px] flex items-center justify-center cursor-pointer`} onClick={(e) => {
                                                                setBankChoose(index); setListStatus(false);
                                                            }}>
                                                                <div className='text-[18px] text-[#000000]'>{item}</div>
                                                            </div>
                                                        })
                                                    }

                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='sm:pt-[25px] pt-[20px] sm:w-[428px]'>
                                        <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>Bank Name</div>
                                        <div className='relative'>
                                            <div className='business businesss'>
                                                <Input className='h-[48px] w-[100%]'
                                                    value={bankName}
                                                    type="text"
                                                    onChange={(e) => { setBankName(e.target.value) }}
                                                    // onKeyUp={(e) => {
                                                    //     e.target.value = rules(e.target.value)
                                                    // }}
                                                    maxLength='50'
                                                    placeholder='Bank Name' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sm:pt-[25px] pt-[20px] sm:w-[428px]'>
                                        <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>Account Number</div>
                                        <div className='relative'>
                                            <div className='business businesss'>
                                                <Input className='h-[48px] w-[100%]'
                                                    value={iphone}
                                                    type="text" pattern="\d*"
                                                    onChange={(e) => { setIphone(newRules(e.target.value)) }}
                                                    onKeyUp={(e) => {
                                                        e.target.value = newRules(e.target.value)
                                                    }}
                                                    // minLength='12'
                                                    maxLength='24'
                                                    placeholder='Account Number' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sm:pt-[25px] pt-[20px] sm:w-[428px]'>
                                        <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>Bank Code</div>
                                        <div className='relative'>
                                            <div className='business businesss'>
                                                <Input className='h-[48px] w-[100%]'
                                                    value={bankCode}
                                                    type="text" pattern="\d*"
                                                    onChange={(e) => { setBankCode(rules(e.target.value)) }}
                                                    onKeyUp={(e) => {
                                                        e.target.value = rules(e.target.value)
                                                    }}
                                                    maxLength='9'
                                                    placeholder='Bank Code' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='info pt-[25px]'></div>
                                <div className='sm:flex sm:justify-between sm:flex-wrap'>
                                    <div className='sm:pt-[25px] pt-[20px] sm:w-[428px]'>
                                        <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>Address</div>
                                        <div className='relative'>
                                            <div className='business businesss'>
                                                <Input className='h-[48px] w-[100%]'
                                                    value={bankAddress}
                                                    type="text"
                                                    onChange={(e) => { setBankAddress(e.target.value) }}
                                                    maxLength='50'
                                                    placeholder='Address' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sm:pt-[25px] pt-[20px] sm:w-[428px]'>
                                        <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>City</div>
                                        <div className='relative'>
                                            <div className='h-[48px] w-[100%] bg-[#F7F7F7] rounded-[6px] flex items-center px-[15px] cursor-pointer' onClick={(e) => { setCityListStatus(!cityListStatus); stopBubble(e) }}>
                                                <div className='text-[#000000] sm:text-[16px] text-[16px] flex-auto'>{cityChoose == -1 ? 'Select City' : usCityList[cityChoose]}</div>
                                                <Icon type='icon-iconDown' className='text-[#000000]' />
                                            </div>
                                            {
                                                cityListStatus && <div className='absolute w-[100%] max-h-[300px] bg-[#ffffff] left-0 top-[58px] z-50 rounded-[6px] px-[10px] pt-[20px] overflow-y-auto' onClick={(e) => { stopBubble(e) }}>
                                                    {
                                                        usCityList.map((item, index) => {
                                                            return <div key={index} className={`w-[100%] h-[48px] border ${cityChoose == index ? 'bg-[#E7E7E7]' : 'border-[#ffffff]'} hover:bg-[#E7E7E7] border-solid mb-[20px] rounded-[6px] flex items-center justify-center cursor-pointer`} onClick={(e) => {
                                                                setCityChoose(index); setCityListStatus(false);
                                                            }}>
                                                                <div className='text-[18px] text-[#000000]'>{item}</div>
                                                            </div>
                                                        })
                                                    }

                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='sm:pt-[25px] pt-[20px] sm:w-[428px]'>
                                        <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>Postal Code</div>
                                        <div className='relative'>
                                            <div className='business businesss'>
                                                <Input className='h-[48px] w-[100%]'
                                                    value={postalCode}
                                                    type="text" pattern="\d*"
                                                    onChange={(e) => { setPostalCode(rules(e.target.value)) }}
                                                    onKeyUp={(e) => {
                                                        e.target.value = rules(e.target.value)
                                                    }}
                                                    maxLength='5'
                                                    placeholder='Postal Code' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> : <>
                                <div className='sm:pt-[25px] pt-[20px] sm:w-[428px]'>
                                    <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>Bank Name</div>
                                    <div className='relative'>
                                        <div className='business businesss'>
                                            <Input className='h-[48px] w-[100%]'
                                                value={bankName}
                                                type="text"
                                                onChange={(e) => { setBankName(e.target.value) }}
                                                // onKeyUp={(e) => {
                                                //     e.target.value = rules(e.target.value)
                                                // }}
                                                maxLength='50'
                                                placeholder='Bank Name' />
                                        </div>
                                    </div>
                                </div>
                                <div className='sm:pt-[25px] pt-[20px] sm:w-[428px]'>
                                    <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>Account Number</div>
                                    <div className='relative'>
                                        <div className='business businesss'>
                                            <Input className='h-[48px] w-[100%]'
                                                value={iphone}
                                                type="text" pattern="\d*"
                                                onChange={(e) => { setIphone(newRules(e.target.value)) }}
                                                onKeyUp={(e) => {
                                                    e.target.value = newRules(e.target.value)
                                                }}
                                                // minLength='12'
                                                maxLength='19'
                                                placeholder='Account Number' />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        <div className='sm:h-[58px] h-[48px] bg-[#5A47EE] cursor-pointer sm:mt-[200px] mt-[28px] rounded-[8px] flex items-center justify-center' onClick={() => { submitMethods() }}>
                            <div className='text-[#FFFFFF] sm:text-[20px] text-[16px] font-bold mr-[10px]'>Confirm Addition</div>
                        </div>
                        <div className='mt-[10px] sm:text-[16px] text-[12px] sm:text-left text-center'>Note: Please fill in the relevant information in English.</div>
                    </div>
                </article>
            </main>
            <PassowrdModal type={modalType} visible={isModalOpen} handleClose={handleClose} value={modalValue} handleChange={handleChange} handleSubmit={handleSubmit} />
        </div>
    </>
}

export default AddBankCardNext;