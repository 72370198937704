import { useNavigate, useSearchParams } from 'react-router-dom'
import Header from "../../component/header";
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Input, message, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux'


import { setTokenAction } from '../../store/userInfo'


import { fetchLogin } from '../../api/path/login'
import loginPcBg from '../../assets/images/loginPcBg.png'
import passwordIcon1 from '../../assets/images/passwordIcon1.png'
import passwordIcon2 from '../../assets/images/passwordIcon2.png'


function NewLogin() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [search] = useSearchParams()
    const [messageApi, contextHolder] = message.useMessage()


    const onFinish = (values) => {
        let params = {
            'email': values.email,
            'password': values.password
        }

        fetchLogin(params).then(res => {
            const token = res['x-auth-token']
            localStorage.setItem('token', token)
            dispatch(setTokenAction(token))
            navigate('/')
        }).catch((err) => {
            messageApi.error(err.message)
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return <div style={{ overflowY: 'auto', background: `url(${loginPcBg})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', backgroundPosition: 'bottom' }}
        className="App mx-auto bg-[#F4F4F4]">
        {contextHolder}
        <main className='h-screen'>
            <div className="sticky top-0 relative  z-[50]">
                <Header />
            </div>
            <article className='mx-auto sm:pt-[56px] pt-[20px] px-[20px]'>
                {/* w-[530px] */}
                <div style={{ 'margin': '0 auto' }} className="max-w-[535px] sm:pb-[87px] rounded-md px-[20px] sm:px-[70px]">
                    <div className="text-[#FFFFFF] sm:text-[30px] text-[20px] font-medium sm:mb-[25px] mb-[20px] text-center">ChiChaPay Login</div>
                    <div>
                        <Form
                            autoComplete="off"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}>
                            <Form.Item
                                name="email"
                                rules={[
                                    { required: true, message: 'Please input your email!' },
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                ]}
                            >
                                <div>
                                    <div className="text-[#FFFFFF] sm:text-[14px] text-[16px] font-light mb-[8px]">Email</div>
                                    <Input.Password
                                        visibilityToggle={{ visible: true }}
                                        className='sm:h-[60px] h-[43px]'
                                        placeholder="Enter your email address..."
                                        iconRender={(visible) => (visible ? <EyeOutlined style={{ color: '#fff' }} /> : <EyeInvisibleOutlined style={{ color: '#fff' }} />)}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                <div>
                                    <div className="text-[#FFFFFF] sm:text-[14px] text-[16px] font-light mb-[8px] flex justify-between">
                                        <span>Password</span>
                                    </div>
                                    <Input.Password
                                        className='sm:h-[60px] h-[43px]'
                                        placeholder="Enter your password..."
                                        autoComplete="new-password"
                                        iconRender={(visible) => (visible ? <img src={passwordIcon2} alt='passwordIcon2' /> : <img src={passwordIcon1} alt='passwordIcon1' />)}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item>
                                <div id='create' className='w-[100%]'>
                                    <Button className='sm:text-[20px] text-[14px] w-[100%] sm:h-[60px] h-[43px] text-[#fff] rounded-md bg-[#5A47EE] flex justify-center items-center cursor-pointer' type="primary" htmlType="submit">
                                        Login
                                    </Button>
                                </div>
                                <div className='text-[14px] sm:text-[#5A47EE] text-[#D9D9D9] font-normal cursor-pointer pt-[10px] text-left' onClick={() => {navigate('/register')}}>Create a ChiChaPay Account</div>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </article>
        </main>
    </div>
}

export default NewLogin;