import { useNavigate, useSearchParams } from 'react-router-dom'
import logo from '../../../assets/images/newLogo.png'

function Header() {
  const navigate = useNavigate()
  const [search] = useSearchParams()
  // w-full flex items-center mx-auto
  return <header className=''>
    <div className='cursor-pointer max-w-[1200px] py-[20px]' style={{ 'margin': '0 auto' }}>
      <img src={logo} alt='logo' className='sm:w-[263px] w-[0px]' onClick={() => {
        if(search.get('backUrl')){
          window.location.href=search.get('backUrl')
        }else{
          navigate('/')
        }
      }} />
    </div>
  </header>
}

export default Header