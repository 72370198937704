import { useEffect, useState } from 'react'
import { message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom'

import Icon from '../icon';
import { copyFn, stopBubble } from '../utils'


import { useSelector, useDispatch } from 'react-redux'

import { clearUserInfoAction } from '../store/userInfo'

import logo from '../assets/images/logo.png'
import menu from '../assets/images/menu.png'
import closeH5 from '../assets/images/closeH5.png'
import defaultUser from '../assets/images/defaultUser.png'
import './index.css'



function Header() {

	const dispatch = useDispatch()

	const { user } = useSelector((state) => state.user)
	const { width } = useSelector((state) => state.global)
	const [messageApi, contextHolder] = message.useMessage()
	const location = useLocation()
	const navigate = useNavigate()
	const [userInfo, setUserInfo] = useState(null);
	const [userStatus, setUserStatus] = useState(false);
	const [status, setStatus] = useState(true)

	
	useEffect(() => {
		setUserInfo(user)
	}, [user])

	useEffect(() => {
		document.addEventListener('click', () => {
			setUserStatus(false)
			setNavStatus(false)
		})
	}, [])


	const [navStatus, setNavStatus] = useState(false)
	let navList = [
		{ name: 'Buy Crypto', icon: 'icon-buyCrypto', value: 'Trade USDT with Visa/Mastrcard', route: '/business' },
		{ name: 'Token BulkSender', icon: 'icon-bulksender', value: 'Use Token BulkSender to send tokens in bulk', route: '/bulksender' },
		{ name: 'Fiat Currency BulkSender', icon: 'icon-bulksenders', value: 'Batch withdrawals with Fiat Currency BulkSender', route: '/bulksenders' },
		{ name: 'Exchange', icon: 'icon-bulksenders', value: 'exchange', route: '/exchange' }
	]


	return <>
		<header className='w-full flex items-center justify-between mx-auto sm:w-[1200px] sm:h-[64px] select-none'>
			{contextHolder}
			<div className='cursor-pointer'>
				<img src={logo} alt='logo' className='sm:w-[140px] sm:pl-[0] w-[157px] py-[10px] pl-[18px]' onClick={() => {
					if (location.pathname == '/') {
						document.body.scrollTop = 0;
						document.documentElement.scrollTop = 0;
						navigate('/')
					} else {
						navigate('/')
					}
				}} />
			</div>
			{
				width > 768 ? <>

					<nav className='ml-10 flex h-[100%] items-center'>
						{
							location.pathname == '/' ? <>
								<a href='#support' className='hover:text-[#06DCBF]'>Supported Wallet</a>
								<a href='#demo' className='ml-5 hover:text-[#06DCBF]'>Demo</a>
								<a href='#openapi' className='ml-5 hover:text-[#06DCBF]'>OpenAPI</a>
							</> : <>
								<div onClick={() => { navigate('/') }} className='hover:text-[#06DCBF] cursor-pointer'>Supported Wallet</div>
								<div onClick={() => { navigate('/') }} className='ml-5 hover:text-[#06DCBF] cursor-pointer'>Demo</div>
								<div onClick={() => { navigate('/') }} className='ml-5 hover:text-[#06DCBF] cursor-pointer'>OpenAPI</div>
							</>
						}

						<div className='navHover flex items-center relative cursor-pointer h-[100%]'>
							{/* onClick={(e) => { setNavStatus(!navStatus); stopBubble(e); }} */}
							<div className='ml-5 hover:text-[#06DCBF] mr-[6px] flex h-[100%] items-center'>Products</div>
							<div className='rotate'>
								<Icon type='icon-iconDown' className='text-[#ffffff] text-[18px]' />
							</div>
							<div className='navListBox hidden absolute top-[60px] left-[0px] rounded-[6px] w-[350px] bg-[#15174B] px-[8px] py-[15px]'>
								{
									navList.map((item, index) => {
										return <div key={index} className='h-[48px] flex items-center py-[30px] cursor-pointer hover:bg-[#3E2DA8] navList'
											onClick={() => {
												if (localStorage.getItem('token')) {
													navigate(item.route)
												} else {
													navigate('/login')
												}
											}}>
											<Icon type={item.icon} className='text-[28px] mr-[10px] ml-[13px]' />
											<div className='text-[#FFFFFF]'>
												<div className='text-[15px] font-bold'>{item.name}</div>
												<div className='text-[12px]'>{item.value}</div>
											</div>
										</div>
									})
								}
							</div>
						</div>
					</nav>

					<div className='flex flex-auto justify-end relative h-[100%]'>
						{
							userInfo ? <>

								<div className='userHover h-[100%] flex items-center'>
									<div className='flex justify-center items-center cursor-pointer head'>
										{/* <Icon type='icon-userIcon' className='text-[28px] mr-[10px] ml-[13px]' onClick={(e) => { setUserStatus(!userStatus); stopBubble(e) }} /> */}
										<img className='w-[40px] h-[40px] rounded-full' src={userInfo.avatar_url ? userInfo.avatar_url : defaultUser} />
										{/* <div className='ml-3' onClick={(e) => { setUserStatus(!userStatus); stopBubble(e) }}>{userInfo.nickname}</div> */}
									</div>
									<div className='userBox hidden w-[270px] pt-[30px] px-[12px] z-40 bg-[#15174B] absolute top-[60px] right-[-100px] shadow-xl rounded-[2px]'>
										<div className='pb-[15px]' style={{ 'borderBottom': 'dashed 1px #B9B9B9' }}>
											{/* <img className='w-[64px] h-[64px] rounded-full' src={userInfo.avatar_url} /> */}
											<div className='pl-[15px]'>
												<div className='text-[#FFFFFF] text-[18px]'>{userInfo.user_email}</div>
												<div className='text-[#ffffff] text-[12px] pt-[5px] mb-[6px] flex items-center'>UID: {userInfo.id}<Icon onClick={(e) => { copyFn(userInfo.id, true); messageApi.success('Successful Replication'); stopBubble(e) }} type='icon-copy' className='cursor-pointer ml-[5px] text-[16px] text-[#ffffff]' /></div>
												{
													userInfo?.user_kyc_info.KycStatus == '3' ? <span className='bg-[#5A47EE] text-[10px] text-[#FFFFFF] px-[10px] py-[3px] rounded-[50px]'>{userInfo?.user_type == '1' ? 'Users' : 'Merchants'}</span> :
														<span className='bg-[#5A47EE] text-[10px] text-[#FFFFFF] px-[10px] py-[3px] rounded-[50px]'>{userInfo?.user_kyc_info.KycStatus == '2' ? 'Failed' : userInfo?.user_kyc_info.KycStatus == '0' ? 'Unverified' : ''}</span>

												}
											</div>
										</div>
										<div className='py-[15px]'>
											<div className='h-[48px] flex items-center py-[30px] cursor-pointer hover:bg-[#3E2DA8] navList' onClick={() => {
												navigate('/user')
											}}>
												<Icon type='icon-a-Group951' className='text-[28px] mr-[10px] ml-[13px]' />
												<div className='text-[#FFFFFF]'>
													<div className='text-[15px] font-bold'>Personal Center</div>
												</div>
											</div>
											<div className='h-[48px] flex items-center py-[30px] cursor-pointer hover:bg-[#3E2DA8] navList' onClick={() => {
												navigate('/user')
											}}>
												<Icon type='icon-userOrderIcon' className='text-[28px] mr-[10px] ml-[13px]' />
												<div className='text-[#FFFFFF]'>
													<div className='text-[15px] font-bold'>Orders</div>
												</div>
											</div>
											<div className='text-center pt-[20px] cursor-pointer' onClick={() => {
												localStorage.removeItem('token')
												dispatch(clearUserInfoAction())
												setUserInfo(null)
												navigate('/')
											}}>Logout</div>
										</div>
									</div>
								</div>
							</> : <>
								{
									location.pathname == '/' && <>
										<div className='flex items-center'>
											<div className='w-[84px] h-[30px] flex justify-center items-center cursor-pointer' onClick={() => {
												navigate('/login')
											}}>LogIn</div>
											<div className='w-[84px] h-[30px] bg-[#5A47EE] rounded-[5px] flex justify-center items-center cursor-pointer' onClick={() => {
												navigate('/register')
											}}>SignUp</div>
										</div>
									</>
								}
							</>
						}

					</div>
				</> : <>
					{
						location.pathname != '/login' && location.pathname != '/register' && (status ? <img onClick={() => setStatus(false)} src={menu} alt='menu' className='w-[35px] pr-[18px]' />
							: <img onClick={() => setStatus(true)} src={closeH5} alt='close' className='w-[35px] pr-[18px]' />)
					}
				</>
			}


		</header>
		{
			!status && <>
				<div className="border-t border-[#161654] border-solid px-[20px] relative overflow-y-auto" style={{ 'width': '100vw', 'height': 'calc( 100vh - 52px )', 'backgroundColor': '#010220' }}>
					<div className='mb-[100px]'>
						<div className='pt-[16px] pb-[25px] flex'>
							<img src={defaultUser} className='w-[80px]' />
							<div>
								{
									userInfo ? <div className='pl-[15px]'>
										<div className='text-[#FFFFFF] text-[18px]'>{userInfo && userInfo.user_email}</div>
										<div className='text-[#ffffff] text-[12px] pt-[5px] mb-[6px] flex items-center'>UID: {userInfo && userInfo.id}<Icon onClick={(e) => { copyFn(userInfo.id, true); messageApi.success('Successful Replication'); stopBubble(e) }} type='icon-copy' className='cursor-pointer ml-[5px] text-[16px] text-[#ffffff]' /></div>
										<span className='bg-[#5A47EE] text-[10px] text-[#FFFFFF] px-[10px] py-[3px] rounded-[50px]'>{userInfo?.user_type == '1' ? 'Users' : 'Merchants'}</span>
									</div> : <div className='text-[#FFFFFF] text-[18px] text-bold pl-[20px] leading-[80px]' onClick={() => {
										navigate('/login')
									}}>LogIn / SignUp</div>
								}
							</div>
						</div>
						<p className='py-[16px]' style={{ 'borderBottom': 'dashed 1px #31314B' }}><a onClick={() => setStatus(true)} href='#support' className='ml-3 hover:text-[#06DCBF] text-[16px] text-bold'>Supported Wallet</a></p>
						<p className='py-[16px]' style={{ 'borderBottom': 'dashed 1px #31314B' }}><a onClick={() => setStatus(true)} href='#demo' className='ml-3 hover:text-[#06DCBF] text-[16px] text-bold'>Demo</a></p>
						<p className='py-[16px]' style={{ 'borderBottom': 'dashed 1px #31314B' }}><a onClick={() => setStatus(true)} href='#openapi' className='ml-3 hover:text-[#06DCBF] text-[16px] text-bold'>OpenAPI</a></p>
						<div className='py-[16px]' style={{ 'borderBottom': 'dashed 1px #31314B' }}>

							<div className='flex justify-start items-center' onClick={(e) => { setNavStatus(!navStatus); stopBubble(e) }}>
								<div className='ml-3 mr-[6px]'>Products</div>
								<div className={navStatus ? 'rotates' : 'rotate'}>
									<Icon type='icon-iconDown' className='text-[#ffffff] text-[18px]' />
								</div>
								{/* <Icon type='icon-iconDown' className='text-[#ffffff] text-[18px] ml-[6px] mt-[4px]' /> */}
							</div>
							{
								navStatus && <>
									{
										navList.map((item, index) => {
											return <div key={index} className='h-[48px] flex items-center py-[30px] cursor-pointer hover:bg-[#0B0053] navList'
												onClick={() => {
													if (localStorage.getItem('token')) {
														navigate(item.route)
													} else {
														navigate('/login')
													}
												}}>
												<Icon type={item.icon} className='text-[28px] mr-[10px] ml-[13px]' />
												<div className='text-[#FFFFFF]'>
													<div className='text-[15px] font-bold'>{item.name}</div>
													<div className='text-[12px] text-[#B9B9B9]'>{item.value}</div>
												</div>
											</div>
										})
									}
								</>
							}
						</div>
						{userInfo && <div className='py-[16px]' style={{ 'borderBottom': 'dashed 1px #31314B' }}><span className='ml-3 hover:text-[#06DCBF] text-[16px] text-bold' onClick={() => {
							if (localStorage.getItem('token')) {
								navigate('/user')
							} else {
								navigate('/login')
							}
						}}>Orders</span></div>}
						{userInfo && <div className='py-[16px]' style={{ 'borderBottom': 'dashed 1px #31314B' }}><span className='ml-3 hover:text-[#06DCBF] text-[16px] text-bold' onClick={() => {
							if (localStorage.getItem('token')) {
								navigate('/bankcard')
							} else {
								navigate('/login')
							}
						}}>Bank Card</span></div>}
						<div className='w-[100%] px-[14px] mt-[20px]'>
							{
								userInfo ? <>
									<div className='w-[100%] h-[43px] bg-[#1F1F1F] text-[#E7E7E7] rounded-[10px] text-center leading-[43px] text-[16px] mt-[40px]' onClick={() => {
										localStorage.removeItem('token')
										setUserInfo(null)
										dispatch(clearUserInfoAction())
										setStatus(true)
										navigate('/')
									}}>LogOut</div>
								</> : <>
									<div className='w-[100%] h-[43px] bg-[#5A47EE] rounded-[10px] text-center leading-[43px] text-[16px]' onClick={() => {
										navigate('/login')
									}}>LogIn</div>
									<div className='w-[100%] h-[43px] bg-[#1F1F1F] text-[#E7E7E7] rounded-[10px] text-center leading-[43px] text-[16px] mt-[20px]' onClick={() => {
										navigate('/register')
									}}>SignUp</div>
								</>
							}

						</div>
					</div>
				</div>
			</>
		}
	</>
}

export default Header