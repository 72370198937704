import React, { useEffect, useRef, useState } from 'react';

import CodeMirror from '@uiw/react-codemirror';
import { createTheme } from '@uiw/codemirror-themes';
import { javascript } from '@codemirror/lang-javascript';
import { tags as t } from '@lezer/highlight';
import * as XLSX from 'xlsx';
import { getConfigList, grant, getSubsymbolConf, getBalances, sendEmail, getUserInfo } from '../../api/path/login'

import { Input, Upload, message, Modal, Radio } from 'antd';
import { EyeTwoTone, EyeInvisibleTwoTone } from '@ant-design/icons';
import Header from "../../component/header";
import './test.css'

import payHead from '../../assets/images/payHead.png'
import payHeads from '../../assets/images/payHeads.png'
import ChiChaPay from '../../assets/images/ChiChaPay.png'
import upload from '../../assets/images/upload.png'
import down from '../../assets/images/down.png'
import listSuccess from '../../assets/images/listSuccess.png'
import listError from '../../assets/images/listError.png'
import close from '../../assets/images/close.png'

const { Dragger } = Upload;


const myTheme = createTheme({
    theme: 'dark',
    settings: {
        background: '#1B1B1B',
        foreground: '#75baff',
        caret: '#ffffff',
        selection: '#036dd6',
        selectionMatch: '#036dd626',
        lineHighlight: '#8a91991a',
        gutterBackground: '#1B1B1B',
        gutterForeground: '#8a919966',
    },
    styles: [
        { tag: t.comment, color: '#787b8099' },
        { tag: t.variableName, color: '#0080ff' },
        { tag: [t.string, t.special(t.brace)], color: '#ffffff' },
        { tag: t.number, color: '#ffffff' },
        { tag: t.bool, color: '#ffffff' },
        { tag: t.null, color: '#ffffff' },
        { tag: t.keyword, color: '#ffffff' },
        { tag: t.operator, color: '#ffffff' },
        { tag: t.className, color: '#ffffff' },
        { tag: t.definition(t.typeName), color: '#ffffff' },
        { tag: t.typeName, color: '#ffffff' },
        { tag: t.angleBracket, color: '#ffffff' },
        { tag: t.tagName, color: '#ffffff' },
        { tag: t.attributeName, color: '#ffffff' },
    ],
});
const extensions = [javascript({ jsx: true })];

function Bulksender() {

    const [network, setNetwork] = useState(0);

    const [width, setWidth] = useState('33.3')

    const [top, setTop] = useState('0')

    const [opacity, setOpacity] = useState('1')
    // 650
    const [top1, setTop1] = useState('1000')

    const [opacity1, setOpacity1] = useState('0')

    const [tokenValue, setTokenValue] = useState('')
    const [tokenLists, setTokenLists] = useState([])
    const [tokenListStatus, setTokenListStatus] = useState(false)
    const [addressList, setAddressList] = useState([])

    const [remark, setRemark] = useState('')
    const [amount, setAmount] = useState(0)

    const [result, setResult] = useState(false)
    const [resultList, setResultList] = useState([])

    const [addressValue, setAddressValue] = useState('')

    const [newlist, setNewlist] = useState([])

    const [rate, setRate] = useState(0)
    const [totalBalance, setTotalBalance] = useState(0)

    const [inputType, setInputType] = useState(0)

    const [topStatus, setTopStatus] = useState(1)

    const [completeStatus, setCompleteStatus] = useState(true)

    useEffect(() => {
        getConfigList().then(res => {
            setTokenLists(JSON.parse(res.data))
            setTokenValue(JSON.parse(res.data)[0])
            getMoney(JSON.parse(res.data)[0])
        })

    }, [])

    const getMoney = (type) => {
        getBalances(type).then(res => {
            setTotalBalance(res.data.user_balances[0].total_balance)
        })
    }

    const grantSubmit = () => {
        if (!moadlInput1) {
            message.error('Please enter the verification code!');
            return false
        }
        if (!moadlInput2) {
            message.error('Please input a password!');
            return false
        }
        let params = {
            "auth_code": moadlInput1,
            "password": moadlInput2,
            "sub_symbol": tokenValue,
            "remark": remark,
            "employee_lst": addressList
        }
        grant(params).then(res => {
            res.data.employee_lst.map(i => {
                if (i.status != '200') {
                    setCompleteStatus(false)
                }
            })
            setResultList(res.data.employee_lst)
            setIsModalOpen(false)
            setWidth('100')
            setTop1(1000)
            setOpacity1(0)
            setResult(true)
            setTopStatus(3)
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            setMoadlInput1('')
            setMoadlInput2('')
        }).catch(error => {
            message.error(error.message)
        })
    }

    const backSubmit = () => {
        setTop(10)
        setOpacity(1)
        setTop1(1000)
        setOpacity1(0)
        setTopStatus(1)
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    const onChanges = (e) => {
        if (!tokenValue) {
            message.error("Please select Token");
            return false
        }

        if (addressValue.length > 0) {
            let list = addressValue.trim().split(/\s+/);
            let h = check(list)
            setNewlist(h)
            if (h.length == 0) {
                let newList = []
                let sum = 0;
                list.map((i, index) => {
                    sum += Number(i.match(/,(\S*)/)[1])
                    newList.push({ employee: i.match(/(\S*),/)[1], amount: Number(i.match(/,(\S*)/)[1]) })
                })
                setAmount(sum)
                setAddressList(newList)
                setWidth('66.6')
                setTop(-1000)
                setOpacity(0)
                setTop1(10)
                setOpacity1(1)
                setTopStatus(2)
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                getSubsymbolConf().then(res => {
                    res.data.map(i => {
                        if (i.sub_symbol == tokenValue) {
                            setRate(i.payroll_transfer_fee_rate)
                        }
                    })
                })
            } else {
                console.log('error')
            }
        } else {
            message.error("Please enter the receiving address and quantity");
        }

    };

    const deleteList = () => {
        let list = addressValue.trim().split(/\s+/);

        newlist.map(i => {
            list = list.filter(item => !item.includes(i.value))
        })
        var arr = list.join('\n');
        setAddressValue(arr)
        let h = check(list)
        setNewlist(h)

    }

    const check = (data) => {
        let resule = []
        let RegEmail = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        // let reg = '^[+]{0,1}([1-9][0-9]{0,})$|^[+]{0,1}([1-9][0-9]{0,}\.\d+)$|^[+]{0,1}([0]\.(?!0+$)\d{1,})$'
        data.map((i, index) => {

            let list = { id: index, test0: true, test1: true, test2: true, value: i }

            if (i.includes(',') && RegEmail.test(i.match(/(\S*),/)[1])) {

            } else {
                if (i.includes(',') && i.match(/(\S*),/)[1].length == 10 || i.includes(',') && i.match(/(\S*),/)[1].length == 9) {
                    list.test1 = true
                } else {
                    list.test1 = false
                }
            }
            if (!i.includes(',')) {
                list.test0 = false
            }

            if (i.includes(',') && Number(i.match(/,(\S*)/)[1]).toString() == 'NaN') {
                list.test2 = false
            }
            if (i.includes(',') && Number(i.match(/,(\S*)/)[1]) <= 0) {
                list.test2 = false
            }
            if (!list.test0 || !list.test1 || !list.test2) {
                resule.push(list)
            }

        })
        return resule
    }

    const onChange = React.useCallback((value, viewUpdate) => {
        setAddressValue(value)
    }, []);

    const HandleImportFile = (info) => {
        let files = info.file;
        let name = files.name
        let suffix = name.substr(name.lastIndexOf("."));
        let reader = new FileReader();
        let newString = []
        reader.onload = (event) => {
            try {
                if (".xls" != suffix && ".xlsx" != suffix) {
                    message.error("Select a file in Excel format to import!");
                    return false;
                }
                let { result } = event.target;
                let workbook = XLSX.read(result, { type: 'binary' });
                let data = [];
                for (let sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    }
                }
                data.map(i => {
                    newString.push(i.Address + ',' + i.Amount)
                })
                var c = newString.join('\n');
                setAddressValue(c)
                setInputType(1)
            } catch (e) {
                message.error('The file type is incorrect!');
            }
        }
        reader.readAsBinaryString(files);
    }

    const back2 = () => {
        setResult(false)
        setWidth('33.3')
        setTop(10)
        setOpacity(1)
        setTokenValue(tokenValue)
        setRemark('')
        setAddressValue('')
        setTopStatus(1)
        setCompleteStatus(true)
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        getMoney(tokenValue)
    }


    /**
     * +
     * @param {*} arg1 
     * @param {*} arg2 
     * @returns 
     */
    function accAdd(arg1, arg2) {
        return changeNum(arg1, arg2)
    }

    /**
     * -
     * @param {*} arg1 
     * @param {*} arg2 
     * @returns 
     */
    function accSub(arg1, arg2) {
        return changeNum(arg1, arg2, false)
    }

    /**
     * *
     * @param {*} arg1 
     * @param {*} arg2 
     * @returns 
     */
    function accMul(arg1, arg2) {
        let m = 0;
        m = accAdd(m, getDecimalLength(arg1))
        m = accAdd(m, getDecimalLength(arg2))
        return getNum(arg1) * getNum(arg2) / Math.pow(10, m)
    }

    /**
     * /
     * @param {*} arg1 
     * @param {*} arg2 
     * @returns 
     */
    function accDiv(arg1, arg2) {
        let t1, t2;
        t1 = getDecimalLength(arg1)
        t2 = getDecimalLength(arg2)
        if (t1 - t2 > 0) {
            return (getNum(arg1) / getNum(arg2)) / Math.pow(10, t1 - t2)
        } else {
            return (getNum(arg1) / getNum(arg2)) * Math.pow(10, t2 - t1)
        }
    }

    function changeNum(arg1 = '', arg2 = '', isAdd = true) {
        function changeInteger(arg, r, maxR) {
            if (r != maxR) {
                let addZero = ''
                for (let i = 0; i < maxR - r; i++) {
                    addZero += '0'
                }
                arg = Number(arg.toString().replace('.', '') + addZero)
            } else {
                arg = getNum(arg)
            }
            return arg
        }
        let r1, r2, maxR, m;
        r1 = getDecimalLength(arg1)
        r2 = getDecimalLength(arg2)
        maxR = Math.max(r1, r2)
        arg1 = changeInteger(arg1, r1, maxR)
        arg2 = changeInteger(arg2, r2, maxR)
        m = Math.pow(10, maxR)
        if (isAdd) {
            return (arg1 + arg2) / m
        } else {
            return (arg1 - arg2) / m
        }
    }

    function getDecimalLength(arg = '') {
        try {
            return arg.toString().split(".")[1].length
        } catch (e) {
            return 0
        }
    }

    function getNum(arg = '') {
        return Number(arg.toString().replace(".", ""))
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [moadlInput1, setMoadlInput1] = useState('');
    const [moadlInput2, setMoadlInput2] = useState('');
    const [modalTime, setModalTime] = useState('send');
    const [modalTimeStatus, setModalTimeStatus] = useState(true);

    const [userInfo, setUserInfo] = useState(null);
    useEffect(() => {
        // if (localStorage.getItem('token')) {
            getUserInfo().then(res => {
                setUserInfo(res.data)
            })
        // }

    }, [localStorage.getItem('token')])

    const showModal = () => {
        setIsModalOpen(true);
        setMoadlInput1('')
        setMoadlInput2('')
    };
    const handleOk = () => {
        setIsModalOpen(false);
        setMoadlInput1('')
        setMoadlInput2('')
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setMoadlInput1('')
        setMoadlInput2('')
    };

    let intervalHandle = useRef();

    const modalSendMethods = async () => {
        if (modalTimeStatus) {
            let data = { email: userInfo.user_email }
            await sendEmail(data)
            setModalTimeStatus(false)
            var timer_num = 60;
            intervalHandle.current = setInterval(() => {
                timer_num--;
                setModalTime(timer_num + 's')
                if (timer_num == 0) {
                    setModalTime('send')
                    setModalTimeStatus(true)
                    clearInterval(intervalHandle.current);
                }
            }, 1000);
        }
    }



    return <div className="App mx-auto bg-[#000000]">
        {/* h-screen  */}
        <main className='overflow-y-hidden'>
            {/* border-b border-[#161654] border-solid sticky */}
            <div className="fixed top-0 bg-[rgba(0,0,0,0.43)] w-[100%]" style={{ 'zIndex': '51' }}>
                <Header />
            </div>
            {
                topStatus == '1' ? <div className='h-[565px]' style={{ background: `url(${payHead})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cotain', backgroundPosition: 'center' }}>
                    <article className='mx-auto w-[1200px] pt-[127px]' style={{ 'zIndex': '23' }}>
                        <div className='text-[58px] text-[#ffffff] mb-[30px]'>Welcome to</div>
                        <img src={ChiChaPay} alt="ChiChaPay" />
                        <div className='text-[18px] text-[#ffffff] mb-[10px] mt-[40px]'>Support Network</div>
                        <div className='text-[16px] text-[#00DDBF]'>
                            <span className='mr-[70px]'>Ethereum Mainnet</span>
                            <span className='mr-[70px]'>TRON</span>
                            <span className='mr-[70px]'>BNBSmart Chain</span>
                            <span className='mr-[70px]'>OKEx Chain</span>
                        </div>
                    </article>
                </div> : <div className='h-[228px]' style={{ background: `url(${payHeads})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cotain', backgroundPosition: 'center' }}>
                    <article className='mx-auto w-[1200px] pt-[127px]' style={{ 'zIndex': '23' }}>
                        <div className='text-[32px] text-[#ffffff] mb-[30px]'>{topStatus == '2' ? 'Authorization' : 'Sending'}</div>
                    </article>
                </div>
            }


            <article className='mx-auto w-[1200px]'>
                {/* <div className="w-[1200px] h-[10px] bg-[#aec2e3] overflow-hidden">
                    <div className={"relative processAnimation"} style={{ "width": `${width}%` }}>
                        <div className="w-[100%] h-[10px] bg-[#5A47EE]"></div>
                        <div className="absolute -right-[10px] top-0" style={{ border: '5px solid', borderColor: 'transparent transparent transparent #5A47EE' }}></div>
                    </div>
                </div> */}
                <div className="nextBox">
                    <div className='test'>
                        {
                            // style={{ "top": `${top}px`, 'opacity': `${opacity}` }}
                            topStatus == '1' && <div className='next1'>
                                <div className='mb-[20px]'>
                                    <div className='flex justify-between mb-[10px]'>
                                        <div className="text-[#ffffff] text-[16px] font-medium">Token</div>
                                        <div className="text-[#ffffff] text-[16px] font-medium">Balance：{totalBalance}</div>
                                    </div>
                                    <div className='h-[46px] relative mb-[30px]'>
                                        <Input
                                            value={tokenValue}
                                            // <DownOutlined className='pr-[10px] text-[#fff]' />
                                            prefix={<img src={down} className='w-[20px] mr-[10px]' />}
                                            className='h-[46px]'
                                            placeholder=""
                                            autoComplete="off"
                                            onFocus={() => { setTokenListStatus(true) }}
                                        />
                                        {
                                            tokenListStatus && <div className='tokenList absolute left-0 top-[46px] z-50'>
                                                {
                                                    tokenLists.map((i, index) => {
                                                        return <div key={index} className='tokenListItem' onClick={() => { getMoney(i); setTokenValue(i); setTokenListStatus(false) }}>{i}</div>
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='flex justify-between mb-[30px]'>
                                    <div className='h-[50px] border-solid border-[#999999] border-[1px] rounded-[25px] overflow-hidden flex justify-start'>
                                        <div className='px-[20px] text-[18px] h-[48px] text-center leading-[48px] cursor-pointer' style={{ 'background': 'linear-gradient(90deg, #633EEC 0%, #0DCDC6 100%)' }}>ChiChaPay Address</div>
                                        <div className='px-[20px] text-[18px] h-[48px] text-center leading-[48px] text-[#ffffff] cursor-not-allowed'>External Wallet Address</div>
                                    </div>
                                    <div className='h-[50px] flex justify-start'>
                                        <div className={`leading-[50px] text-[18px] ${inputType == '0' ? 'text-[#00DDBF]' : 'text-[#ffffff]'} cursor-pointer`} onClick={() => { setInputType(0) }}>Excel Import</div>
                                        <div className='h-[100%] w-[1px] bg-[#979797] mx-[20px]'></div>
                                        <div className={`leading-[50px] text-[18px] ${inputType == '1' ? 'text-[#00DDBF]' : 'text-[#ffffff]'}  cursor-pointer`} onClick={() => { setInputType(1) }}>Manual Import</div>
                                    </div>
                                </div>
                                <div className='h-[400px]'>
                                    {
                                        inputType == '0' ? <Dragger
                                            height='400px'
                                            accept=".xls , .xlsx"
                                            maxCount={1}
                                            showUploadList={false}
                                            customRequest={HandleImportFile}>
                                            <div className='mx-auto'><img className='mx-auto' src={upload} alt='upload' /></div>
                                            <div className='text-[14px] text-[#716F6F] pt-[20px]'>Drag the file here or click to upload</div>
                                        </Dragger> : <CodeMirror
                                            value={addressValue}
                                            height="400px"
                                            theme={myTheme}
                                            extensions={extensions}
                                            onChange={onChange}
                                        />
                                    }
                                </div>
                                <div className='flex justify-between mt-[10px] mb-[30px]'>
                                    <div className="text-[#FFFFFF] text-[16px]">{inputType == '0' ? 'Supported file types：Excel' : 'Each line should include address and quantity, comma separated'}</div>
                                    <a href='https://mmpaypublic.s3.ap-east-1.amazonaws.com/files/bulksender_example_erc20.xlsx' className="text-[#FFFFFF] text-[16px] cursor-pointer">Example file</a>
                                </div>
                                {
                                    newlist.length > 0 && <>
                                        <div className='flex justify-between mt-[10px] mb-[10px]'>
                                            <div className="text-[#FFFFFF] text-[18px]">The following addresses or amounts cannot be sent</div>
                                            <div className="text-[#F8395D] text-[16px] cursor-pointer" onClick={() => { deleteList() }}>Delete them</div>
                                        </div>
                                        <div className="errorTable px-5 py-2 h-[150px] mb-[30px] border-[#F8395D] overflow-y-auto">
                                            <div className="text-[#F8395D] font-light mb">
                                                {
                                                    newlist.map((i, index) => {
                                                        return <div key={index}>
                                                            line {i.id + 1}： {!i.test0 && 'Format error'} {!i.test1 && 'Invalid wallet address'} {!i.test2 && 'Invalid send quantity'}
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                                <div>
                                    <Input
                                        value={remark} onChange={(e) => { setRemark(e.target.value) }}
                                        prefix={<span className='text-[#ffffff]'>Notes：</span>}
                                        className='h-[46px]'
                                        placeholder=""
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="pt-[30px]">
                                    <div className="nextBtn mx-auto rounded-[25px] flex justify-center items-center cursor-pointer" onClick={onChanges}>Next step</div>
                                </div>
                            </div>
                        }

                        {
                            topStatus == '2' && <div className="next2" style={{ "top": `${top1}px`, 'opacity': `${opacity1}` }}>
                                <div className='flex justify-between items-center mt-[30px] mb-[16px]'>
                                    <div className="text-[#FFFFFF] text-[18px]">Summary</div>
                                    {/* <div className="text-[#50BFFF] text-[16px]">充值</div> */}
                                </div>
                                <div className="next2Table px-[24px] py-[30px]">
                                    <div className='flex justify-start mb-[15px]'>
                                        <div className='w-[250px] text-[#FFFFFF] text-[18px]'>{amount}</div>
                                        <div className='w-[250px] text-[#FFFFFF] text-[18px]'>{addressList.length}</div>
                                    </div>
                                    <div className='flex justify-start mb-[30px]'>
                                        <div className='w-[250px] text-[#D4D4D4] text-[16px]'>Total number of sent</div>
                                        <div className='w-[250px] text-[#D4D4D4] text-[16px]'>Total number of addresses</div>
                                    </div>
                                    <div className='flex justify-start mb-[15px]'>
                                        <div className='w-[250px] text-[#FFFFFF] text-[18px]'>{totalBalance} {tokenValue}</div>
                                        <div className='w-[250px] text-[#FFFFFF] text-[18px]'>{accMul(amount, rate)} {tokenValue}</div>
                                    </div>
                                    <div className='flex justify-start'>
                                        <div className='w-[250px] text-[#D4D4D4] text-[16px]'>Token Balance</div>
                                        <div className='w-[250px] text-[#D4D4D4] text-[16px]'>Handling fee</div>
                                    </div>
                                </div>
                                <div className="text-[#ffffff] text-[18px] mb-[16px] mt-[46px]">Address List</div>
                                <div className="next2Table py-[24px] px-[30px] h-[350px] overflow-y-auto">
                                    {
                                        addressList.map((i, index) => {
                                            return <>
                                                <div key={index} className="text-[16px]">{index + 1}<span className='ml-[18px]'>{i.employee},{i.amount}</span></div>
                                            </>
                                        })
                                    }
                                </div>
                                <div className="text-[#ffffff] text-[16px] pt-[16px]">Notes：{remark && <span>{remark}</span>}</div>

                                <div className="pt-[30px] flex justify-center">
                                    <div className="backBtn rounded-[25px] flex justify-center items-center cursor-pointer" onClick={backSubmit}>Return</div>
                                    <div className="nextBtn rounded-[25px] flex justify-center items-center cursor-pointer" onClick={showModal}>Next step</div>
                                </div>
                            </div>
                        }

                        {
                            result && <div className='next3'>
                                <div className="text-[#ffffff] text-[18px] pt-[30px] mb-[16px]">All transactions have been sent, please wait for the transaction to be confirmed</div>
                                {
                                    resultList.length > 0 && <div className="next2Table px-[30px] py-[24px] min-h-[160px] max-h-[380px] overflow-y-auto">
                                        {
                                            resultList.map((i, index) => {
                                                return <>
                                                    <div key={index} className='text-[16px] h-[20px] mb-[16px] flex justify-between items-center'>
                                                        <span>{index + 1}：{i.employee},{i.amount}</span>
                                                        <img src={i.state == '200' ? listSuccess : listError} />
                                                    </div>
                                                </>
                                            })
                                        }
                                    </div>
                                }
                                {completeStatus && <div className="next2Table py-[27px] px-[24px] text-[16px] mt-[30px]">Congratulations, the batch transaction was successful!</div>}
                                <div className="text-[#ffffff] text-[16px] pt-[16px]">Notes：{remark}</div>
                                <div className="pt-[40px] flex justify-center">
                                    <div className="backBtn rounded-[25px] flex justify-center items-center cursor-pointer" onClick={back2}>Return</div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </article>
        </main>
        <Modal title="安全验证" footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
            closeIcon={<img src={close} />} maskClosable={false} >
            <Input
                value={moadlInput1}
                prefix={<div className='text-[#ffffff] text=[14px] mr-[10px]'>验证码</div>}
                suffix={<><div className='send text-[#ffffff] text=[14px] w-[60px] cursor-pointer' onClick={() => { modalSendMethods() }}>{modalTime}</div></>}
                className='h-[46px] my-[20px]'
                placeholder=""
                autoComplete="off"
                onChange={(e) => { setMoadlInput1(e.target.value) }}
            />
            <Input.Password
                value={moadlInput2}
                prefix={<div className='text-[#ffffff] text=[14px] mr-[10px]'>密码</div>}
                className='h-[46px]'
                placeholder=""
                autoComplete="off"
                onChange={(e) => { setMoadlInput2(e.target.value) }}
                iconRender={(visible) => (visible ? <EyeTwoTone twoToneColor="#444444" /> : <EyeInvisibleTwoTone twoToneColor="#444444" />)}
            />
            <div className='modalBtn cursor-pointer' onClick={() => { grantSubmit() }}>确认</div>
        </Modal>
    </div>
}

export default Bulksender