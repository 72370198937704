import React, { useImperativeHandle } from 'react';
import { notification } from 'antd'


const ToastNotification = (props) => {

    const [api, contextHolders] = notification.useNotification()

    useImperativeHandle(props.onRef, () => {
        return {
            func: func,
        };
    });
    function func() {
        api.success({
            message: 'Success',
            // description: 'This is the .',
        });
    }

    return (
        <>
            {contextHolders}
        </>
    )
}

export default ToastNotification