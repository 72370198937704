import { Space } from 'antd';
import Icon from '../../icon';
import { getMyDate, rateRules } from '../../utils'



export const headerList = (navigate,showModal) => {
    return {
        buy: [
            {
                title: 'Order Number',
                dataIndex: 'trade_no',
                key: 'trade_no',
            },
            {
                title: 'Time',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (text) => <a>{getMyDate(text)}</a>,
            },
            {
                title: 'Type',
                dataIndex: 'trade_type',
                key: 'trade_type',
                render: (text) => <a>{text == 1 ? 'Buy' : 'Sell'}</a>,
            },
            {
                title: 'Get',
                dataIndex: 'amount',
                key: 'amount',
                render: (text, record) => <a>{Number(text).toFixed(rateRules(record.sub_symbol))} {record.sub_symbol}</a>,
            },
            {
                title: 'Handling Fee',
                dataIndex: 'fee',
                key: 'fee',
                render: (text, record) => <a>{Number(text).toFixed(rateRules(record.currency))} {record.currency}</a>,
            },
            {
                title: 'Total Amount',
                dataIndex: 'discount',
                key: 'discount',
                render: (text, record) => <a>{Number(Number(record.discount) + Number(record.fee)).toFixed(rateRules(record.currency))} {record.currency}</a>,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (text) => <a>{text == 0 ? <Icon type='icon-a-bianzu5' className='text-[20px]' /> : text == 1 ? <Icon type='icon-a-Group9' className='text-[20px]' /> : <Icon type='icon-a-Group7' className='text-[20px]' />}</a>,
            },
            {
                title: 'Details',
                key: 'action',
                render: (_, record) => (
                    <Space size="middle">
                        <div className='border-solid border-[#666C6D] border-[1px] py-[3px] px-[26px] rounded-[15px] cursor-pointer hover:bg-[#5A47EE] hover:border-[#5A47EE]' onClick={() => {
                            navigate(`/businessResult?transactionId=${record.trade_no}`, { state: { type: 1 } })
                        }}>See</div>
                    </Space>
                ),
            }
        ],
        buyH5: [
            {
                title: 'Time',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (text) => <div className='text-[12px]'>{getMyDate(text)}</div>,
            },
            {
                title: 'Type',
                dataIndex: 'trade_type',
                key: 'trade_type',
                render: (text) => <div className='text-[12px]'>{text == 1 ? 'Buy' : 'Sell'}</div>,
            },
            {
                title: 'Total Amount',
                dataIndex: 'discount',
                key: 'discount',
                render: (text, record) => <div className='text-[12px]'>{Number(Number(record.discount) + Number(record.fee)).toFixed(rateRules(record.currency))} {record.currency}</div>,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (text) => <a>{text == 0 ? <Icon type='icon-a-bianzu5' className='text-[20px]' /> : text == 1 ? <Icon type='icon-a-Group9' className='text-[20px]' /> : <Icon type='icon-a-Group7' className='text-[20px]' />}</a>,
            },
        ],
        sell: [
            {
                title: 'Order Number',
                dataIndex: 'trade_no',
                key: 'trade_no',
            },
            {
                title: 'Time',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (text) => <a>{getMyDate(text)}</a>,
            },
            {
                title: 'Type',
                dataIndex: 'trade_type',
                key: 'trade_type',
                render: (text) => <a>{text == 1 ? 'Buy' : 'Sell'}</a>,
            },
            {
                title: 'Get',
                dataIndex: 'amount',
                key: 'amount',
                render: (text, record) => <a>{Number(record.discount).toFixed(rateRules(record.currency))} {record.currency}</a>,
            },
            {
                title: 'Handling Fee',
                dataIndex: 'fee',
                key: 'fee',
                render: (text, record) => <a>{Number(text).toFixed(rateRules(record.sub_symbol))} {record.sub_symbol}</a>,
            },
            {
                title: 'Total Amount',
                dataIndex: 'discount',
                key: 'discount',
                render: (text, record) => <a>{Number(record.amount).toFixed(rateRules(record.sub_symbol))} {record.sub_symbol}</a>,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (text) => <a>{text == 0 ? <Icon type='icon-a-bianzu5' className='text-[20px]' /> : text == 1 ? <Icon type='icon-a-Group9' className='text-[20px]' /> : <Icon type='icon-a-Group7' className='text-[20px]' />}</a>,
            },
            {
                title: 'Details',
                key: 'action',
                render: (_, record) => (
                    <Space size="middle">
                        <div className='border-solid border-[#666C6D] border-[1px] py-[3px] px-[26px] rounded-[15px] cursor-pointer hover:bg-[#5A47EE] hover:border-[#5A47EE]' onClick={() => {
                            navigate(`/businessResult?transactionId=${record.trade_no}`, { state: { type: 2 } })
                        }}>See</div>
                    </Space>
                ),
            }
        ],
        sellH5: [
            {
                title: 'Time',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (text) => <div className='text-[12px]'>{getMyDate(text)}</div>,
            },
            {
                title: 'Type',
                dataIndex: 'trade_type',
                key: 'trade_type',
                render: (text) => <div className='text-[12px]'>{text == 1 ? 'Buy' : 'Sell'}</div>,
            },
            {
                title: 'Total Amount',
                dataIndex: 'discount',
                key: 'discount',
                render: (text, record) => <div className='text-[12px]'>{Number(Number(record.discount) + Number(record.fee)).toFixed(rateRules(record.currency))} {record.currency}</div>,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (text) => <a>{text == 0 ? <Icon type='icon-a-bianzu5' className='text-[20px]' /> : text == 1 ? <Icon type='icon-a-Group9' className='text-[20px]' /> : <Icon type='icon-a-Group7' className='text-[20px]' />}</a>,
            },
        ],
        token: [
            {
                title: 'Time',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (text) => <a>{getMyDate(text)}</a>,
            },
            {
                title: 'Coins Sent',
                dataIndex: 'sub_symbol',
                key: 'sub_symbol',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Total Number Sent',
                dataIndex: 'amount',
                key: 'amount',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Total number of addresses',
                dataIndex: 'cnt',
                key: 'cnt',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Handling Fee',
                dataIndex: 'fee',
                key: 'fee',
            },
            {
                title: 'Details',
                key: 'action',
                render: (_, record) => (
                    <Space size="middle">
                        <div className='border-solid border-[#666C6D] border-[1px] py-[3px] px-[26px] rounded-[15px] cursor-pointer hover:bg-[#5A47EE] hover:border-[#5A47EE]' onClick={() => {
                            // showModal(record, 0)
                            showModal(record, 0)
                        }}>See</div>
                    </Space>
                ),
            },
            {
                title: 'Notes',
                dataIndex: 'remark',
                key: 'remark',
            }
        ],


        CNY: [
            {
                title: 'Number',
                dataIndex: 'id',
                key: 'id',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Bank Account',
                dataIndex: 'account_num',
                key: 'account_num',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Last Name',
                dataIndex: 'last_name',
                key: 'last_name',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'First Name',
                dataIndex: 'first_name',
                key: 'first_name',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Bank Id',
                dataIndex: 'bank_id',
                key: 'bank_id',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                render: (text) => <a>{text == '1' ? 'Success' : text == '3' ? 'Fail' : 'Waiting'}</a>,
            }
        ],
        EUR: [
            {
                title: 'Number',
                dataIndex: 'id',
                key: 'id',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Bank Account',
                dataIndex: 'account_num',
                key: 'account_num',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Last Name',
                dataIndex: 'last_name',
                key: 'last_name',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'First Name',
                dataIndex: 'first_name',
                key: 'first_name',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                render: (text) => <a>{text == '1' ? 'Success' : text == '3' ? 'Fail' : 'Waiting'}</a>,
            }
        ],
        USD: [
            {
                title: 'Number',
                dataIndex: 'id',
                key: 'id',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Bank Account',
                dataIndex: 'account_num',
                key: 'account_num',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Bank Id',
                dataIndex: 'bank_id',
                key: 'bank_id',
                width: 150,
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Last Name',
                dataIndex: 'last_name',
                key: 'last_name',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'First Name',
                dataIndex: 'first_name',
                key: 'first_name',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Country Code',
                dataIndex: 'countryCode',
                key: 'countryCode',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'City Code',
                dataIndex: 'cityCode',
                key: 'cityCode',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                width: 300,
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Postcode',
                dataIndex: 'postcode',
                key: 'postcode',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'BankCode',
                dataIndex: 'bankCode',
                key: 'bankCode',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'BankAccountType',
                dataIndex: 'bankAccountType',
                key: 'bankAccountType',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                render: (text) => <a>{text == '1' ? 'Success' : text == '3' ? 'Fail' : 'Waiting'}</a>,
            }
        ]
    }
}