import partner from '../assets/images/partner.png'
import group1 from '../assets/images/GroupH5 41.png'
import group2 from '../assets/images/GroupH5 36.png'
import group3 from '../assets/images/GroupH5 37.png'
import group4 from '../assets/images/GroupH5 38.png'
import group5 from '../assets/images/GroupH5 39.png'
import group6 from '../assets/images/GroupH5 40.png'
import group7 from '../assets/images/GroupH5 30.png'
import group8 from '../assets/images/GroupH5 31.png'
import group9 from '../assets/images/GroupH5 32.png'
import group10 from '../assets/images/GroupH5 33.png'
import group11 from '../assets/images/GroupH5 34.png'
import group12 from '../assets/images/GroupH5 35.png'

function PartnerH5() {
  return (
    <section className='pt-[70px] px-[25px]' id='openapi'>
      <h2 className='flex justify-center'>
        <img src={partner} alt='partner' className='w-[105px]' />
      </h2>
      <section className='flex justify-center mt-[18px] flex-wrap'>
        <img className='p-2' src={group1} alt='partner' />
        <img className='p-2' src={group2} alt='partner' />
        <img className='p-2' src={group3} alt='partner' />
        <img className='p-2' src={group4} alt='partner' />
        <img className='p-2' src={group5} alt='partner' />
        <img className='p-2' src={group6} alt='partner' />
        <img className='p-2' src={group7} alt='partner' />
        <img className='p-2' src={group8} alt='partner' />
        <img className='p-2' src={group9} alt='partner' />
        <img className='p-2' src={group10} alt='partner' />
        <img className='p-2' src={group11} alt='partner' />
        <img className='p-2' src={group12} alt='partner' />
      </section>
    </section>
  )
}

export default PartnerH5