import { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from "../../component/header";
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Input, Checkbox, Form, message } from 'antd';
import { sendEmail, register } from '../../api/path/login'
import registerPcBg from '../../assets/images/registerPcBg.png'
import passwordIcon1 from '../../assets/images/passwordIcon1.png'
import passwordIcon2 from '../../assets/images/passwordIcon2.png'

function Register() {

    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()

    const [timeValue, setTimeValue] = useState('Send verification code');
    const [timeStatus, setTimeStatus] = useState(true);

    const [referralCode, setReferralCode] = useState('');
    

    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    const onFinish = (values) => {
        let params = {
            'email': values.email,
            'password': values.password,
            'auth_code': values.code,
            'invite_code': referralCode
        }
        register(params).then(res => {
            messageApi.success(res.message)
            setTimeout(function () {
                navigate('/login')
            }, "1500");
        }).catch((err) => {
            messageApi.error(err.message)
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    let intervalHandle = useRef();

    const sendMethods = async () => {
        if (!form.getFieldsValue().email) {
            messageApi.error('Please input your email!')
            return false;
        }
        const regex = new RegExp("^\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$")
        if (!regex.test(form.getFieldsValue().email)) {
            messageApi.error('Please enter the correct email address!')
            return false;
        }
        if (timeStatus) {
            let data = { email: form.getFieldsValue().email }
            await sendEmail(data)
            setTimeStatus(false)
            var timer_num = 60;
            setTimeValue(`Code sent(${timer_num})`)
            intervalHandle.current = setInterval(() => {
                timer_num--;
                setTimeValue(`Code sent(${timer_num})`)
                if (timer_num == 0) {
                    setTimeValue('Resend Code')
                    setTimeStatus(true)
                    clearInterval(intervalHandle.current);
                }
            }, 1000);
        }
    }

    return <div style={{ overflowY: 'auto', background: `url(${registerPcBg})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', backgroundPosition: 'center' }}
        className="App mx-auto">
        {contextHolder}
        <main className='h-screen'>
            <div className="sticky sm:top-0 relative  z-[50]">
                <Header />
            </div>
            <article className='mx-auto sm:pt-[56px] sm:pt-[20px] px-[5px]'>
                <div style={{ 'margin': '0 auto' }} className="max-w-[535px] sm:pb-[87px] rounded-md px-[20px] sm:px-[70px]">
                    <div className="text-[#FFFFFF] sm:text-[30px] text-[20px] font-medium sm:mb-[25px] mb-[20px] text-center">Sign UP</div>
                    <div className=''>
                        <div>
                            <Form
                                autoComplete="off"
                                onFinish={onFinish}
                                form={form}
                                onFinishFailed={onFinishFailed}>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        { required: true, message: 'Please input your email!' },
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                    ]}
                                >
                                    <div>
                                        <div className="text-[#FFFFFF] sm:text-[14px] text-[16px] font-light mb-[8px]">Email</div>
                                        <Input.Password
                                            visibilityToggle={{ visible: true }}
                                            className='sm:h-[60px] h-[43px]'
                                            placeholder="Enter your email address..."
                                            iconRender={(visible) => (visible ? <EyeOutlined style={{ color: '#fff' }} /> : <EyeInvisibleOutlined style={{ color: '#fff' }} />)}
                                        />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                    ]}
                                >
                                    <div>
                                        <div className="text-[#FFFFFF] sm:text-[14px] text-[16px] font-light mb-[8px] flex justify-between">
                                            <span>Password</span>
                                        </div>
                                        <Input.Password
                                            className='sm:h-[60px] h-[43px]'
                                            placeholder="Enter your password..."
                                            autoComplete="new-password"
                                            iconRender={(visible) => (visible ? <img src={passwordIcon2} alt='passwordIcon2' /> : <img src={passwordIcon1} alt='passwordIcon1' />)}
                                        />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name="confirm"
                                    dependencies={['password']}
                                    // hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <div>
                                        <div className="text-[#FFFFFF] sm:text-[14px] text-[16px] font-light mb-[8px] flex justify-between">
                                            <span>Confirm Password</span>
                                        </div>
                                        <Input.Password
                                            className='sm:h-[60px] h-[43px]'
                                            placeholder="Enter your password..."
                                            autoComplete="new-password"
                                            iconRender={(visible) => (visible ? <img src={passwordIcon2} alt='passwordIcon2' /> : <img src={passwordIcon1} alt='passwordIcon1' />)}
                                        />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name="code"
                                    rules={[
                                        { required: true, message: 'Please input your code!' }
                                    ]}
                                >
                                    <div className='mb-[0px]'>
                                        <div className="text-[#FFFFFF] sm:text-[14px] text-[16px] font-light mb-[8px] flex justify-start items-center">
                                            <span>Email Verification Code</span>
                                        </div>
                                        <Input
                                            className='sm:h-[60px] h-[43px]'
                                            placeholder="Enter your code..."
                                            suffix={<div onClick={() => { sendMethods() }} className={timeStatus ? 'text-[#9A8DFD] cursor-pointer sm:text-[14px] text-[12px]' : 'text-[#D9D9D9] cursor-pointer sm:text-[14px] text-[12px]'}>{timeValue}</div>}
                                        />
                                    </div>
                                </Form.Item>
                                <div className='mb-[20px] referralCode'>
                                    <div className="text-[#8F8F8F] sm:text-[14px] text-[16px] font-light mb-[8px] flex justify-start items-center">
                                        <span>Referral code (optional)</span>
                                    </div>
                                    <Input
                                        className='sm:h-[60px] h-[43px]'
                                        placeholder="Enter Referral ID..."
                                        value={referralCode} onChange={(e) => {
                                            setReferralCode(e.target.value)
                                        }}
                                    />
                                </div>
                                <Form.Item
                                    name="agreement"
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                                        },
                                    ]}
                                >
                                    <Checkbox onChange={onChange} className="text-[#8F8F8F] text-[14px]">I have read and agree to ChiChaPay's <a className='text-[#06DCBF] underline cursor-pointer' href='https://api.chicha.io/agreement/userAgreement_English.html'>User Agreement</a> and <a href='https://api..chicha.io/privacy/privacyAgreement_English.html' className='text-[#06DCBF] underline cursor-pointer'>Privacy Policy</a></Checkbox>
                                </Form.Item>
                                <Form.Item>
                                    <div id='create' className='w-[100%]'>
                                        <Button className='sm:text-[20px] text-[14px] w-[100%] sm:h-[60px] h-[43px] rounded-md bg-[#5A47EE] flex justify-center items-center cursor-pointer' type="primary" htmlType="submit">
                                            Registration
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Form>
                            <div className='sm:text-[15px] text-[14px] font-light text-[#E7E7E7] sm:text-left text-center pb-[20px]'>Already have an account? <span className='text-[#FFFFFF] font-bold cursor-pointer' onClick={() => { navigate('/login') }}>Login</span></div>
                        </div>
                    </div>
                </div>
            </article>
        </main>
    </div>
}

export default Register;