import React, { useEffect, useRef, useState } from 'react';
import Header from "../../component/header";
import { Space, Table, Button, Modal } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom'
import './index.css'
import payHeads from '../../assets/images/payHeads.png'

import * as XLSX from 'xlsx';

import { headerList } from './header'

function History() {

    const state = useLocation().state
    const navigate = useNavigate()
    const [data, setData] = useState([])

    useEffect(() => {
        var list = JSON.parse(state.details)
        list.map((i, index) => {
            i.id = index + 1
        })
        setData(list)
    }, [])

    const onChange = (e) => {
        console.log(e)
    }




    const columns = [
        {
            title: 'Number',
            dataIndex: 'id',
            key: 'id',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'UID/email',
            dataIndex: 'employee',
            key: 'employee',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Total Number Sent',
            dataIndex: 'amount',
            key: 'amount',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            render: (text) => <a>{text == '200' ? 'Success' : 'Fail'}</a>,
        }
    ];

    const newColumns = [
        {
            title: 'Number',
            dataIndex: 'id',
            key: 'id',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Bank Account',
            dataIndex: 'account_num',
            key: 'account_num',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'first_name',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Bank Id',
            dataIndex: 'bank_id',
            key: 'bank_id',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            render: (text) => <a>{text == '1' ? 'Success' : text == '3' ? 'Fail' : 'Waiting'}</a>,
        }
    ];

    const exportExcel = (id, name) => {
        var exportFileContent = document.getElementById(id).cloneNode(true);
        var wb = XLSX.utils.table_to_book(exportFileContent, { sheet: "sheet1" });
        XLSX.writeFile(wb, name);
    }

    return <>
        <div className="App mx-auto bg-[#000000] min-h-[100vh]">
            <main className='pb-[50px]'>
                <div className="fixed top-0 bg-[rgba(0,0,0,0.43)] w-[100%]" style={{ 'zIndex': '51' }}>
                    <Header />
                </div>
                <div className='h-[228px]' style={{ background: `url(${payHeads})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cotain', backgroundPosition: 'center' }}>
                    <article className='mx-auto w-[1200px] pt-[110px]' style={{ 'zIndex': '23' }}>
                        <div className=''> </div>
                    </article>
                </div>
                <article className='mx-auto w-[1200px]'>
                    <div className='h-[64px] text-[18px] text-[#ffffff] leading-[75px]'>History orders</div>
                    {
                        state.tableType == '0' ? <Table id="table_report" columns={columns} dataSource={data} onChange={onChange} pagination={{
                            pageSize: 10,
                            showSizeChanger: false
                        }}
                            rowKey={record => record.id}
                        /> : <Table id="table_reports" columns={headerList()[`${state.sub_symbol}`]} dataSource={data} onChange={onChange} pagination={{
                            pageSize: 10,
                            showSizeChanger: false
                        }}
                            scroll={{
                                x: state.sub_symbol == 'USD' ? 2000 : 0,
                            }}
                            rowKey={record => record.id}
                        />
                    }
                    <div className='text-[16px] text-[#ffffff]'>Notes：{state.remark}</div>

                    {/* <div onClick = {() => exportExcel('table_report', 'test.xlsx')}>export</div> */}
                    <div className='pt-[30px] flex justify-center'>
                        <div className="backBtn rounded-[25px] flex justify-center items-center cursor-pointer" onClick={() => { navigate(-1) }}>Return</div>
                    </div>
                </article>
            </main>
        </div>
    </>
}
export default History