import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from "./components/header";
import { EyeInvisibleOutlined, EyeTwoTone, InboxOutlined } from '@ant-design/icons';
import { Form, Input, message, Button } from 'antd';
import testCode from '../../assets/images/testCode.png'
import { fetchLogin } from '../../api/path/login'

function Login() {

    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage()

    const onFinish = (values) => {
        console.log('Success:', values);
        let params = {
            'email': values.email,
            'password': values.password
        }

        fetchLogin(params).then(res => {
            console.log(res)
            const token = res['x-auth-token']
            localStorage.setItem('token', token)
            navigate('/')
        }).catch((err) => {
            messageApi.error(err.message)
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    


    return <div className="App mx-auto bg-[#F4F4F4]">
        {contextHolder}
        <main className='h-screen'>
            <div className="border-b border-[#161654] border-solid sticky top-0 bg-[#010220]">
                <Header />
            </div>
            <article className='mx-auto w-[1200px] pt-[56px]'>
                <div style={{ 'margin': '0 auto' }} className="w-[940px] h-[540px] bg-[#FFFFFF] shadow-xl rounded-md px-[83px] pt-[38px]">
                    <div style={{ 'fontSize': '30px' }} className="text-[#000000] font-medium mb-[40px]">ChiChaPay Login</div>
                    <div className='flex justify-between'>
                        <div>
                            <Form
                                autoComplete="off"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}>
                                <Form.Item
                                    name="email"
                                    className='pb-[30px]'
                                    rules={[
                                        { required: true, message: 'Please input your email!' },
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                    ]}
                                >
                                    <div>
                                        <div className="text-[#000000] font-light mb" style={{ 'fontSize': '20px' }}>Email</div>
                                        <Input
                                            className='w-[376px] h-[46px]'
                                            placeholder="email"
                                            autoComplete="off"
                                        />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    className='pb-[30px]'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                    ]}
                                >
                                    <div>
                                        <div className="w-[376px] text-[#000000] font-light mb-1 flex justify-between" style={{ 'fontSize': '20px' }}>
                                            <span>Password</span>
                                            {/* <span style={{ 'fontSize': '15px' }} className="pt-1 cursor-pointer">Forgot your password?</span> */}
                                        </div>
                                        <Input.Password
                                            className='w-[376px] h-[46px]'
                                            placeholder="password"
                                            autoComplete="new-password"
                                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        />
                                    </div>
                                </Form.Item>
                                <Form.Item>
                                    <Button style={{ 'fontSize': '20px' }} className='w-[376px] h-[46px] rounded-md bg-[#5A47EE] flex justify-center items-center cursor-pointer' type="primary" htmlType="submit">
                                        Login
                                    </Button>
                                </Form.Item>

                            </Form>
                            {/* <div style={{ 'fontSize': '20px' }} className='w-[376px] h-[46px] rounded-md bg-[#5A47EE] flex justify-center items-center cursor-pointer' onClick={login}>
                                Login
                            </div> */}
                            {/* <div style={{ 'fontSize': '15px' }} className='text-[#5A47EE] font-normal pt-[10px] cursor-pointer'>Create a ChiChaPay Account</div> */}
                        </div>
                        {/* <div className='pt-[30px]'>
                            <div className='w-[240px] flex justify-center'>
                                <div className='w-[163px] h-[163px] bg-[#ECE8FD] rounded-md p-[12px] mb-[13px] relative'>
                                    <img className='w-[100%] h-[100%]' src={testCode} />
                                    <div style={{ 'fontSize': '15px' }} className='w-[163px] h-[163px] bg-[rgba(255,255,255,0.91)] rounded-md absolute top-0 left-0 text-[#000000] font-normal flex flex-col justify-center items-center'>
                                        <div className='mb-2'>QR code expired</div>
                                        <div className='w-[102px] py-[4px] bg-[#D9D9D9] rounded flex justify-center items-center cursor-pointer'>Refresh</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ 'fontSize': '20px' }} className="text-[#000000] font-normal text-center mb-1">Log in with QR code</div>
                            <div style={{ 'fontSize': '15px' }} className="w-[240px] text-[#000000] font-light text-center">Scan this code with the
                                <span className='text-[#5A47EE]'> ChiChaPay mobile app</span> to log in instantly.
                            </div>
                        </div> */}
                    </div>
                </div>
            </article>
        </main>
    </div>
}

export default Login;