import { useEffect, useState, useRef } from 'react'
import { Input, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Header from "../../component/header"
import Loading from "../../component/loading"
import Icon from '../../icon';
import PassowrdModal from '../../components/passowrdModal/index'

import { stopBubble, accMul, rateRules } from '../../utils'
import { payget, cryptoBuy, cryptoSell, getSubsymbolConf, bankList, isPayPassword } from '../../api/path/login'

import moonPays from '../../assets/images/moonPays.png'
import businessBg from '../../assets/images/businessProcessBg.png'
import businessBgH5 from '../../assets/images/businessBgH5.png'

import { setUserBackRouterAction } from '@/store/userInfo'


function BusinessProcess() {

    const [messageApi, contextHolder] = message.useMessage()
    const state = useLocation().state

    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const { width } = useSelector((state) => state.global)

    const [info, setInfo] = useState(null)
    const [loadingStatus, setLoadingStatus] = useState(false)

    const [list, setList] = useState([])

    let getInfo = () => {
        setLoadingStatus(true)
        if (state.type == 1) {
            let data = {
                uri: `https://api.moonpay.com/v3/currencies/${state.currency}/buy_quote?apiKey=${state.apiKey}&baseCurrencyCode=${state.baseCurrencyCode}&quoteCurrencyAmount=${state.quoteCurrencyAmount}`
            }
            payget(data).then(res => {
                setInfo(res)
                setLoadingStatus(false)
            })
        } else {
            getSubsymbolConf().then(res => {
                res.data.map((item, index) => {
                    if (item.sub_symbol == state.currency.toUpperCase()) {
                        setFee(item.crypto_sell_fee_rate)
                    }
                })
            })
            bankList().then(res => {
                let arr = []
                res.data.map((i,index) => {
                    if(i.currency == state.baseCurrencyCode.toUpperCase()){
                        arr.push(i)
                    }
                })
                setList(arr)
                // 
                setLoadingStatus(false)
            })
            isPayPassword().then(res => {
                setModalType(res.data.state)
            })
        }
    }

    const [fee, setFee] = useState(0)

    const buyMethods = () => {
        if (state.type == 1) {
            setLoadingStatus(true)
            let data = {
                "platform": 2, //1.壹云汇 2.moonpay
                "amount": Number(state.quoteCurrencyAmount), //购买数量
                "currency": state.baseCurrencyCode.toUpperCase(), //法币
                "sub_symbol": state.currency.toUpperCase() //代币名称 比如USDT
            }
            cryptoBuy(data).then(res => {
                setLoadingStatus(false)
                window.location.href = res.data.uri
            })
        } else {
            if (bankChoose == -1) {
                messageApi.error('Please select a bank card');
                return false
            }
            setIsModalOpen(true)
        }
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(0);
    const [modalValue, setModalValue] = useState('');

    const handleClose = () => {
        setIsModalOpen(false)
        setModalValue('')
    }
    const handleChange = (e) => {
        setModalValue(e.target.value)
    }
    const handleSubmit = () => {
        if (!modalValue) {
            messageApi.error('Please input a password')
            return false
        }
        let data = {
            "amount": Number(state.quoteCurrencyAmount),
            "currency": state.baseCurrencyCode.toUpperCase(),
            "sub_symbol": state.currency.toUpperCase(),
            "bank_card_id": list[bankChoose].id,
            "payment_password": modalValue  //142536
        }
        setLoadingStatus(true)
        cryptoSell(data).then(res => {
            setLoadingStatus(false)
            setModalValue('')
            navigate(`/businessResult?transactionId=${res.data.trade_no}`, {
                state: {
                    type: state.type
                }
            })
        }).catch(err => {
            setLoadingStatus(false)
            messageApi.error(err.message)
        })
    }

    useEffect(() => {
        getInfo()
    }, [])




    const [bankListStatus, setBankListStatus] = useState(false)
    const [bankChoose, setBankChoose] = useState(-1)


    useEffect(() => {
        document.addEventListener('click', () => {
            setBankListStatus(false)
        })
    }, [])

    return <>
        <div className="App mx-auto min-h-[100vh]">
            {loadingStatus && <Loading />}
            {contextHolder}
            <main className='relative'>
                <div className='w-[100%] sm:min-h-[100vh] h-[100%] min-h-[100%] absolute bg-[#000000]'>
                    <img src={width > 768 ? businessBg : businessBgH5} className='w-[100%] min-h-[100%]' />
                </div>
                <div className="border-b border-[#161654] border-solid sticky top-0 bg-[#010220] relative z-[100]">
                    <Header />
                </div>
                <article className='mx-auto sm:pt-[20px] pt-[10px] sm:px-[0px] px-[18px] relative'>
                    <div className='sm:w-[916px] pb-[60px]' style={{ 'margin': '0 auto' }}>
                        <div className='text-[16px] text-[#ffffff] flex items-center'>
                            <Icon type='icon-backColors' className='text-[16px] mr-[8px] cursor-pointer' onClick={() => { navigate('/business') }} />
                            <span className='cursor-pointer' onClick={() => { navigate('/business') }}>Back</span>
                        </div>
                        <div className='sm:text-[30px] text-[22px] text-[#FFFFFF] font-bold sm:pt-[20px] pt-[10px]'>{state.type == 1 ? 'Quick Buy' : 'Quick Sell'}</div>
                        <div className='sm:flex sm:justify-between sm:flex-wrap'>
                            <div className='sm:pt-[25px] pt-[16px] sm:w-[428px]'>
                                <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>{state.type == 1 ? 'Payment Amount' : 'Sale Quantity'}</div>
                                <div className='business'>
                                    <Input className='h-[48px]' disabled
                                        value={state.type == 1 ? state.amount : state.quoteCurrencyAmount}
                                        suffix={
                                            <div className='flex items-center'>
                                                <div className='h-[16px] w-[1px] bg-[#B9B9B9] mr-[12px]'></div>
                                                <img src={state.type == 1 ? state.config.currency[state.input1Type].icon : state.config.sub_symbol[state.input2Type].icon} className="mr-[12px] sm:w-[33px] w-[24px]" />
                                                <div className='mr-[12px] w-[40px]'>{state.type == 1 ? state.config.currency[state.input1Type].symbol : state.config.sub_symbol[state.input2Type].symbol}</div>
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                            <div className='sm:pt-[25px] pt-[16px] sm:w-[428px]'>
                                <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>{state.type == 1 ? 'Purchase Quantity' : 'Amount Received'}</div>
                                <div className='business'>
                                    <Input className='h-[48px]' disabled
                                        value={state.type == 1 ? state.quoteCurrencyAmount : state.amount}
                                        suffix={
                                            <div className='flex items-center'>
                                                <div className='h-[16px] w-[1px] bg-[#B9B9B9] mr-[12px]'></div>
                                                <img src={state.type == 1 ? state.config.sub_symbol[state.input2Type].icon : state.config.currency[state.input1Type].icon} className="mr-[12px] sm:w-[33px] w-[24px]" />
                                                <div className='mr-[12px] w-[40px]'>{state.type == 1 ? state.config.sub_symbol[state.input2Type].symbol : state.config.currency[state.input1Type].symbol}</div>
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                            {width < 768 && <div className='info pt-[25px]'></div>}
                            {
                                state.type == 1 ? <div className='sm:pt-[25px] pt-[0px] sm:w-[428px]'>
                                    <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>1.Pay With</div>
                                    <div className='h-[48px] w-[100%] bg-[#F7F7F7] rounded-[6px] flex items-center px-[15px]'>
                                        <img src={state.config.icons[0]} className="sm:w-[24px] sm:h-[18px] w-[19px] h-[14px] sm:mr-[5px] mr-[7px]" />
                                        <img src={state.config.icons[1]} className="sm:w-[24px] sm:h-[18px] w-[19px] h-[14px] sm:mr-[5px] mr-[7px]" />
                                        <div className='text-[#000000] sm:text-[16px] text-[12px]'>Debit or Credit</div>
                                    </div>
                                </div> : <>
                                    <div className='sm:pt-[25px] pt-[0px] sm:w-[428px]'>
                                        <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>
                                            Pay With <span className='text-[#06DCBF] ml-[20px] cursor-pointer' onClick={() => { dispatch(setUserBackRouterAction('/businessProcess')); navigate(`/bankcard`) }}>+ Add Bank Card</span>
                                        </div>
                                        {
                                            bankChoose != -1 && <div className='sm:h-[84px] h-[62px] w-[100%] bg-[#F7F7F7] rounded-[6px] flex px-[15px] cursor-pointer sm:mb-[16px] mb-[12px] sm:py-[17px] py-[12px] pl-[20px] sm:pr-[35px] pr-[20px] text-[#000000]'>
                                                <div className='flex items-center'><Icon type='icon-a-Group108' className='sm:text-[35px] text-[28px]' /></div>
                                                <div className='flex flex-col ml-[15px] flex-auto justify-between'>
                                                    <span className='sm:text-[16px] text-[12px] font-bold'>{list[bankChoose].number.replace(/(\d{3})\d{4}(\d{4})/, "$1 **** $2")}</span>
                                                    <span className='sm:text-[12px] text-[10px]'>{list[bankChoose].bank_name}</span>
                                                </div>
                                                <div className='flex flex-col items-center justify-between'>
                                                    <span className='sm:text-[16px] text-[12px]'>{list[bankChoose].currency}</span>
                                                    <div className='sm:w-[16px] w-[12px] sm:h-[16px] h-[12px] rounded-[50%] bg-[#5A47EE] mb-[2px]'></div>
                                                </div>
                                            </div>
                                        }
                                        <div className='h-[48px] w-[100%] bg-[#F7F7F7] rounded-[6px] flex items-center px-[15px] cursor-pointer relative'>
                                            <div className='w-[100%] flex items-center justify-between' onClick={(e) => {
                                                setBankListStatus(!bankListStatus); stopBubble(e)
                                            }}>
                                                <span className='text-[#000000] sm:text-[16px] text-[12px]'>Please select a bank card</span>
                                                <Icon type='icon-iconDown' className='text-[#000000]' />
                                            </div>
                                            {
                                                bankListStatus && <>
                                                    {
                                                        list.length > 0 ? <div className='w-[100%] sm:max-h-[275px] max-h-[200px] rounded-[5px] bg-[#FFFFFF] absolute left-0 top-[58px] px-[10px] sm:pt-[20px] pt-[10px] overflow-y-auto' onClick={(e) => { stopBubble(e) }}>
                                                            {
                                                                list.map((item, index) => {
                                                                    return <div key={index}>
                                                                        <div className={`bankItem sm:h-[84px] h-[62px] w-[100%] ${bankChoose == index ? 'bg-[#E7E7E7]' : 'bg-[#F7F7F7]'}  rounded-[6px] flex px-[15px] ${item.currency == state.baseCurrencyCode.toUpperCase() ? 'cursor-pointer' : 'cursor-not-allowed'} sm:mb-[20px] mb-[10px] sm:py-[17px] py-[12px] pl-[20px] sm:pr-[35px] pr-[20px] text-[#000000] border ${bankChoose == index ? 'border-[#5A47EE]' : 'border-[#E7E7E7]'} border-solid`} onClick={(e) => {
                                                                            if (item.currency == state.baseCurrencyCode.toUpperCase()) {
                                                                                setBankChoose(index); stopBubble(e); setBankListStatus(false)
                                                                            }
                                                                        }}>
                                                                            <div className='flex items-center'><Icon type='icon-a-Group108' className='sm:text-[35px] text-[28px]' /></div>
                                                                            <div className='flex flex-col ml-[15px] flex-auto justify-between'>
                                                                                <span className='sm:text-[16px] text-[12px] font-bold'>{item.number.replace(/\D/g, "").length > 12 ? item.number.replace(/\D/g, "").slice(0, 4) + ' **** **** ' + item.number.replace(/\D/g, "").substr(-4) : '**** ' + item.number.replace(/\D/g, "").substr(-4)}</span>
                                                                                <span className='sm:text-[12px] text-[10px]'>{item.bank_name}</span>
                                                                            </div>
                                                                            <div className='flex flex-col items-center justify-between'>
                                                                                <span className='sm:text-[16px] text-[12px]'>{item.currency}</span>
                                                                                <div className={`sm:w-[16px] w-[12px] sm:h-[16px] h-[12px] rounded-[50%] ${bankChoose == index ? 'bg-[#5A47EE]' : 'bg-[#ffffff]'} mb-[2px] border ${bankChoose == index ? 'border-[#5A47EE]' : 'border-[#B9B9B9]'} mb-[2px] border-solid`}></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                        </div> : <div className='w-[100%] sm:h-[275px] h-[200px] rounded-[5px] bg-[#FFFFFF] absolute left-0 top-[58px] px-[10px] sm:pt-[20px] pt-[10px] overflow-y-auto flex items-center text-[16px] text-[#000000] justify-center' onClick={(e) => { stopBubble(e) }}>No cards available</div>
                                                    }
                                                </>
                                            }
                                        </div>
                                        <div className='text-[#F4F5F6] pt-[10px]'>Fee: {(fee * 100).toFixed(2)}%</div>
                                    </div>
                                </>
                            }
                            {state.type == 1 && <div className='sm:pt-[25px] pt-[16px] sm:w-[428px]'>
                                <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>2.Purchase Channels</div>
                                <div className='business'>
                                    <Input className='h-[48px]' disabled
                                        suffix={
                                            <div className='flex items-center'>
                                                <div className='h-[16px] w-[1px] bg-[#B9B9B9] mr-[12px]'></div>
                                                <img src={state.config.sub_symbol[state.input2Type].icon} className="mr-[12px] sm:w-[33px] w-[24px]" />
                                                <div className='mr-[12px] w-[40px]'>{state.config.sub_symbol[state.input2Type].symbol}</div>
                                            </div>
                                        }
                                        prefix={
                                            <img src={moonPays} />
                                        }
                                    />
                                </div>
                            </div>}
                        </div>
                        <div className='info pt-[25px]'></div>
                        {
                            state.type == 1 ? <>
                                <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                    <div className='w-[185px] font-bold'>Get：</div>
                                    <span className='font-normal flex-auto sm:text-left text-right'>{Number(info?.quoteCurrencyAmount).toFixed(rateRules(info?.quoteCurrencyCode.toUpperCase()))} {info?.quoteCurrencyCode.toUpperCase()}</span>
                                </div>
                                <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                    <div className='w-[185px] font-bold'>Price：</div>
                                    <span className='font-normal flex-auto sm:text-left text-right'>{Number(info?.baseCurrencyAmount).toFixed(rateRules(info?.baseCurrencyCode.toUpperCase()))} {info?.baseCurrencyCode.toUpperCase()}</span>
                                </div>
                                <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                    <div className='w-[185px] font-bold'>Fees：</div>
                                    <span className='font-normal flex-auto sm:text-left text-right'>{Number(info?.feeAmount).toFixed(rateRules(info?.baseCurrencyCode.toUpperCase()))} {info?.baseCurrencyCode.toUpperCase()}</span>
                                </div>
                                <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                    <div className='w-[185px] font-bold'>Gas：</div>
                                    <span className='font-normal flex-auto sm:text-left text-right'>{Number(info?.networkFeeAmount).toFixed(rateRules(info?.baseCurrencyCode.toUpperCase()))} {info?.baseCurrencyCode.toUpperCase()}</span>
                                </div>
                                <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                    <div className='w-[185px] font-bold'>Total：</div>
                                    <span className='font-normal flex-auto sm:text-left text-right'>{Number(info?.totalAmount).toFixed(rateRules(info?.baseCurrencyCode.toUpperCase()))} {info?.baseCurrencyCode.toUpperCase()}</span>
                                </div>
                                <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                    <div className='w-[185px] font-bold'>Purchase Channel：</div>
                                    <span className='font-normal flex-auto sm:text-left text-right'>MoonPay</span>
                                </div>
                            </> : <>
                                <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                    <div className='w-[185px] font-bold'>Total：</div>
                                    <span className='font-normal flex-auto sm:text-left text-right'>{Number(state.quoteCurrencyAmount).toFixed(rateRules(state.currency.toUpperCase()))} {state.currency.toUpperCase()}</span>
                                </div>
                                <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                    <div className='w-[185px] font-bold'>Received：</div>
                                    <span className='font-normal flex-auto sm:text-left text-right'>{Number(state.amount).toFixed(rateRules(state.baseCurrencyCode.toUpperCase()))} {state.baseCurrencyCode.toUpperCase()}</span>
                                </div>
                                <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                    <div className='w-[185px] font-bold'>Exchange Rate：</div>
                                    <span className='font-normal flex-auto sm:text-left text-right'>1 {state.currency.toUpperCase()} ≈ {Number(state.rate).toFixed(rateRules(state.baseCurrencyCode.toUpperCase()))} {state.baseCurrencyCode.toUpperCase()}</span>
                                </div>
                                <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                    <div className='w-[185px] font-bold'>Fees：</div>
                                    <span className='font-normal flex-auto sm:text-left text-right'>{Number(accMul(Number(state.quoteCurrencyAmount), Number(fee))).toFixed(rateRules(state.currency.toUpperCase()))} {state.currency.toUpperCase()}</span>
                                </div>
                            </>
                        }

                        <div className='info pt-[25px]'></div>
                        <div className='sm:text-[30px] text-[22px] text-[#FFFFFF] font-bold'>Disclaimer</div>
                        <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] pt-[10px]'>
                            {
                                state.type == 1 ? 'You will leave ChiChaPay and go to' + <a className='text-[#5A47EE] underline' target="_blank" href='https://moonpay.com'>moonpay.com</a> + '; the purchase service is provided by MoonPay, a third party.' :
                                    'The fiat currency exchange service is provided by a third-party and the arrival time is usually 1-3 working days'
                            }
                        </div>
                        <div className='sm:h-[58px] h-[48px] bg-[#5A47EE] cursor-pointer mt-[18px] rounded-[8px] flex items-center justify-center' onClick={() => { buyMethods() }}>
                            <div className='text-[#FFFFFF] sm:text-[20px] text-[16px] font-bold mr-[10px]'>{state.type == 1 ? 'Buy Now' : 'Instant Sell'}</div>
                            <Icon type='icon-Vector' className='text-[#ffffff] sm:text-[20px] text-[14px]' />
                        </div>
                    </div>
                </article>
            </main>
            <PassowrdModal type={modalType} visible={isModalOpen} handleClose={handleClose} value={modalValue} handleChange={handleChange} handleSubmit={handleSubmit} />
        </div>
    </>
}

export default BusinessProcess;