import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'

import { load, save } from 'redux-localstorage-simple'



import user from './userInfo'
import global from './global'

const store = configureStore({
    reducer: {
        user,
        global
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            thunk: true,
            serializableCheck: false,
        }).concat(save({ states: ['global'] }))
    },
    preloadedState: load({
        states: ['global'],
        disableWarnings: process.env.NODE_ENV === 'production',
    }),
});

setupListeners(store.dispatch)

export default store;