import { useEffect, useState } from 'react'
import Header from "../../component/header";
import { platemini, coinInfo, symbolInfo, addOrder, getBalances, orderCurrent, orderHistory } from '../../api/path/login';
import { SearchOutlined, HeartOutlined } from '@ant-design/icons';
import { Input, Tooltip, Slider, Button, message } from 'antd';
import { TVChartContainer } from './components/index';



import axios from 'axios'

function Exchange() {

    const [messageApi, contextHolder] = message.useMessage()

    var moment = require('moment');

    const marks = {
        0: '0',
        25: '25',
        50: '50',
        75: '75',
        100: '100',
    };


    const [currentCoin, setCurrentCoin] = useState({
        base: "USDT",
        baseCoinScale: 2,
        coin: "BTC",
        coinScale: 8,
        symbol: "BTC/USDT"
    });

    const [plate, setPlate] = useState({
        askRows: [],
        bidRows: [],
        askTotle: '',
        bidTotle: '',
        maxPostion: 10,
        columns: []
    });

    const [coins, setCoins] = useState({
        _map: [],
        USDT: [],
        BTC: [],
        ETH: [],
        USDT2: [],
        BTC2: [],
        ETH2: [],
        favor: []
    })

    const [baseCoinScale, setBaseCoinScale] = useState('6')
    const [coinScale, setCoinScale] = useState('6')
    const [searchKey, setSearchKey] = useState('')
    const [dataIndex, setDataIndex] = useState([])

    const [trade, setTrade] = useState([])

    const [balance1, setBalance1] = useState(0)
    const [balance2, setBalance2] = useState(0)

    const [exchangeType, setExchangeType] = useState(0)
    const [tableType, setTableType] = useState(0)

    const [buyPrice, setBuyPrice] = useState(0)
    const [buyAmount, setBuyAmount] = useState(0)
    const [sellPrice, setSellPrice] = useState(0)
    const [sellAmount, setSellAmount] = useState(0)

    const [table, setTable] = useState([])
    const [historyTable, setHistoryTable] = useState([])


    const getPlate = (str = '') => {

        var newPlate = Object.assign({}, plate)

        axios.post(`http://13.215.173.79/market/exchange-plate-mini?symbol=${currentCoin.symbol}`).then(function (res) {
            console.log(res.data)
            newPlate.askRows = []
            newPlate.bidRows = []
            let resp = res.data
            if (resp.ask && resp.ask.items) {
                for (var i = 0; i < resp.ask.items.length; i++) {
                    if (i == 0) {
                        resp.ask.items[i].totalAmount = resp.ask.items[i].amount
                    } else {
                        resp.ask.items[i].totalAmount = resp.ask.items[i - 1].totalAmount + resp.ask.items[i].amount
                    }
                }
                if (resp.ask.items.length >= newPlate.maxPostion) {
                    for (var i = newPlate.maxPostion; i > 0; i--) {
                        var ask = resp.ask.items[i - 1]
                        ask.direction = 'SELL'
                        ask.position = i
                        newPlate.askRows.push(ask)
                    }
                    const rows = newPlate.askRows,
                        len = rows.length,
                        totle = rows[0].totalAmount
                    newPlate.askTotle = totle
                } else {
                    for (var i = newPlate.maxPostion; i > resp.ask.items.length; i--) {
                        var ask = { price: 0, amount: 0 }
                        ask.direction = 'SELL'
                        ask.position = i
                        ask.totalAmount = ask.amount
                        newPlate.askRows.push(ask)
                    }
                    for (var i = resp.ask.items.length; i > 0; i--) {
                        var ask = resp.ask.items[i - 1]
                        ask.direction = 'SELL'
                        ask.position = i
                        newPlate.askRows.push(ask)
                    }
                    var askItemIndex = resp.ask.items.length - 1 > 0 ? resp.ask.items.length - 1 : 0
                    const rows = newPlate.askRows,
                        len = rows.length,
                        totle = rows[askItemIndex].totalAmount
                    newPlate.askTotle = totle
                }
                console.log(newPlate)
            }
            if (resp.bid && resp.bid.items) {
                for (var i = 0; i < resp.bid.items.length; i++) {
                    if (i == 0) resp.bid.items[i].totalAmount = resp.bid.items[i].amount
                    else resp.bid.items[i].totalAmount = resp.bid.items[i - 1].totalAmount + resp.bid.items[i].amount
                }
                for (var i = 0; i < resp.bid.items.length; i++) {
                    var bid = resp.bid.items[i]
                    bid.direction = 'BUY'
                    bid.position = i + 1
                    newPlate.bidRows.push(bid)
                    if (i == newPlate.maxPostion - 1) break
                }
                if (resp.bid.items.length < newPlate.maxPostion) {
                    for (var i = resp.bid.items.length; i < newPlate.maxPostion; i++) {
                        var bid = { price: 0, amount: 0 }
                        bid.direction = 'BUY'
                        bid.position = i + 1
                        bid.totalAmount = 0
                        newPlate.bidRows.push(bid)
                    }
                    var bidItemIndex = resp.bid.items.length - 1 > 0 ? resp.bid.items.length - 1 : 0
                    const rows = newPlate.bidRows,
                        len = rows.length,
                        totle = rows[bidItemIndex].totalAmount
                    newPlate.bidTotle = totle
                } else {
                    const rows = newPlate.bidRows,
                        len = rows.length,
                        totle = rows[len - 1].totalAmount
                    newPlate.bidTotle = totle
                }
            }
            if (str != '') {
                this.selectedPlate = str
            }
            setPlate(newPlate)
        })

        // let formData = new FormData();
        // formData.append('symbol',currentCoin.symbol);
        // platemini(formData).then(res => {
        //     console.log(res)
        // })

    }

    const getCoinInfo = () => {
        // let formData = new FormData();
        // formData.append('unit',currentCoin.coin);
        // coinInfo(formData).then(res => {
        //     console.log(res)
        // })
        axios.post(`http://13.215.173.79/market/coin-info?unit=${currentCoin.coin}`).then(function (res) {
            console.log(res.data)
        })
    }

    const getSymbolScale = () => {

        axios.post(`http://13.215.173.79/market/symbol-info?symbol=${currentCoin.symbol}`).then(function (res) {
            var resp = res.data
            setBaseCoinScale(resp.baseCoinScale)
            setCoinScale(resp.coinScale)
        })
    }

    const getSymbol = () => {
        axios.post(`http://13.215.173.79/market/symbol-thumb`).then(function (res) {
            console.log(res.data)
            var resp = res.data
            var newCoins = Object.assign({}, coins)
            for (var i = 0; i < resp.length; i++) {
                var coin = resp[i]
                coin.base = resp[i].symbol.split('/')[1]
                newCoins[coin.base] = []
                newCoins[coin.base + '2'] = []
                newCoins._map = []
                newCoins.favor = []
            }

            for (var i = 0; i < resp.length; i++) {
                var coin = resp[i]
                // coin.price = resp[i].close = resp[i].close.toFixed(this.baseCoinScale)
                coin.price = resp[i].close
                coin.rose =
                    resp[i].chg > 0 ? '+' + (resp[i].chg * 100).toFixed(2) + '%' : (resp[i].chg * 100).toFixed(2) + '%'
                coin.coin = resp[i].symbol.split('/')[0]
                coin.base = resp[i].symbol.split('/')[1]
                coin.href = (coin.coin + '_' + coin.base).toLowerCase()
                coin.isFavor = false
                newCoins._map[coin.symbol] = coin
                if (coin.zone == 0) {
                    newCoins[coin.base].push(coin)
                } else {
                    newCoins[coin.base + '2'].push(coin)
                }
                if (coin.symbol == currentCoin.symbol) {
                    // this.currentCoin = coin
                    setCurrentCoin(coin)
                    // this.form.buy.limitPrice = this.form.sell.limitPrice = coin.price
                }
            }
            setCoins(newCoins)

            let arr = newCoins.USDT.filter(item => item['coin'].indexOf(searchKey) == 0)
            setDataIndex(arr)
        })
    }

    // 

    const getTrade = () => {
        axios.post(`http://13.215.173.79/market/latest-trade?symbol=${currentCoin.symbol}&size=20`).then(function (res) {
            var resp = res.data
            console.log(resp)
            let arr = []
            for (var i = 0; i < resp.length; i++) {
                arr.push(resp[i])
            }
            setTrade(arr)
        })
    }

    const getMoney = async () => {
        let res1 = await getBalances('USDT')
        setBalance1(res1.data.user_balances[0].balance)
        let res2 = await getBalances('BTC')
        setBalance2(res2.data.user_balances[0].balance)
    }

    const buyWithLimitPrice = async () => {
        var params = {}
        params['symbol'] = currentCoin.symbol
        params['price'] = Number(buyPrice)
        params['amount'] = Number(buyAmount)
        params['direction'] = 0  // 'BUY' 0  'SELL' 1
        params['type'] = exchangeType // 0 市价 1 限价
        params['useDiscount'] = '0' //是否试用手续费抵扣,0 不使用 1使用
        let res = await addOrder(params)
        if (res.code == 1000) {

        } else {
            messageApi.error(res.message)
        }
    }
    const sellWithLimitPrice = async () => {
        var params = {}
        params['symbol'] = currentCoin.symbol
        params['price'] = Number(sellPrice)
        params['amount'] = Number(sellAmount)
        params['direction'] = 1  // 'BUY' 0  'SELL' 1
        params['type'] = exchangeType // 0 市价 1 限价
        params['useDiscount'] = '0' //是否试用手续费抵扣,0 不使用 1使用
        let res = await addOrder(params)
        if (res.code == 1000) {

        } else {
            messageApi.error(res.message)
        }
    }

    const getTable = async () => {
        var params = {}
        params['pageNo'] = 1
        params['pageSize'] = 10
        params['symbol'] = currentCoin.symbol
        let res = await orderHistory(params)
        setHistoryTable(res.data.list)
        let resq = await orderCurrent(params)
        setTable(resq.data.list)

    }

    useEffect(() => {
        getSymbol()
        getSymbolScale() //获取精度
        getPlate()
        getTrade()
        getMoney()
        getTable()
    }, [])

    const toFloor = (number, scale = 8) => {
        if (new Number(number) == 0) {
            return 0;
        }
        var __str = number + "";
        if (__str.indexOf('e') > -1 || __str.indexOf('E') > -1) {
            var __num = new Number(number).toFixed(scale + 1),
                __str = __num + "";
            return __str.substring(0, __str.length - 1);
        } else if (__str.indexOf(".") > -1) {
            if (scale == 0) {
                return __str.substring(0, __str.indexOf("."));
            }
            return __str.substring(0, __str.indexOf(".") + scale + 1);
        } else {
            return __str;
        }
    }


    return (
        <div className="App mx-auto bg-[#010220] h-[100vh]">
            {contextHolder}
            <div className="border-b border-[#161654] border-solid sticky top-0 bg-[#010220] relative z-[100]">
                <Header />
            </div>
            <div>
                {/* <article className='mx-auto w-[1200px]'>
                    
                </article> */}
                <div className='min-w-[1200px]' style={{ display: 'flex' }}>
                    <div className='exchangeInput bg-[#192330] h-[795px]' style={{ flex: "0 0 20%" }}>
                        <div className='p-[10px]'>
                            <Input
                                placeholder="输入币种名称搜索"
                                value={searchKey}
                                onChange={(e) => { setSearchKey(e.target.value) }}
                                suffix={<SearchOutlined className="site-form-item-icon" />}
                            />
                        </div>
                        {/* <div className='flex'>
                            <div className='border-b border-[red] border-solid'>1</div>
                            <div>2</div>
                            <div>3</div>
                        </div> */}
                        <ul className='text-[12px]'>
                            <li className='text-[#61688A] flex h-[30px] border-b border-[#444444] border-solid leading-[30px]'>
                                <div className='flex-1 flex items-center justify-center'>币种</div>
                                <div className='flex-1 flex items-center justify-center'>最新价</div>
                                <div className='flex-1 flex items-center justify-center'>24h涨跌</div>
                            </li>
                            {
                                dataIndex.map((i, index) => {
                                    return <li key={index} className='flex h-[30px] border-b border-[#444444] border-solid leading-[30px]'>
                                        <div className='flex-1 flex items-center justify-center'>
                                            {/* <HeartOutlined className='ml-[20px] mr-[10px]' /> */}
                                            {i.coin}
                                        </div>
                                        <div className='flex-1 flex items-center justify-center'>{i.close}</div>
                                        <div className={`flex-1 flex items-center justify-center text-[${parseFloat(i.rose) < 0 ? '#f15057' : '#00b275'}]`}>{i.rose}</div>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    <div className='mx-[10px]' style={{ flex: "1" }}>
                        <div className='h-[60px] mb-[10px] bg-[#192330] flex justify-evenly text-[12px]'>
                            <div className='flex items-center text-[20px]'>
                                {currentCoin.coin}/{currentCoin.base}
                            </div>
                            <div className='flex flex-col items-start justify-center'>
                                <div className='text-[#999]'>最新价</div>
                                <div className={`text-[${currentCoin.change > 0 ? '#00b275' : '#f15057'}]`}>{currentCoin?.close?.toFixed(baseCoinScale)}</div>
                            </div>
                            <div className='flex flex-col items-start justify-center'>
                                <div className='text-[#999]'>24h涨跌</div>
                                <div className={`text-[${currentCoin.change > 0 ? '#00b275' : '#f15057'}]`}>{currentCoin.rose}</div>
                            </div>
                            <div className='flex flex-col items-start justify-center'>
                                <div className='text-[#999]'>24h最高价</div>
                                <div>{currentCoin?.high?.toFixed(baseCoinScale)}</div>
                            </div>
                            <div className='flex flex-col items-start justify-center'>
                                <div className='text-[#999]'>24h最低价</div>
                                <div>{currentCoin?.low?.toFixed(baseCoinScale)}</div>
                            </div>
                            <div className='flex flex-col items-start justify-center'>
                                <div className='text-[#999]'>交易数量</div>
                                <div>{currentCoin.volume} {currentCoin.coin}</div>
                            </div>
                        </div>
                        <div className='h-[320px]'>
                            <TVChartContainer />
                        </div>
                        <div className='h-[365px] bg-[#192330] mt-[40px]'>
                            <div className='h-[40px] border-b border-[#444444] border-solid flex'>
                                <div className='w-[72px] h-[40px] flex items-center justify-center relative cursor-pointer' onClick={() => { setExchangeType(0) }}>限价 {exchangeType == 0 && <div className='absolute w-[100%] h-[2px] bg-[#f0a70a] right-[0] bottom-[-2px]'></div>}</div>
                                <div className='w-[72px] h-[40px] flex items-center justify-center relative cursor-pointer' onClick={() => { setExchangeType(1); setBuyPrice(''); setSellPrice('') }}>市价 {exchangeType == 1 && <div className='absolute w-[100%] h-[2px] bg-[#f0a70a] right-[0] bottom-[-2px]'></div>}</div>
                            </div>
                            <div className='flex'>
                                <div className='flex-1 flex-col items-center'>
                                    <div className='text-[12px] text-right pr-[10px] pt-[20px]'>可用 {balance1} USDT</div>
                                    <div className='exchangeInput'>
                                        <div className='p-[10px]'>
                                            <Input
                                                value={exchangeType == 0 ? buyPrice : ''}
                                                onChange={(e) => { setBuyPrice(e.target.value) }}
                                                disabled={exchangeType == 0 ? false : true}
                                                style={{ height: '40px' }}
                                                placeholder={exchangeType == 0 ? '0' : '以市场上最优价格买入'}
                                                prefix={<span className='mr-[10px]'>买入价</span>}
                                                suffix={<span>USDT</span>}
                                            />
                                        </div>
                                        <div className='p-[10px]'>
                                            <Input
                                                value={buyAmount}
                                                onChange={(e) => { setBuyAmount(e.target.value) }}
                                                style={{ height: '40px' }}
                                                placeholder="0"
                                                prefix={<span className='mr-[10px]'>买入量</span>}
                                                suffix={<span>BTC</span>}
                                            />
                                        </div>
                                    </div>
                                    <div className='p-[10px] buySlider'>
                                        <Slider step='25' defaultValue={30} />
                                    </div>
                                    <div className='p-[10px] buyBtn'>
                                        <Button style={{ width: '100%', height: '40px' }} type="primary" onClick={() => { buyWithLimitPrice() }}>买入(BTC)</Button>
                                    </div>
                                </div>
                                <div className='flex-1 flex-col items-center'>
                                    <div className='exchangeInput'>
                                        <div className='text-[12px] text-right pr-[10px] pt-[20px]'>BTC {balance2} 可用</div>
                                        <div className='p-[10px]'>
                                            <Input
                                                value={exchangeType == 0 ? sellPrice : ''}
                                                onChange={(e) => { setSellPrice(e.target.value) }}
                                                disabled={exchangeType == 0 ? false : true}
                                                style={{ height: '40px' }}
                                                placeholder={exchangeType == 0 ? '0' : '以市场上最优价格卖出'}
                                                prefix={<span className='mr-[10px]'>卖出价</span>}
                                                suffix={<span>USDT</span>}
                                            />
                                        </div>
                                        <div className='p-[10px]'>
                                            <Input
                                                value={sellAmount}
                                                onChange={(e) => { setSellAmount(e.target.value) }}
                                                style={{ height: '40px' }}
                                                placeholder="0"
                                                prefix={<span className='mr-[10px]'>卖出量</span>}
                                                suffix={<span>BTC</span>}
                                            />
                                        </div>
                                        <div className='p-[10px]'>
                                            <Slider step='25' defaultValue={30} />
                                        </div>
                                        <div className='p-[10px] sellBtn'>
                                            <Button style={{ width: '100%', height: '40px' }} type="primary" onClick={() => { sellWithLimitPrice() }}>卖出(BTC)</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='bg-[#192330]' style={{ flex: "0 0 20%" }}>
                        <ul className='text-[12px]'>
                            <li className='text-[#61688A] flex h-[30px] border-b border-[#444444] border-solid leading-[30px]'>
                                <div className='flex-1 flex items-center justify-center'>价格(USDT)</div>
                                <div className='flex-1 flex items-center justify-center'>数量(BTC)</div>
                                <div className='flex-1 flex items-center justify-center'>累计(BTC)</div>
                            </li>
                            {
                                plate.askRows.map((i, index) => {
                                    return <li key={index} className='flex h-[20px] border-b border-[#444444] border-solid leading-[30px] relative'>
                                        <div className='flex-1 flex items-center justify-center relative z-[1] text-[#f15057]'>{i.price.toFixed(baseCoinScale)}</div>
                                        <div className='flex-1 flex items-center justify-center relative z-[1]'>{i.amount.toFixed(coinScale)}</div>
                                        <div className='flex-1 flex items-center justify-center relative z-[1]'>{i.totalAmount.toFixed(coinScale)}</div>
                                        <div style={{ width: `${(i.totalAmount / (i.direction === 'BUY' ? plate.bidTotle : plate.askTotle)).toFixed(2) * 100 + '%'}` }} className={`absolute h-[20px] top-[0] right-[0] bg-[#f15057] opacity-[0.3]`}></div>
                                    </li>
                                })
                            }
                        </ul>
                        <div className='h-[40px] bg-[#27313e] flex items-center justify-center'>
                            <span className={`text-[${currentCoin.change > 0 ? '#00b275' : '#f15057'}]`}>{currentCoin?.price?.toFixed(baseCoinScale)}</span>
                            <span className={`text-[${currentCoin.change > 0 ? '#00b275' : '#f15057'}] ml-[5px]`}>{currentCoin.change > 0 ? '↑' : '↓'}</span>
                        </div>
                        <ul className='text-[12px]'>
                            {
                                plate.bidRows.map((i, index) => {
                                    return <li key={index} className='flex h-[20px] border-b border-[#444444] border-solid leading-[30px] relative'>
                                        <div className='flex-1 flex items-center justify-center relative z-[1] text-[#00b275]'>{i.price.toFixed(baseCoinScale)}</div>
                                        <div className='flex-1 flex items-center justify-center relative z-[1]'>{i.amount.toFixed(coinScale)}</div>
                                        <div className='flex-1 flex items-center justify-center relative z-[1]'>{i.totalAmount.toFixed(coinScale)}</div>
                                        <div style={{ width: `${(i.totalAmount / (i.direction === 'BUY' ? plate.bidTotle : plate.askTotle)).toFixed(2) * 100 + '%'}` }} className={`absolute h-[20px] top-[0] right-[0] bg-[#00b275] opacity-[0.3]`}></div>
                                    </li>
                                })
                            }
                        </ul>
                        <ul className='text-[12px] border-t-[10px] border-[#010220] border-solid'>
                            <li className='text-[#61688A] flex h-[30px] border-b border-[#444444] border-solid leading-[30px]'>
                                <div className='flex-1 flex items-center justify-center'>价格(USDT)</div>
                                <div className='flex-1 flex items-center justify-center'>数量(BTC)</div>
                                <div className='flex-1 flex items-center justify-center'>时间</div>
                            </li>
                        </ul>
                        <ul className='text-[12px] h-[285px] overflow-y-auto'>
                            {
                                trade.map((i, index) => {
                                    return <li key={index} className='flex h-[30px] border-b border-[#444444] border-solid leading-[30px] relative'>
                                        <div className={`flex-1 flex items-center justify-center relative z-[1] text-[${i.direction == 'BUY' ? '#00b275' : '#f15057'}]`}>{i?.price?.toFixed(baseCoinScale)}</div>
                                        <div className='flex-1 flex items-center justify-center relative z-[1]'>{i?.amount?.toFixed(coinScale)}</div>
                                        <div className='flex-1 flex items-center justify-center relative z-[1]'>{moment(i.time).format("HH:mm:ss")}</div>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className='min-w-[1200px] h-[280px] bg-[#192330] mt-[15px]'>
                    <div className='h-[40px] flex'>
                        <div className={`text-[14px] w-[96px] h-[39px] flex items-center justify-center relative cursor-pointer bg-[${tableType == 0 ? '#27313e' : '#192330'}] text-[${tableType == 0 ? '#f15057' : '#fff'}]`} onClick={() => { setTableType(0) }}>当前委托</div>
                        <div className={`text-[14px] w-[96px] h-[39px] flex items-center justify-center relative cursor-pointer bg-[${tableType == 1 ? '#27313e' : '#192330'}] text-[${tableType == 1 ? '#f15057' : '#fff'}]`} onClick={() => { setTableType(1) }}>委托历史</div>
                    </div>
                    <ul className='text-[12px]'>
                        <li className='text-[#61688A] flex h-[30px] border-b border-[#444444] border-solid leading-[30px]'>
                            <div className='w-[50px] flex items-center justify-center'>#</div>
                            <div className='w-[150px] flex items-center justify-center'>时间</div>
                            <div className='flex-1 flex items-center justify-center'>交易对</div>
                            <div className='flex-1 flex items-center justify-center'>类型</div>
                            <div className='flex-1 flex items-center justify-center'>方向</div>
                            <div className='flex-1 flex items-center justify-center'>价格</div>
                            <div className='flex-1 flex items-center justify-center'>数量</div>
                            <div className='flex-1 flex items-center justify-center'>已成交</div>
                            <div className='flex-1 flex items-center justify-center'>成交金额</div>
                            <div className='flex-1 flex items-center justify-center'>状态</div>
                        </li>
                        {
                            tableType == 0 ? <>
                                {
                                    table.map((i, index) => {
                                        return <li key={index} className='text-[#61688A] flex h-[30px] border-b border-[#444444] border-solid leading-[30px]'>
                                            <div className='w-[50px] flex items-center justify-center'>{index+1}</div>
                                            <div className='w-[150px] flex items-center justify-center'>{moment(i.time).format("YYYY-MM-DD HH:mm:ss")}</div>
                                            <div className='flex-1 flex items-center justify-center'>{i.symbol}</div>
                                            <div className='flex-1 flex items-center justify-center'>{i.type == 1 ? '限价' : '市价'}</div>
                                            <div className={`flex-1 flex items-center justify-center text-[${i.direction == '0' ? '#00b275' : '#f15057'}]`}>{i.direction == 0 ? '买入' : '卖出'}</div>
                                            <div className='flex-1 flex items-center justify-center'>{toFloor(i.price)}</div>
                                            <div className='flex-1 flex items-center justify-center'>{toFloor(i.amount)}</div>
                                            <div className='flex-1 flex items-center justify-center'>{toFloor(i.traded_amount)}</div>
                                            <div className='flex-1 flex items-center justify-center'>{toFloor(i.turnover)}</div>
                                            <div className='flex-1 flex items-center justify-center'>-</div>
                                        </li>
                                    })
                                }
                            </> : <>
                                {
                                    historyTable.map((i, index) => {
                                        return <li key={index} className='text-[#61688A] flex h-[30px] border-b border-[#444444] border-solid leading-[30px]'>
                                            <div className='w-[50px] flex items-center justify-center'>{index+1}</div>
                                            <div className='w-[150px] flex items-center justify-center'>{moment(i.time).format("YYYY-MM-DD HH:mm:ss")}</div>
                                            <div className='flex-1 flex items-center justify-center'>{i.symbol}</div>
                                            <div className='flex-1 flex items-center justify-center'>{i.type == 1 ? '限价' : '市价'}</div>
                                            <div className={`flex-1 flex items-center justify-center text-[${i.direction == '0' ? '#00b275' : '#f15057'}]`}>{i.direction == 0 ? '买入' : '卖出'}</div>
                                            <div className='flex-1 flex items-center justify-center'>{toFloor(i.price)}</div>
                                            <div className='flex-1 flex items-center justify-center'>{toFloor(i.amount)}</div>
                                            <div className='flex-1 flex items-center justify-center'>{toFloor(i.traded_amount)}</div>
                                            <div className='flex-1 flex items-center justify-center'>{toFloor(i.turnover)}</div>
                                            <div className='flex-1 flex items-center justify-center'>-</div>
                                        </li>
                                    })
                                }
                            </>
                            
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Exchange