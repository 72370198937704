import { useEffect, useState } from 'react'
import Header from "../../component/header";
import OneSection from "../../component/oneSection";
import GetStart from "../../component/getStart";
import Supported from "../../component/supported";
import Demo from "../../component/deme";
import OpenApi from "../../component/openapi";
import Partner from "../../component/partner";
import PartnerH5 from "../../component/partnerH5";
import Footer from "../../component/footer";
import xuan from '../../assets/images/xuan.png'

function Index() {

  const [width, setWidth] = useState(0)

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    setWidth(width)
  }

    return (
      <div className="App mx-auto bg-[#010220]">
        <div className="border-b border-[#161654] border-solid sticky top-0 bg-[#010220] relative z-[100]">
          <Header />
        </div>
        <main style={width > 768 ? { background: `url(${xuan})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cotain', backgroundPosition: 'center' } : { background: `url(${xuan})`, backgroundSize: '100%', backgroundPosition: '0 -120px' }}>
          <article className='mx-auto sm:pb-48 pb-[66px] sm:w-[1200px]'>
            <OneSection />
            <GetStart />
            <Supported />
            <Demo />
            <OpenApi />
            { width > 768 ? <Partner /> : <PartnerH5 /> }
          </article>
        </main>
        <div className="border-t border-[#161654] border-solid">
          <Footer />
        </div>
      </div>
    );
  }
  
  export default Index