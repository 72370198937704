import { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from "../../component/header";
import { getCryptoInfo } from '../../api/path/login';
import businessBg from '../../assets/images/businessProcessBg.png'
import businessBgH5 from '../../assets/images/businessBgH5.png'
import businessSuccess from '../../assets/images/businessSuccess.png'
import businessError from '../../assets/images/businessError.png'
import businessWaiting from '../../assets/images/businessWaiting.png'

import { rateRules } from '@/utils'

const GetQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    var context = "";
    if (r != null)
        context = decodeURIComponent(r[2]);
    reg = null;
    r = null;
    return context == null || context == "" || context == "undefined" ? "" : context;
}

function BusinessResult() {

    const state = useLocation().state

    const [info, setInfo] = useState(null)
    let getInfo = () => {
        let data = {
            trade_no: GetQueryString('transactionId')
        }
        getCryptoInfo(data).then(res => {
            setInfo(res.data.item)
        })
    }

    useEffect(() => {
        getInfo()
    }, [])

    const [width, setWidth] = useState(0)
    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    function handleResize() {
        let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        setWidth(width)
    }

    const getMyDate = (str) => {
        var oDate = new Date(str),
            oYear = oDate.getFullYear(),
            oMonth = oDate.getMonth() + 1,
            oDay = oDate.getDate(),
            oHour = oDate.getHours(),
            oMin = oDate.getMinutes(),
            oSen = oDate.getSeconds(),
            oTime = oYear + '-' + getzf(oMonth) + '-' + getzf(oDay) + ' ' + getzf(oHour) + ':' + getzf(oMin) + ':' + getzf(oSen);
        return oTime;
    };
    const getzf = (num) => {
        if (parseInt(num) < 10) {
            num = '0' + num;
        }
        return num;
    }


    return <>
        <div className="App mx-auto min-h-[100vh]">
            <main className='relative'>
                <div className='w-[100%] min-h-[100vh] h-[100%] absolute bg-[#000000]'>
                    <img src={width > 768 ? businessBg : businessBgH5} className='w-[100%] h-[100%]' />
                </div>
                <div className="border-b border-[#161654] border-solid sticky top-0 bg-[#010220] relative z-[100]">
                    <Header />
                </div>
                <article className='mx-auto sm:pt-[165px] pt-[60px] sm:px-[0px] px-[18px] relative'>
                    <div className='sm:w-[630px]' style={{ 'margin': '0 auto' }}>
                        <div className='text-[#FFFFFF] sm:text-[30px] text-[0px] font-bold'>Order Results</div>
                        <div className='sm:flex sm:flex-row-reverse sm:justify-between sm:items-center'>
                            {
                                info && <div className='flex flex-col items-center sm:w-[120px]'>
                                    <img src={info.status == 0 ? businessWaiting : info.status == 1 ? businessError : businessSuccess} className="sm:w-[120px] w-[70px]" />
                                    <div className='text-[#FFFFFF] text-center sm:min-w-[120px] sm:text-[30px] text-[22px] font-bold sm:pt-[26px] pt-[10px]'>{info.status == 0 ? 'Pending' : info.status == 1 ? 'Failed' : 'Successful'}</div>
                                </div>
                            }
                            <div className='w-[100%] pt-[50px] sm:px-[0px] px-[10px]'>
                                {
                                    state.type == 1 ? <>
                                        <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                            <div className='sm:w-[185px] font-bold'>Get：</div>
                                            <span className='font-normal flex-auto sm:text-left text-right'>{Number(info?.amount).toFixed(rateRules(info?.sub_symbol))} {info?.sub_symbol}</span>
                                        </div>
                                        <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                            <div className='sm:w-[185px] font-bold'>Price：</div>
                                            <span className='font-normal flex-auto sm:text-left text-right'>{Number(info?.discount).toFixed(rateRules(info?.currency))} {info?.currency}</span>
                                        </div>
                                        <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                            <div className='sm:w-[185px] font-bold'>Fees：</div>
                                            <span className='font-normal flex-auto sm:text-left text-right'>{Number(info?.fee).toFixed(rateRules(info?.currency))} {info?.currency}</span>
                                        </div>
                                        <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                            <div className='sm:w-[185px] font-bold'>Total：</div>
                                            <span className='font-normal flex-auto sm:text-left text-right'>{Number(info && (Number(info.discount) + Number(info.fee))).toFixed(rateRules(info?.currency))} {info?.currency}</span>
                                        </div>
                                        <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                            <div className='sm:w-[185px] font-bold'>Purchase Channel：</div>
                                            <span className='font-normal flex-auto sm:text-left text-right'>MoonPay</span>
                                        </div>
                                    </> : <>
                                        <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                            <div className='sm:w-[185px] font-bold'>Total：</div>
                                            <span className='font-normal flex-auto sm:text-left text-right'>{Number(info?.amount).toFixed(rateRules(info?.sub_symbol))} {info?.sub_symbol}</span>
                                        </div>
                                        <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                            <div className='sm:w-[185px] font-bold'>Received：</div>
                                            <span className='font-normal flex-auto sm:text-left text-right'>{Number(info?.discount).toFixed(rateRules(info?.currency))} {info?.currency}</span>
                                        </div>
                                        <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                            <div className='sm:w-[185px] font-bold'>Fees：</div>
                                            <span className='font-normal flex-auto sm:text-left text-right'>{Number(info?.fee).toFixed(rateRules(info?.sub_symbol))} {info?.sub_symbol}</span>
                                        </div>
                                    </>
                                }

                                <div className='flex text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                    <div className='sm:w-[185px] font-bold'>Order Number：</div>
                                    <span className='font-normal flex-auto sm:text-left text-right'>{info?.trade_no}</span>
                                </div>
                                <div className='flex sm:justify-start justify-between text-[16px] text-[#F7F7F7] sm:mb-[18px] mb-[14px]'>
                                    <div className='sm:w-[185px] font-bold'>Time：</div>
                                    <span className='sm:font-normal font-bold'>{info && getMyDate(info.created_at)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </main>
        </div>
    </>
}

export default BusinessResult;