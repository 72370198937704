export const check = (data, payValue) => {

    let resule = []
    data.map((i, index) => {
        let list = { id: index, test0: true, test1: true, test2: true, value: i }

        let array = i.match(/,/g);
        let count = array ? array.length : 0;
        if (count < (payValue == 'CNY' ? 4 : payValue == 'EUR' ? 3 : 10)) {
            list.test0 = false
        } else {
            if (payValue == 'CNY') {
                let value1 = i.substring(0, find(i, ',', 0));
                let value2 = i.substring(find(i, ',', 0) + 1, find(i, ',', 1));
                let value3 = i.substring(find(i, ',', 1) + 1, find(i, ',', 2));
                let value4 = i.substring(find(i, ',', 2) + 1, find(i, ',', 3));
                let value5 = i.substring(find(i, ',', 3) + 1, i.length);
                if (value1.length > 0 && value2.length > 0 && value3.length > 0 && value4.length > 0 && value5.length > 0) {
                    list.test0 = true
                } else {
                    list.test0 = false
                }
                if (value1 == 'undefined' || value2 == 'undefined' || value3 == 'undefined' || value4 == 'undefined') {
                    list.test0 = false
                }
                if (Number(value5) <= 0) {
                    list.test2 = false
                }
                if (Number(value5).toString() == 'NaN') {
                    list.test2 = false
                }
            } else if (payValue == 'EUR') {
                let value1 = i.substring(0, find(i, ',', 0));
                let value2 = i.substring(find(i, ',', 0) + 1, find(i, ',', 1));
                let value3 = i.substring(find(i, ',', 1) + 1, find(i, ',', 2));
                let value4 = i.substring(find(i, ',', 2) + 1, i.length);
                if (value1.length > 0 && value2.length > 0 && value3.length > 0 && value4.length > 0) {
                    list.test0 = true
                } else {
                    list.test0 = false
                }
                if (value1 == 'undefined' || value2 == 'undefined' || value3 == 'undefined') {
                    list.test0 = false
                }
                if (Number(value4) <= 0) {
                    list.test2 = false
                }
                if (Number(value4).toString() == 'NaN') {
                    list.test2 = false
                }
            } else {

                let value1 = i.substring(0, find(i, ',', 0));
                let value2 = i.substring(find(i, ',', 0) + 1, find(i, ',', 1));
                let value3 = i.substring(find(i, ',', 1) + 1, find(i, ',', 2));
                let value4 = i.substring(find(i, ',', 2) + 1, find(i, ',', 3));
                let value5 = i.substring(find(i, ',', 3) + 1, find(i, ',', 4));
                let value6 = i.substring(find(i, ',', 4) + 1, find(i, ',', 5));
                let value7 = i.substring(find(i, ',', 5) + 1, find(i, ',', 6));
                let value8 = i.substring(find(i, ',', 6) + 1, find(i, ',', 7));
                let value9 = i.substring(find(i, ',', 7) + 1, find(i, ',', 8));
                let value10 = i.substring(find(i, ',', 8) + 1, find(i, ',', 9));
                let value11 = i.substring(find(i, ',', 9) + 1, i.length);
                if (value1.length > 0 && value2.length > 0 && value3.length > 0 && value4.length > 0 && value5.length > 0 && value6.length > 0 && value7.length > 0 && value8.length > 0 && value9.length > 0 && value10.length > 0 && value11.length > 0) {
                    list.test0 = true
                } else {
                    list.test0 = false
                }
                if (value1 == 'undefined' || value2 == 'undefined' || value3 == 'undefined' || value4 == 'undefined' || value5 == 'undefined' || value6 == 'undefined' || value7 == 'undefined' || value8 == 'undefined' || value9 == 'undefined' || value10 == 'undefined') {
                    list.test0 = false
                }
                if (Number(value11) <= 0) {
                    list.test2 = false
                }
                if (Number(value11).toString() == 'NaN') {
                    list.test2 = false
                }
            }
        }
        if (!list.test0 || !list.test1 || !list.test2) {
            resule.push(list)
        }
    })
    return resule
}


export const nextList = (data, payValue) => {
    let newList = []
    let sum = 0;
    data.map((i, index) => {
        if (payValue == 'CNY') {
            let value1 = i.substring(0, find(i, ',', 0));
            let value2 = i.substring(find(i, ',', 0) + 1, find(i, ',', 1));
            let value3 = i.substring(find(i, ',', 1) + 1, find(i, ',', 2));
            let value4 = i.substring(find(i, ',', 2) + 1, find(i, ',', 3));
            let value5 = i.substring(find(i, ',', 3) + 1, i.length);
            sum += Number(value5)
            newList.push({
                account_num: value1, bank_id: Number(value2), first_name: value3, last_name: value4, amount: Number(value5)
            })
        } else if (payValue == 'EUR') {
            let value1 = i.substring(0, find(i, ',', 0));
            let value2 = i.substring(find(i, ',', 0) + 1, find(i, ',', 1));
            let value3 = i.substring(find(i, ',', 1) + 1, find(i, ',', 2));
            let value4 = i.substring(find(i, ',', 2) + 1, i.length);
            sum += Number(value4)
            newList.push({
                account_num: value1, first_name: value2, last_name: value3, amount: Number(value4)
            })
        } else {
            let value1 = i.substring(0, find(i, ',', 0));
            let value2 = i.substring(find(i, ',', 0) + 1, find(i, ',', 1));
            let value3 = i.substring(find(i, ',', 1) + 1, find(i, ',', 2));
            let value4 = i.substring(find(i, ',', 2) + 1, find(i, ',', 3));
            let value5 = i.substring(find(i, ',', 3) + 1, find(i, ',', 4));
            let value6 = i.substring(find(i, ',', 4) + 1, find(i, ',', 5));
            let value7 = i.substring(find(i, ',', 5) + 1, find(i, ',', 6));
            let value8 = i.substring(find(i, ',', 6) + 1, find(i, ',', 7));
            let value9 = i.substring(find(i, ',', 7) + 1, find(i, ',', 8));
            let value10 = i.substring(find(i, ',', 8) + 1, find(i, ',', 9));
            let value11 = i.substring(find(i, ',', 9) + 1, i.length);
            sum += Number(value11)
            newList.push({
                account_num: value1,
                bank_id: Number(value2),
                first_name: value3,
                last_name: value4,
                countryCode: value5,
                cityCode: value6,
                address: value7,
                postcode: value8,
                bankCode: value9,
                bankAccountType: value10,
                amount: Number(value11), 
            })
        }

    })
    return {
        list: newList,
        sum: sum
    }
}

const find = (str, cha, num) => {
    var x = str.indexOf(cha);
    for (var i = 0; i < num; i++) {
        x = str.indexOf(cha, x + 1);
    }
    return x;
}