import { useEffect, useState, useRef } from 'react'
import cyrworld from '../assets/images/cyrworld.png'
import gateway from '../assets/images/gateway.png'
import gatewayH5 from '../assets/images/gatewayH5.png'
import get from '../assets/images/get.png'
import getH5 from '../assets/images/getH5.png'

function OneSection() {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    setWidth(width)
  }
  return (
    <section className='sm:py-44 relative pt-[38px] sm:pt-44  z-[0]'>
      <div className='sm:w-[100%] sm:h-[100%] absolute right-[0] top-[0]' style={{ background: `url(${gateway})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'right center', backgroundSize: '62%' }}></div>
      <div className='title relative sm:px-[0px] px-[25px]'>
        <h2 className='sm:text-6xl text-[26px] sm:font-medium font-normal text-white'>Your One-Stop Gateway</h2>
        <div className='mt-5'>
          <img className='sm:w-auto w-[250px]' src={cyrworld} alt='cyrworld' />
        </div>
        <p className='sm:mt-12 mt text-[#BDBDBD] sm:text-[16px] text-[12px] sm:w-[450px]'>Accept cryptocurrency payments from all major wallets through a simple API integration.</p>
      </div>
      <div>
      <div className='sm:pt-[0] pt-[25px]'>
          <img className='sm:w-[0] w-[100%]' src={gatewayH5} alt='gatewayH5' />
        </div>
        <div className='sm:mt-16 mt-0 cursor-pointer flex sm:justify-start justify-center sm:pt-[0] pt-[30px]'>
          <img className='sm:w-auto w-[108px]' src={width > 768 ? get : getH5} alt='get start' />
        </div>
      </div>
    </section>
  )
}

export default OneSection