
import axios from 'axios'

// 数据返回的接口
// 定义请求响应参数，不含data

// const URL = ''
const RequestEnums = {
	TIMEOUT : 20000,
	OVERDUE : 401, // 登录失效
	SUCCESS : 1000, // 请求成功
}
const config = {
	baseURL: process.env.REACT_APP_API_BASEURL,
	// baseURL: 'http://13.215.173.79',
	// baseURL: 'https://exapi.chicha.io',
	timeout: RequestEnums.TIMEOUT,
	withCredentials: true,
}

class RequestHttp {
	// 定义成员变量并指定类型
	constructor(config) {
		// 实例化axios
		this.service = axios.create(config)

		/**
		 * 请求拦截器
		 * 客户端发送请求 -> [请求拦截器] -> 服务器
		 */
		this.service.interceptors.request.use(
			(config) => {
				const { headers } = config
				return {
					...config,
					responseType: headers.responseType ?? '',
					headers: {
						// Language: 'zh-CN',
						Authorization: 'Bearer:' + localStorage.getItem('token'),
						...headers,
					},
				}
			},
			(error) => {
				// 请求报错
				Promise.reject(error)
			}
		)

		/**
		 * 响应拦截器
		 * 服务器换返回信息 -> [拦截统一处理] -> 客户端JS获取到信息
		 */
		this.service.interceptors.response.use(
			(response) => {
				const { data, config } = response // 解构
				if (data.code === RequestEnums.OVERDUE) {
					return Promise.reject(data)
				}
				// 全局错误信息拦截（防止下载文件得时候返回数据流，没有code，直接报错）
				if (data.code && data.code !== RequestEnums.SUCCESS) {
					// ElMessage.error(data) // 此处也可以使用组件提示报错信息
					return Promise.reject(data)
				}
				if (config.url === '/api/mymetapay/users/v2/login') {
					return response.headers
				}
				return data
			},
			(error) => {
				const { response } = error
				if (response) {
					this.handleCode(response.status)
				}
			}
		)
	}
	handleCode(code) {
		switch (code) {
			case 401:
				localStorage.removeItem('token')
				window.location.href = '/login'
				break
			default:
				break
		}
	}

	// 常用方法封装
	get(url, params, headers) {
		return this.service.get(url, { params, headers })
	}
	post(url, params, headers) {
		return this.service.post(url, params, { headers })
	}
	put(url, params) {
		return this.service.put(url, params)
	}
	delete(url, params) {
		return this.service.delete(url, { params })
	}
	moonpay(params) {
		return this.service.put('https://api.moonpay.com/v3/currencies/btc/limits', params)
	}
}

// 导出一个实例对象
export default new RequestHttp(config)
