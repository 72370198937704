import api from '../index'

export const fetchCheckUserStatus = (params) =>
	api.get(`/api/mymetapay/users/v1/check_status?email=${params}`)

export const fetchLogin = (params) =>
	api.post('/api/mymetapay/users/v2/login', params)

export const getUserInfo = () =>
	api.get('/api/mymetapay/users/v1/get_info')

export const getConfigList = () =>
	api.get('/api/mymetapay/config/v1/get_config?code=token_list')
	
export const grant = (params) =>
	api.post('/api/mymetapay/payroll/v1/grant', params)

export const grantCurrency = (params) =>
	api.post('/api/mymetapay/payroll/v1/grant_currency', params)

export const getSubsymbolConf = () =>
	api.get('/api/mymetapay/config/v1/get_subsymbol_conf')
	
export const getBalances = (params) =>
	api.get(`/api/mymetapay/users/v1/get_balances?sub_symbol=${params}`)

export const getGrantList = (params,type) =>
	api.get(`/api/mymetapay/payroll/v1/list?payment_type=${type}&page_num=${params}&page_size=10`)

export const sendEmail = (params) =>
	api.post('/api/mymetapay/auth/v1/emails/send_auth_code', params)

export const register = (params) =>
	api.post('/api/mymetapay/users/v2/register', params)

export const getConfig = (params) =>
	api.get(`/api/mymetapay/config/v1/get_config?code=${params}`)

export const getSubsymbolRate = (params) =>
	api.get(`/api/mymetapay/config/v1/get_subsymbol_rate`, params)

export const cryptoBuy = (params) =>
	api.post('/api/mymetapay/crypto/v1/buy', params)

export const payget = (params) =>
	api.post('/api/mymetapay/crypto/v1/transmit', params)

// export const payget = (data,params) => api.payget(data,params)
export const getCryptoInfo = (params) =>
	api.get(`/api/mymetapay/crypto/v1/info`, params)

export const getCryptoList = (params,type) =>
	api.get(`/api/mymetapay/crypto/v1/list?trade_type=${type}&page_num=${params}&page_size=10`)

export const getCryptoConfig = (params) =>
	api.get(`/api/mymetapay/crypto/v1/config`, params)

export const test = (params) =>
	api.moonpay(params)
	
export const addBank = (params) =>
	api.post('/api/mymetapay/users/v1/payments/add_bankcard', params)
	
export const bankList = (params) =>
	api.get('/api/mymetapay/users/v1/payments/get_bankcard?card_type=', params)

export const removeBank = (params) =>
	api.post('/api/mymetapay/users/v1/payments/remove_bankcard', params)
	
export const isPayPassword = (params) =>
	api.get('/api/mymetapay/auth/v1/pay_password/exist',)
	
export const cryptoSell = (params) =>
	api.post('/api/mymetapay/crypto/v1/sell', params)


export const platemini = (params) =>
	api.post('/market/exchange-plate-mini', params)

export const coinInfo = (params) =>
	api.post('/market/coin-info', params)

export const symbolInfo = (params) =>
	api.post('/market/symbol-info', params)

export const addOrder = (params) =>
	api.post('/api/mymetapay/exchange/order/add', params)

export const orderHistory = (params) =>
	api.post('/api/mymetapay/exchange/order/history', params)

export const orderCurrent = (params) =>
	api.post('/api/mymetapay/exchange/order/current', params)
	

	