import React, { useEffect, useRef, useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { createTheme } from '@uiw/codemirror-themes';
import { javascript } from '@codemirror/lang-javascript';
import { tags as t } from '@lezer/highlight';
import * as XLSX from 'xlsx';
import { useSelector, useDispatch } from 'react-redux'

import { grantCurrency, getSubsymbolConf, sendEmail } from '@/api/path/login'

import { Input, Upload, message, Modal, Radio } from 'antd';
import { EyeTwoTone, EyeInvisibleTwoTone } from '@ant-design/icons';
import Header from "@/component/header";

import { stopBubble } from '@/utils'

import { check, nextList } from './rule'

import payHead from '@/assets/images/payHead.png'
import payHeads from '@/assets/images/payHeads.png'
import ChiChaPay from '@/assets/images/ChiChaPay.png'
import upload from '@/assets/images/upload.png'
import down from '@/assets/images/down.png'
import listWaiting from '@/assets/images/listWaiting.png'
import close from '@/assets/images/close.png'

import china from '@/assets/images/China.png'
import europe from '@/assets/images/Europe.png'
import american from '@/assets/images/American.png'


const { Dragger } = Upload;


const myTheme = createTheme({
    theme: 'dark',
    settings: {
        background: '#1B1B1B',
        foreground: '#75baff',
        caret: '#ffffff',
        selection: '#036dd6',
        selectionMatch: '#036dd626',
        lineHighlight: '#8a91991a',
        gutterBackground: '#1B1B1B',
        gutterForeground: '#8a919966',
    },
    styles: [
        { tag: t.comment, color: '#787b8099' },
        { tag: t.variableName, color: '#0080ff' },
        { tag: [t.string, t.special(t.brace)], color: '#ffffff' },
        { tag: t.number, color: '#ffffff' },
        { tag: t.bool, color: '#ffffff' },
        { tag: t.null, color: '#ffffff' },
        { tag: t.keyword, color: '#ffffff' },
        { tag: t.operator, color: '#ffffff' },
        { tag: t.className, color: '#ffffff' },
        { tag: t.definition(t.typeName), color: '#ffffff' },
        { tag: t.typeName, color: '#ffffff' },
        { tag: t.angleBracket, color: '#ffffff' },
        { tag: t.tagName, color: '#ffffff' },
        { tag: t.attributeName, color: '#ffffff' },
    ],
});
const extensions = [javascript({ jsx: true })];

function NewBulksender() {

    const { user } = useSelector((state) => state.user)

    const [top, setTop] = useState('0')

    const [opacity, setOpacity] = useState('1')
    // 650
    const [top1, setTop1] = useState('1000')

    const [opacity1, setOpacity1] = useState('0')

    const [tokenValue, setTokenValue] = useState('')
    const [tokenListStatus, setTokenListStatus] = useState(false)

    const [payValue, setPayValue] = useState('')
    const [payListStatus, setPayListStatus] = useState(false)


    const [addressList, setAddressList] = useState([])

    const [remark, setRemark] = useState('')
    const [amount, setAmount] = useState(0)

    const [result, setResult] = useState(false)
    const [resultList, setResultList] = useState([])

    const [addressValue, setAddressValue] = useState('')

    const [newlist, setNewlist] = useState([])

    const [rate, setRate] = useState(0)

    const [inputType, setInputType] = useState(0)

    const [topStatus, setTopStatus] = useState(1)

    const [completeStatus, setCompleteStatus] = useState(true)

    const [infoList, setInfoList] = useState([])
    const [tokenIndex, setTokenIndex] = useState(0)

    useEffect(() => {


        let list = [{ name: 'MoneyExpress', list: ['CNY', 'USD'] }, { name: 'SEPA', list: ['EUR'] }]
        setInfoList(list)

        setTokenValue(list[0].name)
        setPayValue(list[0].list[0])



        document.addEventListener('click', () => {
            setTokenListStatus(false)
            setPayListStatus(false)
        })

    }, [])

    const grantSubmit = () => {
        if (!moadlInput1) {
            message.error('Please enter the verification code!');
            return false
        }
        if (!moadlInput2) {
            message.error('Please input a password!');
            return false
        }
        let params = {
            "sub_symbol": payValue,
            "auth_code": moadlInput1,
            "password": moadlInput2,
            "remark": remark,
            "employee_lst": addressList
        }
        grantCurrency(params).then(res => {
            res.data.employee_lst.map(i => {
                if (i.status != '200') {
                    setCompleteStatus(false)
                }
            })
            setResultList(res.data.employee_lst)
            setIsModalOpen(false)
            setTop1(1000)
            setOpacity1(0)
            setResult(true)
            setTopStatus(3)
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            setMoadlInput1('')
            setMoadlInput2('')
        }).catch(error => {
            message.error(error.message)
        })
    }

    const backSubmit = () => {
        setTop(10)
        setOpacity(1)
        setTop1(1000)
        setOpacity1(0)
        setTopStatus(1)
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    const onChanges = (e) => {
        if (!tokenValue) {
            message.error("Please select Token");
            return false
        }

        if (addressValue.length > 0) {
            let list = addressValue.trim().split('\n');
            let h = check(list, payValue)
            setNewlist(h)
            if (h.length == 0) {
                setAmount(nextList(list, payValue).sum)
                setAddressList(nextList(list, payValue).list)
                setTop(-1000)
                setOpacity(0)
                setTop1(10)
                setOpacity1(1)
                setTopStatus(2)
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                getSubsymbolConf().then(res => {
                    res.data.map(i => {
                        if (i.sub_symbol == tokenValue) {
                            setRate(i.payroll_transfer_fee_rate)
                        }
                    })
                })
            } else {
                console.log('error')
            }
        } else {
            message.error("Please enter the receiving address and quantity");
        }

    };

    const deleteList = () => {
        let list = addressValue.trim().split('\n');

        newlist.map(i => {
            list = list.filter(item => !item.includes(i.value))
        })
        var arr = list.join('\n');
        setAddressValue(arr)
        let h = check(list, payValue)
        setNewlist(h)

    }

    const find = (str, cha, num) => {
        var x = str.indexOf(cha);
        for (var i = 0; i < num; i++) {
            x = str.indexOf(cha, x + 1);
        }
        return x;
    }


    const onChange = React.useCallback((value, viewUpdate) => {
        setAddressValue(value)
    }, []);

    const HandleImportFile = (info) => {
        let files = info.file;
        let name = files.name
        let suffix = name.substr(name.lastIndexOf("."));
        let reader = new FileReader();
        let newString = []
        reader.onload = (event) => {
            try {
                if (".xls" != suffix && ".xlsx" != suffix) {
                    message.error("Select a file in Excel format to import!");
                    return false;
                }
                let { result } = event.target;
                let workbook = XLSX.read(result, { type: 'binary' });
                let data = [];
                for (let sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    }
                }
                data.map(i => {
                    if (payValue == 'CNY') {
                        newString.push(i.benAccountNum + ',' + i.benBankId + ',' + i.benFirstName + ',' + i.benLastName + ',' + i.Amount)
                    } else if (payValue == 'EUR') {
                        newString.push(i.benAccountNum + ',' + i.benFirstName + ',' + i.benLastName + ',' + i.Amount)
                    } else {
                        newString.push(i.benAccountNum + ',' + i.benBankId + ',' + i.benFirstName + ',' + i.benLastName + ',' + i.nationalityCountry + ',' + i.cityCode + ',' + i.address + ',' + i.postcode + ',' + i.bankCode + ',' + i.bankAccountType + ',' + i.Amount)
                    }
                })
                var c = newString.join('\n');
                setAddressValue(c)
                setInputType(1)
            } catch (e) {
                message.error('The file type is incorrect!');
            }
        }
        reader.readAsBinaryString(files);
    }

    const back2 = () => {
        setResult(false)
        setTop(10)
        setOpacity(1)
        setTokenValue(tokenValue)
        setRemark('')
        setAddressValue('')
        setTopStatus(1)
        setCompleteStatus(true)
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        // getMoney(tokenValue)
    }


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [moadlInput1, setMoadlInput1] = useState('');
    const [moadlInput2, setMoadlInput2] = useState('');
    const [modalTime, setModalTime] = useState('Send');
    const [modalTimeStatus, setModalTimeStatus] = useState(true);

    const [userInfo, setUserInfo] = useState(null);
    useEffect(() => {
        setUserInfo(user)
    }, [user])

    const showModal = () => {
        setIsModalOpen(true);
        setMoadlInput1('')
        setMoadlInput2('')
    };
    const handleOk = () => {
        setIsModalOpen(false);
        setMoadlInput1('')
        setMoadlInput2('')
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setMoadlInput1('')
        setMoadlInput2('')
    };

    let intervalHandle = useRef();

    const modalSendMethods = async () => {
        if (modalTimeStatus) {
            let data = { email: userInfo.user_email }
            await sendEmail(data)
            setModalTimeStatus(false)
            var timer_num = 60;
            intervalHandle.current = setInterval(() => {
                timer_num--;
                setModalTime(timer_num + 's')
                if (timer_num == 0) {
                    setModalTime('Send')
                    setModalTimeStatus(true)
                    clearInterval(intervalHandle.current);
                }
            }, 1000);
        }
    }

    return <div className="App mx-auto bg-[#000000]">
        {/* h-screen  */}
        <main className='overflow-y-hidden'>
            {/* border-b border-[#161654] border-solid sticky */}
            <div className="fixed top-0 bg-[rgba(0,0,0,0.43)] w-[100%] z-[51]">
                <Header />
            </div>
            {
                topStatus == '1' ? <div className='sm:h-[565px] h-[270px] relative'>
                    {/* style={{ background: `url(${payHead})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cotain', backgroundPosition: 'center' }} */}
                    <img src={payHead} alt='payHead' className='object-cover w-[100%] h-[100%] absolute left-0 top-0 z-[0]' />
                    <article className='mx-auto sm:w-[1200px] sm:pt-[127px] pt-[90px] z-[23] sm:pl-[0] pl-[25px] relative z-[1]'>
                        <div className='sm:text-[58px] text-[24px] text-[#ffffff] sm:mb-[30px] mb-[5px]'>Welcome to</div>
                        <img src={ChiChaPay} className='sm:w-[auto] w-[180px]' alt="ChiChaPay" />
                        <div className='sm:text-[18px] text-[12px] text-[#ffffff] sm:mb-[20px] mb-[10px] sm:mt-[40px] mt-[20px]'>Support Country</div>
                        <div className='text-[16px] text-[#FFFFFF] flex'>
                            <div className='flex items-center'>
                                <img src={china} className='sm:w-[42px] w-[25px]' />
                                <span className='sm:mr-[70px] sm:ml-[10px] mr-[20px] ml-[6px] sm:text-[16px] text-[12px]'>China</span>
                            </div>
                            <div className='flex items-center'>
                                <img src={europe} className='sm:w-[42px] w-[25px]' />
                                <span className='sm:mr-[70px] sm:ml-[10px] mr-[20px] ml-[6px] sm:text-[16px] text-[12px]'>Europe</span>
                            </div>
                            <div className='flex items-center'>
                                <img src={american} className='sm:w-[42px] w-[25px]' />
                                <span className='sm:mr-[70px] sm:ml-[10px] mr-[20px] ml-[6px] sm:text-[16px] text-[12px]'>American</span>
                            </div>
                        </div>
                    </article>
                </div> :
                    // style={{ background: `url(${payHeads})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cotain', backgroundPosition: 'center' }}
                    <div className='h-[228px] relative'>
                        <img src={payHeads} alt='payHeads' className='object-cover w-[100%] h-[100%] absolute left-0 top-0 z-[0]' />
                        <article className='mx-auto sm:w-[1200px] pt-[127px] z-[23] relative z-[1]'>
                            <div className='text-[32px] text-[#ffffff] mb-[30px] sm:px-[0] px-[25px]'>{topStatus == '2' ? 'Authorization' : 'Sending'}</div>
                        </article>
                    </div>
            }
            <article className='mx-auto sm:w-[1200px]'>
                <div className="nextBox">
                    <div className='test px-[25px] sm:px-[0]'>
                        {
                            topStatus == '1' && <div className='next1'>
                                <div className='mb-[20px]'>
                                    <div className='flex justify-between mb-[10px]'>
                                        <div className="text-[#ffffff] text-[16px] font-medium">Support Type</div>
                                    </div>
                                    <div className='h-[46px] relative mb-[30px]'>
                                        <div className='w-[100%] h-[100%] bg-[#1B1B1B] rounded-[6px] border border-[#444444] border-solid cursor-pointer flex items-center' onClick={(e) => { setTokenListStatus(!tokenListStatus); setPayListStatus(false); stopBubble(e) }}>
                                            <img src={down} className='w-[20px] mx-[10px]' />
                                            <div className='border-l-[1px] border-[#999999] border-solid h-[30px] pl-[10px] flex items-center text-[14px]'>{tokenValue}</div>
                                        </div>
                                        {
                                            tokenListStatus && <div className='absolute left-0 top-[46px] z-50 rounded-[6px] overflow-hidden'>
                                                {
                                                    infoList.map((i, index) => {
                                                        return <div key={index} className={`w-[300px] h-[46px] ${tokenValue == i.name ? 'bg-[#3C3C3C]' : 'bg-[#1B1B1B]'} hover:bg-[#3C3C3C] cursor-pointer flex items-center pl-[20px]`} onClick={(e) => { setInputType(0); setAddressValue(''); setTokenValue(i.name); setPayValue(i.list[0]); setTokenIndex(index); setTokenListStatus(false); stopBubble(e) }}>{i.name}</div>
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className='flex justify-between mb-[10px]'>
                                        <div className="text-[#ffffff] text-[16px] font-medium">Currency</div>
                                    </div>
                                    <div className='h-[46px] relative mb-[30px]'>
                                        <div className='w-[100%] h-[100%] bg-[#1B1B1B] rounded-[6px] border border-[#444444] border-solid cursor-pointer flex items-center' onClick={(e) => { setPayListStatus(!payListStatus); setTokenListStatus(false); stopBubble(e) }}>
                                            <img src={down} className='w-[20px] mx-[10px]' />
                                            <div className='border-l-[1px] border-[#999999] border-solid h-[30px] pl-[10px] flex items-center text-[14px]'>{payValue}</div>
                                        </div>
                                        {
                                            payListStatus && <div className='absolute left-0 top-[46px] z-50 rounded-[6px] overflow-hidden'>
                                                {
                                                    infoList[tokenIndex].list.map((i, index) => {
                                                        return <div key={index} className={`w-[300px] h-[46px] ${payValue == i ? 'bg-[#3C3C3C]' : 'bg-[#1B1B1B]'} hover:bg-[#3C3C3C] cursor-pointer flex items-center pl-[20px]`} onClick={(e) => { setInputType(0); setAddressValue(''); setPayValue(i); setPayListStatus(false); stopBubble(e) }}>{i}</div>
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='flex justify-between mb-[30px]'>
                                    <div className='sm:h-[50px] h-[32px] border-solid border-[#999999] border-[1px] rounded-[25px] overflow-hidden flex justify-start'>
                                        <div className='sm:px-[20px] px-[7px] sm:text-[18px] text-[14px] sm:h-[48px] h-[30px] text-center sm:leading-[48px] leading-[30px] cursor-pointer' style={{ 'background': 'linear-gradient(90deg, #633EEC 0%, #0DCDC6 100%)' }}>Payee Information</div>
                                        {/* <div className='px-[20px] text-[18px] h-[48px] text-center leading-[48px] text-[#ffffff] cursor-not-allowed'>External Wallet Address</div> */}
                                    </div>
                                    <div className='sm:h-[50px] h-[32px] flex justify-start'>
                                        <div className={`sm:leading-[50px] leading-[32px] sm:text-[18px] text-[14px] ${inputType == '0' ? 'text-[#00DDBF]' : 'text-[#ffffff]'} cursor-pointer`} onClick={() => { setInputType(0) }}>Excel Import</div>
                                        <div className='h-[100%] w-[1px] bg-[#979797] sm:mx-[20px] mx-[7px]'></div>
                                        <div className={`sm:leading-[50px] leading-[32px] sm:text-[18px] text-[14px] ${inputType == '1' ? 'text-[#00DDBF]' : 'text-[#ffffff]'}  cursor-pointer`} onClick={() => { setInputType(1) }}>Manual Import</div>
                                    </div>
                                </div>
                                <div className='h-[400px]'>
                                    {
                                        inputType == '0' ? <Dragger
                                            height='400px'
                                            accept=".xls , .xlsx"
                                            maxCount={1}
                                            showUploadList={false}
                                            customRequest={HandleImportFile}>
                                            <div className='mx-auto'><img className='mx-auto' src={upload} alt='upload' /></div>
                                            <div className='text-[14px] text-[#716F6F] pt-[20px]'>Drag the file here or click to upload</div>
                                        </Dragger> : <CodeMirror
                                            value={addressValue}
                                            height="400px"
                                            theme={myTheme}
                                            extensions={extensions}
                                            onChange={onChange}
                                            placeholder={payValue == 'USD' ? 'BenAccountNum,BenBankld,BenFirstName,BenLastName,NationalityCountry,CityCode,Address,PostCode,BankCode,BankAccountType,Amount' :
                                                payValue == 'EUR' ? 'BenAccountNum,BenFirstName,BenLastName,Amount' : 'BenAccountNum,BenBankld,BenFirstName,BenLastName,Amount'}
                                        />
                                    }
                                </div>
                                <div className='flex justify-between mt-[10px] mb-[30px]'>
                                    <div className="text-[#FFFFFF] text-[16px]">{inputType == '0' ? 'Supported file types：Excel' : 'Separated by commas'}</div>
                                    <div className="example text-[#FFFFFF] text-[16px] cursor-pointer underline relative">Example file
                                        <div className='exampleBox w-[200px] bg-[#1B1B1B] border-[1px] border-[#444444] border-solid absolute right-0 rounded-[10px] z-[50] text-[#FFFFFF]'>
                                            <div className='h-[62px] flex flex-col px-[30px] justify-center hover:bg-[#3C3C3C] hover:text-[#00DDBF]'>
                                                <div>USD</div>
                                                <a href='https://mmpaypublic.s3.ap-east-1.amazonaws.com/files/USD.xlsx' className="text-[12px] cursor-pointer underline">Execl</a>
                                            </div>
                                            <div className='h-[62px] flex flex-col px-[30px] justify-center hover:bg-[#3C3C3C] hover:text-[#00DDBF]'>
                                                <div>EUR</div>
                                                <a href='https://mmpaypublic.s3.ap-east-1.amazonaws.com/files/EUR.xlsx' className="text-[12px] cursor-pointer underline">Execl</a>
                                            </div>
                                            <div className='h-[62px] flex flex-col px-[30px] justify-center hover:bg-[#3C3C3C] hover:text-[#00DDBF]'>
                                                <div>CNY</div>
                                                <a href='https://mmpaypublic.s3.ap-east-1.amazonaws.com/files/CNY.xlsx' className="text-[12px] cursor-pointer underline">Execl</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    newlist.length > 0 && <>
                                        <div className='flex justify-between mt-[10px] mb-[10px]'>
                                            <div className="text-[#FFFFFF] text-[18px]">The following addresses or amounts cannot be sent</div>
                                            <div className="text-[#F8395D] text-[16px] cursor-pointer" onClick={() => { deleteList() }}>Delete them</div>
                                        </div>
                                        <div className="errorTable px-5 py-2 h-[150px] mb-[30px] border-[#F8395D] overflow-y-auto">
                                            <div className="text-[#F8395D] font-light mb">
                                                {
                                                    newlist.map((i, index) => {
                                                        return <div key={index}>
                                                            line {i.id + 1}： {!i.test0 && 'Missing user information'} {!i.test1 && 'Invalid wallet address'} {!i.test2 && 'Invalid send quantity'}
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                                <div>
                                    <Input
                                        value={remark} onChange={(e) => { setRemark(e.target.value) }}
                                        prefix={<span className='text-[#ffffff]'>Notes：</span>}
                                        className='h-[46px]'
                                        placeholder=""
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="pt-[30px]">
                                    <div className="nextBtn mx-auto rounded-[25px] flex justify-center items-center cursor-pointer" onClick={onChanges}>Next step</div>
                                </div>
                            </div>
                        }

                        {
                            topStatus == '2' && <div className="next2" style={{ "top": `${top1}px`, 'opacity': `${opacity1}` }}>
                                <div className='flex justify-between items-center mt-[30px] mb-[16px]'>
                                    <div className="text-[#FFFFFF] text-[18px]">Summary</div>
                                    {/* <div className="text-[#50BFFF] text-[16px]">充值</div> */}
                                </div>
                                <div className="next2Table px-[24px] py-[30px]">
                                    <div className='flex justify-start mb-[15px]'>
                                        <div className='w-[250px] text-[#FFFFFF] text-[18px]'>{amount}</div>
                                        <div className='w-[250px] text-[#FFFFFF] text-[18px]'>{addressList.length}</div>
                                    </div>
                                    <div className='flex justify-start mb-[15px]'>
                                        <div className='w-[250px] text-[#D4D4D4] text-[16px]'>Total amount</div>
                                        <div className='w-[250px] text-[#D4D4D4] text-[16px]'>Total number</div>
                                    </div>
                                </div>
                                <div className="text-[#ffffff] text-[18px] mb-[16px] mt-[46px]">Address List</div>
                                <div className="next2Table py-[24px] px-[30px] h-[350px] overflow-y-auto">
                                    {
                                        addressList.map((i, index) => {
                                            return <div key={index} className="text-[16px]">{index + 1}<span className='ml-[18px]'>
                                                {
                                                    payValue == 'CNY' ? <>{i.account_num},{i.bank_id},{i.last_name},{i.first_name},{i.amount}</> :
                                                        payValue == 'EUR' ? <>{i.account_num},{i.last_name},{i.first_name},{i.amount}</> :
                                                            <>{i.account_num},{i.bank_id},{i.first_name},{i.last_name},{i.countryCode},{i.cityCode},{i.address},{i.postcode},{i.bankCode},{i.bankAccountType},{i.amount}</>
                                                }
                                            </span></div>
                                        })
                                    }
                                </div>
                                <div className="text-[#ffffff] text-[16px] pt-[16px]">Notes：{remark && <span>{remark}</span>}</div>
                                <div className="pt-[30px] flex justify-center">
                                    <div className="backBtn rounded-[25px] flex justify-center items-center cursor-pointer" onClick={backSubmit}>Return</div>
                                    <div className="nextBtn rounded-[25px] flex justify-center items-center cursor-pointer" onClick={showModal}>Next step</div>
                                </div>
                            </div>
                        }

                        {
                            result && <div className='next3'>
                                <div className="text-[#ffffff] text-[18px] pt-[30px] mb-[16px]">All transactions have been sent, please wait for the transaction to be confirmed</div>
                                {
                                    resultList.length > 0 && <div className="next2Table px-[30px] py-[24px] min-h-[160px] max-h-[380px] overflow-y-auto">
                                        {
                                            resultList.map((i, index) => {
                                                return <div key={index} className='text-[16px] h-[20px] mb-[16px] flex justify-between items-center'>
                                                    <span>{index + 1}：
                                                        {
                                                            payValue == 'CNY' ? <>{i.account_num},{i.bank_id},{i.last_name},{i.first_name},{i.amount}</> :
                                                                payValue == 'EUR' ? <>{i.account_num},{i.last_name},{i.first_name},{i.amount}</> :
                                                                    <>{i.account_num},{i.bank_id},{i.first_name},{i.last_name},{i.countryCode},{i.cityCode},{i.address},{i.postcode},{i.bankCode},{i.bankAccountType},{i.amount}</>
                                                        }
                                                    </span>
                                                    <img src={listWaiting} />
                                                </div>
                                            })
                                        }
                                    </div>
                                }
                                {completeStatus && <div className="next2Table py-[27px] px-[24px] text-[16px] mt-[30px]">Congratulations, the batch transaction was successful!</div>}
                                <div className="text-[#ffffff] text-[16px] pt-[16px]">Notes：{remark}</div>
                                <div className="pt-[40px] flex justify-center">
                                    <div className="backBtn rounded-[25px] flex justify-center items-center cursor-pointer" onClick={back2}>Return</div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </article>
        </main>
        <Modal title="Security Verification" footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={420}
            closeIcon={<img src={close} />} maskClosable={false} >
            <div id='password'>
                <Input
                    value={moadlInput1}
                    prefix={<div className='text-[#ffffff] text=[14px] mr-[10px]'>Code</div>}
                    suffix={<><div className='send text-[#ffffff] text=[14px] w-[60px] cursor-pointer' onClick={() => { modalSendMethods() }}>{modalTime}</div></>}
                    className='h-[46px] my-[20px]'
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => { setMoadlInput1(e.target.value) }}
                />
            </div>
            <div id='confirm'>
                <div id='newConfirm'>
                    <Input.Password
                        value={moadlInput2}
                        prefix={<div className='text-[#ffffff] text=[14px] mr-[10px]'>Password</div>}
                        className='h-[46px]'
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => { setMoadlInput2(e.target.value) }}
                        iconRender={(visible) => (visible ? <EyeTwoTone twoToneColor="#444444" /> : <EyeInvisibleTwoTone twoToneColor="#444444" />)}
                    />
                </div>
            </div>

            <div className='modalBtn cursor-pointer' onClick={() => { grantSubmit() }}>Confirm</div>
        </Modal>
    </div>
}

export default NewBulksender