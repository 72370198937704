import logo from '../assets/images/logo.png'
import link1 from '../assets/images/link1.png'
import link2 from '../assets/images/link2.png'
import link3 from '../assets/images/link3.png'
import link4 from '../assets/images/link4.png'

function Footer() {
	return (
		<footer className='flex justify-between items-center mx-auto sm:w-[1200px] sm:px-[0] px-[30px]'>
			<div className='sm:py-10 py-[10px]'>
				<img src={logo} alt='logo' className='sm:w-auto w-[140px]' />
				<div className='mt-[20px]'>© 2023 Metapay Technology LLC. All rights reserved</div>
			</div>
			<div className='flex items-center sm:mr-48'>
				<a href='https://t.me/ChiChaPay_Global' target="_blank"><img src={link1} alt='link1' className='sm:w-10 sm:h-10 sm:ml-5 sm:mx-[0] w-[26px] h-[26px] mx-[5px]' /></a>
				<a href='https://discord.com/invite/3XNZ73jrGj' target="_blank"><img src={link2} alt='link2' className='sm:w-10 sm:h-10 sm:ml-5 sm:mx-[0] w-[26px] h-[26px] mx-[5px]' /></a>
				<a href='https://twitter.com/ChiChaPay_' target="_blank"><img src={link3} alt='link3' className='sm:w-10 sm:h-10 sm:ml-5 sm:mx-[0] w-[26px] h-[26px] mx-[5px]' /></a>
				<a href='https://medium.com/@ChiChaPay_' target="_blank"><img src={link4} alt='link4' className='sm:w-10 sm:h-10 sm:ml-5 sm:mx-[0] w-[26px] h-[26px] mx-[5px]' /></a>
			</div>
		</footer>
	)
}

export default Footer