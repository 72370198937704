export const copyFn = (EleId, flag) => {
    const input = document.createElement("textarea"); // 直接构建input
    if (flag) {
        input.value = EleId; // 设置内容
    } else {
        const copyTxt = document.getElementById(EleId).innerHTML;
        input.value = copyTxt; // 设置内容
    }
    document.body.appendChild(input); // 添加临时实例
    input.select(); // 选择实例内容
    document.execCommand("Copy"); // 执行复制
    document.body.removeChild(input); // 删除临时实例
}

export const numberRules = (e, type) => {
    var str = e
    var len1 = str.substr(0, 1)
    var len2 = str.substr(1, 1)
    // 如果第一位是0，第二位不是点，就用数字把点替换掉
    if (str.length > 1 && len1 == 0 && len2 != ".") {
        str = str.substr(1, 1)
    }
    // 第一位不能是.
    if (len1 == ".") {
        str = "";
    }
    // 限制只能输入一个小数点
    if (str.indexOf(".") != -1) {
        var str_ = str.substr(str.indexOf(".") + 1)
        if (str_.indexOf(".") != -1) {
            str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1)
        }
    }

    switch (type) {
        case 1:
            return str.match(/^\d*(\.?\d{0,1})/g)[0] || null;
        case 2:
            return str.match(/^\d*(\.?\d{0,2})/g)[0] || null;
        case 3:
            return str.match(/^\d*(\.?\d{0,3})/g)[0] || null;
        case 4:
            return str.match(/^\d*(\.?\d{0,4})/g)[0] || null;
        case 5:
            return str.match(/^\d*(\.?\d{0,5})/g)[0] || null;
        default:
            return false;
    }

}

export const stopBubble = (event) => {
    event.nativeEvent.stopImmediatePropagation() // 阻止冒泡
}

export const rateRules = (e) => {
    switch (e) {
        case 'USDT':
            return 2;
        case 'TRX':
            return 2;
        case 'BTC':
            return 5;
        case 'BNB':
            return 4;
        case 'OKT':
            return 4;
        case 'ETH':
            return 4;
        default:
            return 2;
    }
}

export const rules = (e) => {
    var str = e
    // 只能输入数字
    str = str.replace(/\D/g, '')
    return str
}

export const newRules = (e) => {
    var op = "";
    var tmp = e.replace(/\D/g, "");
    for (var i = 0; i < tmp.length; i++) {
        if (i % 4 === 0 && i > 0) {
            op = op + " " + tmp.charAt(i);
        } else {
            op = op + tmp.charAt(i);

        }
    }
    return op
}

export const CutOut = (num) => { //向上取整
    if (!num) return num
    const n = `${num}`.split('.')
    if (n.length < 2) {
        return num
    } else {
        const s = Math.ceil(n[1].slice(0, 2) + '.' + n[1].slice(2))
        const decimal = s < 10 ? `0${s}` : s >= 100 ? '00' : s
        const integer = s >= 100 ? Number(n[0]) + 1 : n[0]
        return `${integer}.${decimal}`
    }
}

/**
     * +
     * @param {*} arg1 
     * @param {*} arg2 
     * @returns 
     */
export const accAdd = (arg1, arg2) => {
    return changeNum(arg1, arg2)
}

/**
 * -
 * @param {*} arg1 
 * @param {*} arg2 
 * @returns 
 */
export const accSub = (arg1, arg2) => {
    return changeNum(arg1, arg2, false)
}

/**
 * *
 * @param {*} arg1 
 * @param {*} arg2 
 * @returns 
 */
export const accMul = (arg1, arg2) => {
    let m = 0;
    m = accAdd(m, getDecimalLength(arg1))
    m = accAdd(m, getDecimalLength(arg2))
    return getNum(arg1) * getNum(arg2) / Math.pow(10, m)
}

/**
 * /
 * @param {*} arg1 
 * @param {*} arg2 
 * @returns 
 */
export const accDiv = (arg1, arg2) => {
    let t1, t2;
    t1 = getDecimalLength(arg1)
    t2 = getDecimalLength(arg2)
    if (t1 - t2 > 0) {
        return (getNum(arg1) / getNum(arg2)) / Math.pow(10, t1 - t2)
    } else {
        return (getNum(arg1) / getNum(arg2)) * Math.pow(10, t2 - t1)
    }
}

function changeNum(arg1 = '', arg2 = '', isAdd = true) {
    function changeInteger(arg, r, maxR) {
        if (r != maxR) {
            let addZero = ''
            for (let i = 0; i < maxR - r; i++) {
                addZero += '0'
            }
            arg = Number(arg.toString().replace('.', '') + addZero)
        } else {
            arg = getNum(arg)
        }
        return arg
    }
    let r1, r2, maxR, m;
    r1 = getDecimalLength(arg1)
    r2 = getDecimalLength(arg2)
    maxR = Math.max(r1, r2)
    arg1 = changeInteger(arg1, r1, maxR)
    arg2 = changeInteger(arg2, r2, maxR)
    m = Math.pow(10, maxR)
    if (isAdd) {
        return (arg1 + arg2) / m
    } else {
        return (arg1 - arg2) / m
    }
}

function getDecimalLength(arg = '') {
    try {
        return arg.toString().split(".")[1].length
    } catch (e) {
        return 0
    }
}

function getNum(arg = '') {
    return Number(arg.toString().replace(".", ""))
}


export const getMyDate = (str) => {
    var oDate = new Date(str),
        oYear = oDate.getFullYear(),
        oMonth = oDate.getMonth() + 1,
        oDay = oDate.getDate(),
        oHour = oDate.getHours(),
        oMin = oDate.getMinutes(),
        oSen = oDate.getSeconds(),
        oTime = oYear + '-' + getzf(oMonth) + '-' + getzf(oDay) + ' ' + getzf(oHour) + ':' + getzf(oMin) + ':' + getzf(oSen);
    return oTime;
};
const getzf = (num) => {
    if (parseInt(num) < 10) {
        num = '0' + num;
    }
    return num;
}