import { useEffect, useState, useRef } from 'react'
import person from '../assets/images/person.png'
import accountinfo from '../assets/images/accountinfo.png'
import accountinfoH5 from '../assets/images/accountinfoH5.png'
import business from '../assets/images/business.png'
import getstart from '../assets/images/getstart.png'
import get from '../assets/images/get.png'
import startgrid from '../assets/images/startgrid.png'
import getH5 from '../assets/images/getH5.png'

function GetStart() {

  const [width, setWidth] = useState(0)

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    setWidth(width)
  }
  return (
    <>
      {
        width > 768 ? <section className='flex flex-col items-center' id='start' style={{ background: `url(${startgrid})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain' }}>
          <h2 className='flex justify-center'>
            <img src={getstart} alt='get start' />
          </h2>
          <section className='flex items-end mt-16 sm:w-[900px]'>
            <img src={person} alt='person' className='w-10 h-10' />
            <h3 className='text-xl font-bold text-[#06DCBF] ml-2'>Personal Account</h3>
          </section>
          <section className='mt-5 sm:w-[900px] sm:h-[200px]' style={{ background: `url(${accountinfo})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain' }}></section>
          <section className='flex items-end mt-16 sm:w-[900px]'>
            <img src={business} alt='person' className='w-10 h-10' />
            <h3 className='text-xl font-bold text-[#06DCBF] ml-2'>Business Account </h3>
          </section>
          <div className='mt-3 sm:w-[900px]'>
            <p>More KYC documents are needed. For details, please contact.
              <a className='text-[#06DCBF] ml-1' href="mailto:signup@chichapay.net">signup@chichapay.net</a>
              .</p>
          </div>
          <div className='mt-16 cursor-pointer'>
            <img src={get} alt='get start' />
          </div>
        </section> : <section className='flex flex-col px-[25px]' id='start'>
          <h2 className='flex justify-center'>
            <img className='w-[150px] mt-[60px]' src={getstart} alt='get start' />
          </h2>
          <section className='flex items-end mt-[35px]'>
            <img src={person} alt='person' className='w-[20px]' />
            <div style={{ 'fontSize': '15px', 'lineHeight': '1' }} className='font-bold text-[#06DCBF] ml-2'>Personal Account</div>
          </section>
          <img src={accountinfoH5} alt='accountinfo' className='w-[100%]' />
          <section className='flex items-end mt-8'>
            <img src={business} alt='person' className='w-[20px]' />
            <div style={{ 'fontSize': '15px', 'lineHeight': '1' }} className='font-bold text-[#06DCBF] ml-2'>Business Account </div>
          </section>
          <div className='mt-1 pl-[28px]'>
            <p style={{ 'fontSize': '12px' }}>More KYC documents are needed. For details, please contact.
              <a className='text-[#06DCBF] ml-1' href="mailto:signup@chichapay.net">signup@chichapay.net</a>
            </p>
          </div>
          <div className='flex justify-center pt-[30px]'>
            <img className='w-[108px]' src={width > 768 ? get : getH5} alt='get start' />
          </div>
        </section>
      }
    </>
  )
}

export default GetStart