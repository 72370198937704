import React from 'react'
import { Input } from 'antd'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import { rules } from '../../utils'

import password from '../../assets/images/password.png'
import modalClose from '../../assets/images/modalClose.png'
import tipIcon from '../../assets/images/tipIcon.png'



const PassowrdModal = ({ type = 0, visible = false, value = '', handleClose, handleChange, handleSubmit, ...props }) => {

    return (
        <>
            {
                visible && <>
                    {
                        type == 'kyc' ? <div className='w-[100vw] h-[100vh] bg-[rgba(0,0,0,0.5)] fixed top-0 z-[200]'>
                            <div className='sm:w-[500px] w-[300px] rounded-[5px] bg-[#ffffff] absolute sm:top-[200px] top-[150px] sm:pt-[54px] pt-[34px] left-[50%] sm:ml-[-250px] ml-[-150px] flex flex-col items-center'>
                                <img src={modalClose} className='cursor-pointer sm:w-[20px] w-[13px] absolute sm:right-[30px] right-[15px] sm:top-[30px] top-[15px]' alt='modalClose' onClick={handleClose} />
                                <img src={tipIcon} className='sm:w-[48px] w-[30px]' alt='tipIcon' />
                                <div className='sm:text-[30px] text-[20px] font-bold text-[#000000] sm:mt-[35px] mt-[15px] mb-[15px]'>Fast Trade Qualification</div>
                                <div className='smtext-[20px] text-[12px] text-[#444444] px-[30px]'>To ensure the safety of your assets, please go to ChiChaPayApp to complete KYC before using this service</div>
                                <div className='sm:px-[60px] px-[35px] w-[100%] sm:mt-[30px] mt-[15px] sm:mb-[50px] mb-[30px]' onClick={handleClose}>
                                    <div className='w-[100%] sm:h-[50px] h-[30px] rounded-[5px] bg-[#5A47EE] cursor-pointer flex items-center justify-center text-[#ffffff] sm:text-[20px] text-[12px]'>Confirmation</div>
                                </div>
                            </div>
                        </div> : type == 0 ? <div className='w-[100vw] h-[100vh] bg-[rgba(0,0,0,0.5)] fixed top-0 z-[200]'>
                            <div className='sm:w-[500px] w-[300px] rounded-[5px] bg-[#ffffff] absolute sm:top-[200px] top-[150px] sm:pt-[54px] pt-[34px] left-[50%] sm:ml-[-250px] ml-[-150px] flex flex-col items-center'>
                                <img src={modalClose} className='cursor-pointer sm:w-[20px] w-[13px] absolute sm:right-[30px] right-[15px] sm:top-[30px] top-[15px]' alt='modalClose' onClick={handleClose} />
                                <img src={password} className='sm:w-[80px] w-[46px]' alt='password' />
                                <div className='sm:text-[30px] text-[20px] font-bold text-[#000000] sm:mt-[35px] mt-[15px] mb-[15px]'>Set transaction password</div>
                                <div className='smtext-[20px] text-[12px] text-[#444444] px-[30px]'>Please go to ChiChaPay App and open My - Settings - Transaction Password, until the setting is completed, transferring and withdrawing coins will be restricted.</div>
                                <div className='sm:px-[60px] px-[35px] w-[100%] sm:mt-[30px] mt-[15px] sm:mb-[50px] mb-[30px]' onClick={handleClose}>
                                    <div className='w-[100%] sm:h-[50px] h-[30px] rounded-[5px] bg-[#5A47EE] cursor-pointer flex items-center justify-center text-[#ffffff] sm:text-[20px] text-[12px]'>Go to Settings</div>
                                </div>
                            </div>
                        </div> : <div className='w-[100vw] h-[100vh] bg-[rgba(0,0,0,0.5)] fixed top-0 z-[200]'>
                            <div className='sm:w-[500px] w-[300px] rounded-[5px] bg-[#ffffff] absolute sm:top-[200px] top-[150px] sm:pt-[38px] pt-[24px] left-[50%] sm:ml-[-250px] ml-[-150px] flex flex-col'>
                                <img src={modalClose} className='cursor-pointer sm:w-[20px] w-[13px] absolute sm:right-[30px] right-[15px] sm:top-[30px] top-[15px]' alt='modalClose' onClick={handleClose} />
                                <div className='w-[100%] text-center text-[#000000] sm:text-[30px] pb-[10px] text-[20px] border-b border-[rgba(68,68,68,0.3)] border-solid'>Security Verification</div>
                                <div className='bankPassword px-[32px]'>
                                    <div className='sm:text-[20px] text-[12px] text-[#444444] sm:pt-[30px] pt-[20px] sm:pb-[15px] pb-[10px]'>Trading Password</div>
                                    <Input.Password
                                        className='sm:h-[60px] h-[43px] text-[20px]'
                                        placeholder="Trading Password"
                                        onKeyUp={(e) => { e.target.value = rules(e.target.value) }}
                                        value={value}
                                        onChange={handleChange}
                                        maxLength={6}
                                        iconRender={(visible) => (visible ? <EyeOutlined style={{ color: '#444444' }} /> : <EyeInvisibleOutlined style={{ color: '#444444' }} />)}
                                    />
                                </div>
                                <div className='sm:px-[60px] px-[35px] w-[100%] sm:mt-[100px] mt-[60px] sm:mb-[50px] mb-[30px]' onClick={handleSubmit}>
                                    <div className='w-[100%] sm:h-[50px] h-[30px] rounded-[5px] bg-[#5A47EE] cursor-pointer flex items-center justify-center text-[#ffffff] sm:text-[20px] text-[12px]'>Confirm</div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </>
    )
}

export default PassowrdModal
