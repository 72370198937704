import { useEffect, useState, useRef } from 'react'
import demo from '../assets/images/demotitle.png'
import bsc from '../assets/images/bsc.png'
import binan from '../assets/images/binan.gif'
import metamask from '../assets/images/metamask.png'
import depayt from '../assets/images/depayt.png'
import depay from '../assets/images/depay.gif'
import meta from '../assets/images/meta.gif'
import demoqr from '../assets/images/demoqr.png'
import demoget from '../assets/images/demoget.png'

const wayList = [
  {
    key: '1',
    img: bsc,
    list: ['1. Switch to Binance Pro.', "2. Click on “Funds”,“Spot” or “Funds Account”, and then click on 'Withdraw'.", '3. In the ”Crypto” module, enter “USDT” and once the pop-up box appears, select "Send via Crypto Network”.', '4. Enter the address in the "Address bar" (this can also be done by scanning the code) and select the correct chain in the "network" input box. Finally, click on “Confirm" and enter the amount to be paid in the “Amount" input box and then click "Withdraw".', '5. A new browser page will open and enter the verification code, password, etc. to complete the withdrawal.'],
    show: binan
  },
  {
    key: '2',
    img: metamask,
    show: meta,
    list: ['1. Select the wallet account you intend to use.', '2. From the homepage, click below the “Wallet” tab and select the chain you want to use, then Click “Send".', '3. In the "To" input box, input the address (this can be done by scanning the code) and click "Next" to go to the "Amount" page. ', '4. Once at the  "Amount" page, select "USDT" as the currency. Enter the amount to be paid and click "Next" to complete the rest of the payment process.']
  },
  {
    key: '3',
    img: depayt,
    show: depay,
    list: ['1. Click “Me" followed by "Current Version" and then select "Professional Version”. Once at the “Professional Version”, click “Withdraw”.', '2. Once at the "Withdraw" page, select the correct token in the “Token" drop-down list. Once you have selected your token, select the correct network in the “Transfer network” drop-down list and enter the address in the "Address" input box (this can be done by scanning the code). Once the address has been inputed, enter the correct amount in the "Withdrawal " input box, and then, click “Confirm”.', '3. Enter the verification code, password, etc. to complete the transaction.']
  }
]

function Demo() {

  const [width, setWidth] = useState(0)

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    setWidth(width)
  }
  const [type, setType] = useState('1')

  const handleSelType = (e) => {
    const key = e?.target?.dataset?.key
    if (!key || key === type) return
    setType(key)
  }

  const handleGoDemo = () => {
    window.open(process.env.REACT_APP_DEMO_URL)
  }

  return (
    <>
      {
        width > 768 ? <section className='pt-48 flex flex-col items-center mx-auto sm:w-[1000px]' id='demo'>
          <h2 className='flex justify-center'>
            <img src={demo} alt='demo' />
          </h2>
          <ul className='flex justify-evenly rounded-full bg-[#161654] mt-10 p-2.5 text-sm' onClick={handleSelType}>
            <li data-key='1' className={`w-full h-full rounded-full flex justify-center items-center cursor-pointer p-2.5 ${type === '1' ? 'bg-[#5A47EE]' : ''}`}>Binance</li>
            <li data-key='2' className={`w-full h-full rounded-full mx-2.5 flex justify-center items-center cursor-pointer p-2.5 ${type === '2' ? 'bg-[#5A47EE]' : ''}`}>MetaMask</li>
            <li data-key='3' className={`w-full h-full rounded-full flex justify-center items-center cursor-pointer p-2.5 ${type === '3' ? 'bg-[#5A47EE]' : ''}`}>ChiChaPay</li>
          </ul>
          <section className='mt-12 flex flex-col items-center cursor-pointer' onClick={handleGoDemo}>
            <div>
              <img src={demoqr} alt='demo qr' />
            </div>
            <div>
              <img src={demoget} alt='demo qr' />
            </div>
          </section>
          <section className='flex justify-between w-full mt-10'>
            <div className='left flex-1 mr-32'>
              <img src={wayList[type - 1].img} alt='bsc' />
              <ul className='mt-5'>
                {
                  wayList[type - 1].list.map((item, index) => (
                    <li key={index} className='mt-5'>{item}</li>
                  ))
                }
              </ul>
            </div>
            <div className='right' style={{ width: 217 }}>
              <img src={wayList[type - 1].show} alt='binan' style={{ width: 217, height: 412 }} />
            </div>
          </section>
        </section> : <section className='pt-[65px] px-[25px] flex flex-col items-center mx-auto' id='demo'>
          <h2 className='flex justify-center'>
            <img src={demo} alt='demo' className='w-[90px]' />
          </h2>
          <ul className='flex justify-evenly rounded-full bg-[#161654] mt-[30px] p-[6px] text-sm' onClick={handleSelType}>
            <li data-key='1' className={`w-full h-full rounded-full flex justify-center items-center cursor-pointer px-[18px] py-[8px] ${type === '1' ? 'bg-[#5A47EE]' : ''}`}>Binance</li>
            <li data-key='2' className={`w-full h-full rounded-full mx-[6px] flex justify-center items-center cursor-pointer px-[18px] py-[8px] ${type === '2' ? 'bg-[#5A47EE]' : ''}`}>MetaMask</li>
            <li data-key='3' className={`w-full h-full rounded-full flex justify-center items-center cursor-pointer px-[18px] py-[8px] ${type === '3' ? 'bg-[#5A47EE]' : ''}`}>ChiChaPay</li>
          </ul>
          <section className='mt-[30px] flex flex-col items-center cursor-pointer' onClick={handleGoDemo}>
            {/* <div style={{ width: 165, height: 200, background: `url(${demoopen})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }} onClick={handleGoDemo} className='cursor-pointer'></div> */}
            <div>
              <img src={demoqr} alt='demo qr' className='w-[110px]' />
            </div>
            <div>
              <img src={demoget} alt='demo qr' className='w-[180px]' />
            </div>
          </section>
          <section className='flex justify-between w-full mt-10'>
            <div className='left flex-1'>
              <img src={wayList[type - 1].img} alt='bsc' className='w-[108px]' />
              <ul className='mt-[10px]'>
                {
                  wayList[type - 1].list.map((item, index) => (
                    <li key={index} className='mt-[10px]' style={{ 'fontSize': '11px' }}>{item}</li>
                  ))
                }
              </ul>
            </div>
            <div className='right' style={{ width: 153 }}>
              <img src={wayList[type - 1].show} alt='binan' style={{ width: 153, height: 291 }} />
            </div>
          </section>
        </section>
      }
    </>
  )
}

export default Demo