import { LoadingOutlined } from '@ant-design/icons';

function Loading() {
    return <>
        <div className="loadingImg fixed top-0 left-0 w-[100vw] h-[100vh] bg-[rgba(0,0,0,0.5)] z-[999] flex justify-center items-center">
        <LoadingOutlined className='text-[36px] text-[#5A47EE]' />
        </div>
    </>
}

export default Loading