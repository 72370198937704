import { useEffect, useState, useRef } from 'react'
import openapi from '../assets/images/openapi.png'
import get from '../assets/images/get.png'
import getH5 from '../assets/images/getH5.png'

function OpenApi() {

  const [width, setWidth] = useState(0)

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    setWidth(width)
  }

  return (
    <section className='sm:pt-48 sm:px-[0] pt-[70px] px-[25px]' id='openapi'>
      <h2 className='flex justify-center'>
        <img src={openapi} alt='openapi' className='sm:w-auto w-[105px]' />
      </h2>
      <section className='flex justify-center sm:mt-10 mt-[18px]'>
        <p className='text-center sm:w-[685px] sm:text-[16px] text-[11px]'>This document describes how to connect your business, usually a website, to ChichaPay's gateway in order to be able to accept cryptocurrency payments.
          The target audience for this document is usually your company's technical staff, who need to have some understanding of the technology.
          If you have technical support questions, please contact us at support@chichapay.net
        </p>
      </section>
      <div className='sm:mt-16 flex justify-center sm:pt-[0] pt-[30px]'>
        <img src={width > 768 ? get : getH5} alt='get start' onClick={() => { window.open('https://apidoc.chichapay.net/') }} className='sm:w-auto w-[108px] cursor-pointer' />
      </div>
    </section>
  )
}

export default OpenApi