import { useEffect, useState, useRef } from 'react'
import { message } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'

import Header from "../../component/header";
import Icon from '../../icon';

import { stopBubble } from '../../utils'

import { useSelector, useDispatch } from 'react-redux'

import { getConfig } from '@/api/path/login'


import businessBg from '../../assets/images/businessProcessBg.png'
import businessBgH5 from '../../assets/images/businessBgH5.png'

function AddBankCard() {

    const [messageApi, contextHolder] = message.useMessage()
    const { user } = useSelector((state) => state.user)
    const { width } = useSelector((state) => state.global)
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const state = useLocation().state
    const [bankChoose, setBankChoose] = useState(0)

    const [countryList, setCountryList] = useState([])
    const [countryIndex, setCountryIndex] = useState(-1)

    const getConfigInfo = () => {
        getConfig('add_bankcard').then(res => {
            setCountryList(JSON.parse(res.data))
            setCountryIndex(Math.max(state?.index, -1))
        })
    }


    const [status1, setStatus1] = useState(false)
    const [status2, setStatus2] = useState(false)

    useEffect(() => {
        getConfigInfo()
        document.addEventListener('click', () => {
            setStatus1(false)
            setStatus2(false)
        })
    }, [])

    let list = [
        { name: 'America', currency: 'USD' },
        { name: 'Europe', currency: 'EUR' },
        { name: 'China', currency: 'CNY' }
    ]

    return <>
        <div className="App mx-auto min-h-[100vh]">
            {contextHolder}
            <main className='relative min-h-[100vh]'>
                <div className='w-[100%] sm:min-h-[100vh] h-[100%] min-h-[100%] absolute bg-[#000000]'>
                    <img src={width > 768 ? businessBg : businessBgH5} className='w-[100%] min-h-[100%]' />
                </div>
                <div className="border-b border-[#161654] border-solid sticky top-0 bg-[#010220] relative z-[100]">
                    <Header />
                </div>
                <article className='mx-auto sm:px-[0px] px-[18px] relative'>
                    <div className='sm:w-[916px] pb-[100px]' style={{ 'margin': '0 auto' }}>
                        <div className='text-[16px] text-[#ffffff] flex items-center sm:pt-[20px] pt-[10px] sm:pb-[50px] pb-[16px]'>
                            <Icon type='icon-backColors' className='text-[16px] mr-[8px] cursor-pointer' onClick={() => { navigate('/bankcard') }} />
                            <span className='cursor-pointer' onClick={() => { navigate('/bankcard') }}>Back</span>
                        </div>
                        <div className='sm:text-[30px] text-[20px] text-[#FFFFFF] font-bold'>Add Bank Card</div>
                        <div className='sm:text-[16px] text-[12px] sm:mb-[20px] mb-[8px] pt-[10px]'>Please enter your English bank card information. <span className='text-[#0DC7CB]'>ChiChaPay</span> values and protects user privacy and security, and your personal information will be encrypted throughout the process</div>
                        <div className='sm:flex sm:justify-between sm:flex-wrap'>
                            <div className='sm:pt-[25px] pt-[25px] sm:w-[428px]'>
                                <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>Name</div>
                                <div className='h-[48px] w-[100%] bg-[#CED4E4] rounded-[6px] flex items-center px-[15px]'>
                                    <div className='text-[#444444] sm:text-[16px] text-[12px]'>{user?.user_kyc_info.FirstNameInEnglish}</div>
                                </div>
                            </div>
                            <div className='sm:pt-[25px] pt-[25px] sm:w-[428px]'>
                                <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>Middle Name</div>
                                <div className='h-[48px] w-[100%] bg-[#CED4E4] rounded-[6px] flex items-center px-[15px]'>
                                    <div className='text-[#444444] sm:text-[16px] text-[12px]'>{user?.user_kyc_info.MiddleNameInEnglish}</div>
                                </div>
                            </div>
                            <div className='sm:pt-[25px] pt-[25px] sm:w-[428px]'>
                                <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>Surname</div>
                                <div className='h-[48px] w-[100%] bg-[#CED4E4] rounded-[6px] flex items-center px-[15px]'>
                                    <div className='text-[#444444] sm:text-[16px] text-[12px]'>{user?.user_kyc_info.LastNameInEnglish}</div>
                                </div>
                            </div>
                        </div>
                        <div className='h-[20px]'></div>
                        <div className='info'></div>
                        <div className='sm:flex sm:justify-between sm:flex-wrap'>
                            <div className='sm:pt-[25px] pt-[20px] sm:w-[428px]'>
                                <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>Country</div>
                                <div className='relative'>
                                    <div className='h-[48px] w-[100%] bg-[#F7F7F7] rounded-[6px] flex items-center px-[15px] cursor-pointer' onClick={(e) => {
                                        setStatus1(!status1); stopBubble(e)
                                    }}>
                                        {countryIndex != -1 && <img src={require(`@/assets/images/countryIcon${countryList[countryIndex]?.type}.png`)} className='sm:w-[33px] w-[24px] mr-[12px]' alt='countryIcon' />}
                                        <div className='text-[#000000] sm:text-[16px] text-[12px] flex-auto'>{countryList[countryIndex]?.country ? countryList[countryIndex]?.country : 'Select Country'}</div>
                                        <Icon type='icon-iconDown' className='text-[#000000]' />
                                    </div>
                                    {
                                        status1 && <div className='absolute w-[100%] sm:max-h-[170px] overflow-y-auto max-h-[150px] bg-[#ffffff] z-[50] rounded-[6px] left-[0] top-[58px] py-[10px]'>
                                            {
                                                countryList.map((i, index) => {
                                                    return <div key={index} className={`h-[48px] flex items-center hover:bg-[#E7E7E7] ${countryIndex == index ? 'bg-[#E7E7E7]' : 'bg-[#ffffff]'} px-[20px] cursor-pointer`} onClick={(e) => {
                                                        setCountryIndex(index); setStatus1(false); stopBubble(e)
                                                    }}>
                                                        <img src={require(`@/assets/images/countryIcon${i.type}.png`)} className='sm:w-[33px] w-[24px] mr-[12px]' alt='countryIcon' />
                                                        <div className='text-[#000000] sm:text-[16px] text-[12px] flex-auto'>{i.country}</div>
                                                        {countryIndex == index && <Icon type='icon-choosed' className='text-[#000000]' />}
                                                    </div>
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='sm:pt-[25px] pt-[20px] sm:w-[428px]'>
                                <div className='text-[#F7F7F7] sm:text-[16px] text-[12px] font-bold sm:mb-[10px] mb-[5px]'>Currency</div>
                                <div className='relative'>
                                    <div className='h-[48px] w-[100%] bg-[#F7F7F7] rounded-[6px] flex items-center px-[15px] cursor-pointer' onClick={(e) => {
                                        if (countryIndex != -1) {
                                            setStatus2(!status2); stopBubble(e)
                                        }
                                    }}>
                                        {countryIndex != -1 && <img src={require(`@/assets/images/currencyIcon${countryList[countryIndex]?.type}.png`)} className='sm:w-[33px] w-[24px] mr-[12px]' alt='currencyIcon' />}
                                        <div className='text-[#000000] sm:text-[16px] text-[12px] flex-auto'>{countryList[countryIndex]?.type == 1 ? 'USD' : countryList[countryIndex]?.type == 2 ? 'EUR' : countryList[countryIndex]?.type == 3 ? 'CNY' : 'Select Currency'}</div>
                                        {/* <Icon type='icon-iconDown' className='text-[#000000]' /> */}
                                    </div>
                                    {
                                        status2 && <div className='absolute w-[100%] sm:max-h-[170px] overflow-y-auto max-h-[150px] bg-[#ffffff] z-[50] rounded-[6px] left-[0] top-[58px] py-[10px]'>
                                            <div className='h-[48px] flex items-center hover:bg-[#E7E7E7] bg-[#E7E7E7] px-[20px] cursor-pointer' onClick={(e) => {
                                                setStatus2(false); stopBubble(e)
                                            }}>
                                                <img src={require(`@/assets/images/currencyIcon${countryList[countryIndex]?.type}.png`)} className='sm:w-[33px] w-[24px] mr-[12px]' alt='currencyIcon' />
                                                <div className='text-[#000000] sm:text-[16px] text-[12px] flex-auto'>{countryList[countryIndex]?.type == 1 ? 'USD' : countryList[countryIndex]?.type == 2 ? 'EUR' : countryList[countryIndex]?.type == 3 ? 'CNY' : 'Select Currency'}</div>
                                                <Icon type='icon-choosed' className='text-[#000000]' />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='sm:h-[58px] h-[48px] bg-[#5A47EE] cursor-pointer mt-[45px] rounded-[8px] flex items-center justify-center' onClick={() => {
                            if (countryIndex != -1) {
                                navigate('/addBankCardNext', { state: { 
                                    index: countryIndex,
                                    country: countryList[countryIndex]?.country, 
                                    currency: countryList[countryIndex]?.type == 1 ? 'USD' : countryList[countryIndex]?.type == 2 ? 'EUR' : countryList[countryIndex]?.type == 3 ? 'CNY' : '' 
                                } })
                            } else {
                                messageApi.error('Select Country');
                            }
                        }}>
                            <div className='text-[#FFFFFF] sm:text-[20px] text-[16px] font-bold mr-[10px]'>Next Step</div>
                            <Icon type='icon-Vector' className='text-[#ffffff] sm:text-[20px] text-[14px]' />
                        </div>
                        <div className='mt-[10px] sm:text-[16px] text-[12px] sm:text-left text-center'>Note: Please fill in the relevant information in English.</div>
                    </div>
                </article>
            </main>
        </div>
    </>
}

export default AddBankCard;