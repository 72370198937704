import React, { useEffect, useRef, useState } from 'react';
import './index.css'
import partner from '../assets/images/partner.png'
import group1 from '../assets/images/Group 41.png'
import group2 from '../assets/images/Group 36.png'
import group3 from '../assets/images/Group 37.png'
import group4 from '../assets/images/Group 38.png'
import group5 from '../assets/images/Group 39.png'
import group6 from '../assets/images/Group 40.png'
import group7 from '../assets/images/Group 30.png'
import group8 from '../assets/images/Group 31.png'
import group9 from '../assets/images/Group 32.png'
import group10 from '../assets/images/Group 33.png'
import group11 from '../assets/images/Group 34.png'
import group12 from '../assets/images/Group 35.png'

function Partner() {

    const [width, setWidth] = useState(0)

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    function handleResize() {
        let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        setWidth(width)
    }

    const [isScrolle, setIsScrolle] = useState(true)
    const speed = 20
    const warper = useRef()
    const childDomInit = useRef()
    const childDomCopy = useRef()
    const warper1 = useRef()
    const childDomInit1 = useRef()
    const childDomCopy1 = useRef()

    useEffect(() => {
        childDomCopy.current.innerHTML = childDomInit.current.innerHTML
        childDomCopy1.current.innerHTML = childDomInit1.current.innerHTML
    }, [])

    // 开始滚动
    useEffect(() => {
        let timer
        warper.current.scrollLeft = 1372
        if (isScrolle) {
            timer = setInterval(() => {
                warper.current.scrollLeft <= 0 || warper.current.scrollLeft == 175
                    ? (warper.current.scrollLeft = 1372)
                    : warper.current.scrollLeft--

                warper1.current.scrollLeft >= childDomInit1.current.scrollWidth || warper1.current.scrollLeft == 1036
                    ? (warper1.current.scrollLeft = 0)
                    : warper1.current.scrollLeft++
            }, speed)
        }
        return () => {
            clearTimeout(timer)
        }
    }, [isScrolle])

    // 鼠标移动，移除方法
    const hoverHandler = (flag) => setIsScrolle(flag)

    return (
        <section className='pt-48' id='partner'>
            <h2 className='flex justify-center'>
                <img src={partner} alt='partner' />
            </h2>
            <div className='pt-[40px]'>
                <div className="parent py-[20px]" ref={warper}>
                    <div className="child" ref={childDomInit}>
                        <img className='inline-block' src={group1} />
                        <img className='inline-block ml-[12px]' src={group2} />
                        <img className='inline-block ml-[12px]' src={group3} />
                        <img className='inline-block ml-[12px]' src={group4} />
                        <img className='inline-block ml-[12px]' src={group5} />
                        <img className='inline-block ml-[12px]' src={group6} />
                        <img className='inline-block ml-[12px]' src={group1} />
                        <img className='inline-block ml-[12px]' src={group2} />
                    </div>
                    <div className="child ml-[878px]" ref={childDomCopy}></div>
                </div>
                <div className="parent" ref={warper1}>
                    <div className="child" ref={childDomInit1}>
                        <img className='inline-block' src={group7} />
                        <img className='inline-block ml-[12px]' src={group8} />
                        <img className='inline-block ml-[12px]' src={group9} />
                        <img className='inline-block ml-[12px]' src={group10} />
                        <img className='inline-block ml-[12px]' src={group11} />
                        <img className='inline-block ml-[12px]' src={group12} />
                        <img className='inline-block ml-[12px]' src={group7} />
                        <img className='inline-block ml-[12px]' src={group8} />
                    </div>
                    <div className="child ml-[878px]" ref={childDomCopy1}></div>
                </div>
            </div>
        </section>
    )
}

export default Partner